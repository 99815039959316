<template>
	<div class="home">   
	 	 	<div v-if="loading" class="d-flex justify-content-center" id="spinner188" >
				 <Spinner />
				</div>
    <headers/>
    <section-1/>  
   <Scheme />
     

	</div>
</template>

<script>
 
  import Headers from "../components/Header/Header.vue";
  import Section1 from "../components/Section1.vue";
  import Scheme from "../components/Scheme/Scheme.vue";
  import Spinner from "../components/Spinner.vue"
 import {mapState} from 'vuex' 
export default {
 
	name: 'Home',
	components: {
	 Headers,
	 Section1,
	 Scheme,
	  Spinner
	},
   data : () => {
	   return {
		  
	   }
   },
   
  computed : {
	  ...mapState ( {
		  loading : (state) => state.Scheme.loading
	  })
  }
};
</script>
<style lang="scss" scoped>
#spinner188 {
	z-index: 999;
  position: absolute;
	height: 100vh;
	width: 100%;
	display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; 
  
	background-color:$Fifth ;
  
}
 #spinn213 {
    width: 8rem   ;
    height: 8rem    ;
  
  
 
}
  
</style>
