<template>
	<div>
		<Headers />
		<SectionModify
			:first1_1="$t('Design.RequestDesign')"
			:first1_2="$admins == true ? $t('Modify.ComingRequset') :$t('Modify.MyRequset') "
			:secound1_1="$t('Modify.EasySteps')"
			:secound1_2="'MyOrder'"
		/>
		<spinner v-if="loading" style="text-align: center"></spinner>
		<div v-else class="row justify-content-center mt-5">
			<div class="col-10 col-sm-10 Modify" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
				<div class="row justify-content-between ElMessiri-18 font-bold" style="position: relative; margin: 15px;">
					<div class="col-12 col-sm-12 col-lg-6">
						<div class="mt-4">
							<span class="required">1.</span> {{ $t('Design.ChooseType')
							}}<span class="required">*</span>
							<div v-if="SendError">
								<div v-if="!$v.Order.setId.required" class="invalid-feedback block">
									{{ $t('LogIn.required') }}
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 col-sm-12 col-lg-6 mt-4">
						<select
							class="col-lg-12 col-sm-10 col-12 select"
							name="Dimension"
							@change="getDimensions"
							v-model="Order.setId"
						>
							<option v-for="GetSet in GetSets" :key="GetSet.id" class="unit" :value="GetSet.id">
								<span v-if="$i18n.locale == 'ar'"> {{ GetSet.nameAr }} </span>
								<span v-if="$i18n.locale == 'en'"> {{ GetSet.nameEn }} </span>
							</option>
						</select>
					</div>
					<div class="col-12 col-sm-12 col-lg-12">
						<span class="required">2.</span> {{ $t('Modify.WriteDimensions')
						}}<span class="required">*</span>
						<div class="row justify-content-between">
							<div
								class="col-12 col-sm-12 col-lg-6"
								v-for="(dimension, index) in getdimensions"
								:key="dimension.id"
							>
								<div
									:v-model="(dimensionsid[index] = dimension.id)"
									class="ElMessiri-16 mt-2 mb-1"
									v-if="$i18n.locale == 'ar'"
									style="font-weight: 500"
								>
									{{ dimension.nameAr }}
								</div>
								<div
									class="ElMessiri-16 mt-2 mb-1"
									v-if="$i18n.locale == 'en'"
									style="font-weight: 500"
									:v-model="(dimensionsid[index] = dimension.id)"
								>
									{{ dimension.nameEn }}
								</div>

								<input
									class="col-4"
									v-model="dimensionValue[index]"
									type="text"
									:placeholder="$i18n.locale == 'ar' ? dimension.nameAr : dimension.nameEn"
									style="width: 65%"
										 
								/>

								<div v-if="SendError">
									<div v-if="!$v.dimensionValue.required" class="invalid-feedback block">
										{{ $t('LogIn.required') }}
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="col-12 col-sm-12 col-lg-12 mt-4">
						<span class="required">3.</span> {{ $t('Modify.WriteModifications') }}
				 
							<textarea class="col-12  WriteModifications" v-model="extraInfo"   rows="10"></textarea>
					</div>

					<div class="col-12 col-sm-12 col-lg-6 mt-4">
						<span class="required">4.</span> {{ $t('Modify.ChooseScheme') }}<span class="required">*</span>
						<div v-if="SendError">
							<div v-if="!$v.Option.required" class="invalid-feedback block">
								{{ $t('LogIn.required') }}
							</div>
						</div>
					</div>
					<div class="col-12 col-sm-12 col-lg-6 mt-4">
						<div v-for="selectOption in Option" :key="selectOption.id" style="margin: 8px">
							<div class="getOption  row justify-content-around">
								<span class="col-4" v-if="`${$i18n.locale}` == 'en'"> {{ selectOption.nameEn }} </span>
								<span class="col-4" v-if="`${$i18n.locale}` == 'ar'"> {{ selectOption.nameAr }} </span>
								<a class="SeeModel col-4" :href="selectOption.modelUrl">
									{{ $t('Control.Options.SeeModel') }}
								</a>
								<span class="col-2">
									{{ selectOption.price }} $
									<span class="priceBefore">{{ selectOption.priceBefore }} $</span>
								</span>
								<span   @click="DeleteOption(selectOption.id)" class="AddDelete col-2">
									{{ $t('Modify.ClickDelete') }}
								</span>
							</div>
						</div>
						<div class="dropdown">
							<button
								class="btn btn-secondary dropdown-toggle"
								type="button"
								id="dropdownMenuButton1"
								data-bs-toggle="dropdown"
								aria-expanded="false"
							>
								{{ $t('Modify.ChooesFile') }}
							</button>
							<ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1" style="width: 100%">
								<li class="row justify-content-center" v-for="gettOptio in gettOption" :key="gettOptio.id" style="margin: 8px">
									<div class="getOption  row justify-content-around">
										<span class="col-4" v-if="`${$i18n.locale}` == 'en'"> {{ gettOptio.nameEn }} </span>
										<span class="col-4" v-if="`${$i18n.locale}` == 'ar'"> {{ gettOptio.nameAr }} </span>
										<a class="SeeModel col-4" :href="gettOptio.modelUrl">
											{{ $t('Control.Options.SeeModel') }}
										</a>
										<span class="col-2" >
											{{ gettOptio.price }} $
											<span class="priceBefore">{{ gettOptio.priceBefore }} $</span>
										</span>
										<span @click="addOption(gettOptio)" class="AddDelete col-2">
											{{ $t('Modify.ClickAdd') }}</span
										>
									</div>
								</li>
							</ul>
						</div>
					</div>
					<div class="col-12 col-sm-12 col-lg-6 mt-4">
						<span class="required">5.</span> {{ $t('Modify.UploadDraft') }}
					</div>

					<div class="col-12 col-sm-12 col-lg-6">
						<div class="col-10 mt-4">
							<!-- for input add file -->
							<div v-for="(input, index) in inputs" :key="index" class="ElMessiri-18">
								<button @click="test(index)" class="AddNewFile ClickToAdd mt-2">
									{{ $t('Control.AddFile.ClicktoAdd') }}
								</button>
								<span style="margin: 0px 10px 0px 0px">{{ input.File.name }}</span>
								<input
									type="file"
									:id="index"
									@change="previewFilesimg"
									@click="setindex(index)"
									style="display: none"
								/>
							</div>
							<button
								@click="Addinput"
								class="AddNewFile File"
								:class="$i18n.locale == 'ar' ? 'ar-style' : 'en-style'"
							>
								{{ $t('Control.AddFile.AddFile') }}
								<img src="../../assets/Admin/Add.svg" alt="Add" />
							</button>
						</div>
					</div>
				</div>
				<div class="row justify-content-center">
					<button v-if="!loadingSend" class="col-6 mb-4 mt-4 Save ElMessiri-18" @click="svae">
						{{ $t('Modify.Save') }}
					</button>
					<Spinner v-else style="text-align: center"></Spinner>
					<div
						v-if="Error"
						class="alert alert-danger col-sm-12 col-12 ElMessiri-20"
						style="text-align: center"
						role="alert"
					>
						{{ $t('Control.Eroor') }}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Headers from '../Header/Header.vue';
import SectionModify from '../RequestModify/SectionModify.vue';
import Multiselect from 'vue-multiselect';
import router from '../../router';
import { mapState } from 'vuex';
import Spinner from '../Spinner.vue';
import { required } from 'vuelidate/lib/validators';
export default {
	data() {
		return {
			loading: true,
			loadingSend: false,
			extraInfo: '',
			Option: [],
			inputs: [],
			indexArray:null,
			dimensionValue: [],
			dimensionsid: [],
			Error: false,
			SendError: false,
			Order: {
				setId: null,
			},
		};
	},
	validations: {
		dimensionValue: { required },
		Option: { required },
		Order: {
			setId: { required },
		},
	},
	components: {
		Spinner,
		Headers,
		SectionModify,
		Multiselect,
	},
	methods: {
	 
			previewFilesimg(event) {
		 
			this.inputs[this.indexArray].File = event.target.files[0];

		 
		},
			setindex(index) {
			this.indexArray = index;
		 
		},
			test(va) {
			 
			document.getElementById(va).click();
			this.indexArray = va;
		},
		validationStatus: function (validation) {
			return typeof validation != 'undefined' ? validation.$error : false;
		},
		getDimensions() {
		 
			this.$store.dispatch('Set/getSetDimensions', this.Order.setId);
		},
		addOption(gettOptio) {
			this.Option.push(gettOptio);
		},
		DeleteOption(value) {
			this.Option.forEach((element, index) => {
				if (element.id == value) {
					this.Option.splice(index, 1);
				}
			});
		},
		svae() {
			this.SendError = true;
			this.$v.$touch();
			if (this.$v.$pendding || this.$v.$error) return;
			this.loadingSend = true;

			this.$store
				.dispatch('Order/AddRequest', {
					setId: this.Order.setId,
					extraInfo: this.extraInfo,
					dimensionValue: this.dimensionValue,
					dimensionsid: this.dimensionsid,
					Option: this.Option,
					inputs: this.inputs,
				})
				.then((res) => {
					res == true ? router.push({ name: 'MyOrder' }) : (this.Error = true);
					this.loadingSend = false;
				});
		},
		Addinput() {
			if (this.inputs.length != 10) this.inputs.push({ File: {} });
		},
	},
	mounted() {
		this.$store.dispatch('Set/getSet');
		this.$store.dispatch('Option/getOption').then((res1) => {
			 
			this.loading = false;
		});
	},
	computed: {
		...mapState({
			gettOption: (state) => state.Option.get_Option,
			GetSets: (state) => state.Set.get_Set,
			getdimensions: (state) => state.Set.get_Set_Dimensions.dimensions,
		}),
	},
};
</script>

<style lang="scss">
.select {
	border-radius: 5px;
	border: solid 1px #757575;
	margin: 7px 0px;
	background-color: #f3f3f3;
	color: #787878;
}
</style>
