<template>
	<div class="ClcikToSend " :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
    <img src="../../assets/Admin/close.svg" @click="Close" alt="Close" class="close" :style="$i18n.locale == 'ar' ? 'float: left;' : 'float: right;'  ">
		<div> {{$t('Modify.SelectPrice')}} </div>
		<input type="text"  v-model="price" @keyup="NumberOnly"/>
		<div>{{$t('Modify.SelectDate')}}  </div>
    <Datepicker :value="state.date" :format="customFormatter"></Datepicker>
     <div class="row justify-content-center">
    <button v-if="!loading" class="col-6 mt-2 mb-2 updateData" @click="updateData">{{$t('Modify.Save')}}</button>
    <Spinner v-else ></Spinner>
     </div>
 
	</div>
</template>

<script>
 import Datepicker from 'vuejs-datepicker';
  import moment from 'moment';
  import Spinner from '../Spinner.vue';
  import { mapState } from 'vuex';
export default {
  data() {
   return {
     state : {
  date: new Date(2023, 7,  16)
},
date :'',
price:'',
loading: false
   }
  },
 components : {
   Datepicker,
   Spinner
 },
  methods: {
    customFormatter(date) {
      this.date =  moment(date).format('YYYY-MM-DD');
      return moment(date).format('YYYY-MM-DD');
    },
     updateData() {
       this.loading = true
    this.$store.dispatch('Request/ReviewARequest', {date:this.date, price:this.price,id:this.getIdRequest} ).then(res => {
      res == true ? this.loading= false : ''
      	this.$store.dispatch('Request/getRequestByid', this.$route.params.idRequest)
      this.$emit('Close')
       
    })
  },
  NumberOnly() {
          var x = this.price.replace(/\D/g, '');
  // this.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
  
   this.price = x
  },
  Close() {
    this.$emit('Close')
  }
  },
  computed : {
    ...mapState({
      	getIdRequest: (state) => state.Request.get_RequestByid.id,
    })
  }
	 
}
</script>

<style lang="scss" scoped>
 
.ClcikToSend {
	background-color: $requset1;
  padding: 5px 10px;
  width: 100%;
}
.updateData {
   background-color: $requset1_1;
   color: $primary;
}
.vdp-datepicker {
    position: relative;
    text-align: -webkit-auto;
}
.close {
      width: 15px;
    height: 15px;
}
</style>
