<template>
	<div class="sectio1">
		<div class="elements">
			<h1 class="ElMessiri-20 font-bold">{{ $t('section1.Professional') }}</h1>
			<h3 class="ElMessiri-18 font-bold">{{ $t('section1.We_draw') }}</h3>
			<h5 class="ElMessiri-16 font-bold">{{ $t('section1.Architectural') }}</h5>
			<div class="row justify-content-around b_order">
				<div @click="Open('RequestModify')" class="order col_4 col-lg-12 ElMessiri-20">
					{{ $t('section1.modification') }}
				</div>

				<div @click="Open('AddOrder')" class="order col_4 col-lg-12 ElMessiri-20">
					{{ $t('section1.design') }}
				</div>
			</div>
		</div>
		<ErorrFavorites
			v-if="LongINErorr"
			:ErorrFavorite="LongINErorr"
			@ErorrFavoriteEdite="LongINErorrRes"
		></ErorrFavorites>
	</div>
</template>

<script>
import router from '../router/index';
import ErorrFavorites from './Scheme/ErorrFavorites.vue';
export default {
	components: { ErorrFavorites },
	//	:to="`/${$i18n.locale}/RequestModify/`"  	:to="`/${$i18n.locale}/AddOrder/`"
	data() {
		return {
			LongINErorr: false,
		};
	},
	methods: {
		LongINErorrRes(va) {
			this.LongINErorr = va;
		},
		Open(va) {
						var token1 = JSON.parse(localStorage.getItem('token'));	 
			if (token1 == null) {
				this.LongINErorr = true;
			 
			 
			}else {
			router.push({ name: va });
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.sectio1 {
	margin-top: 15px;
}

.elements {
	text-align: center;
}
.elements h1 {
	color: $t-third;
}
.elements h3 {
	color: $t_primary;
}
.elements h5 {
	color: $t_secondary;
}
.sectio1 .elements h1,
h3,
h5 {
	padding-top: 15px;
}

/**.elements
    border-radius: 35px;
    height: 45px;
    width: 550px;
    border: 1px solid #707070;
  <img src="../assets/home/Path33.png" alt="">

  	<button class="order col_4 col-lg-12 ElMessiri-20 " >{{$t('section1.modification')}}</button> 
*/

.sectio1 .elements .b_order .order {
	background-color: $secondary;
	border: 1px solid $secondary;
	color: $t_forth;
   cursor: pointer;
	border-radius: 35px;
	height: 37px;
	width: 470px;
	text-decoration: none;
	margin-top: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
}
@media only screen and (max-width: 500px) {
	.sectio1 .elements .b_order .order {
		width: 347px;
	}
}
</style>
