/* eslint-disable prettier/prettier */
import SetServices from '../Services/SetServices';
 import {PostCategory,DeleteChategory} from '../Services/SetAPI';
 import axios from 'axios';
 
export default {
    getSet({commit}) {
        var offset = 0;
        var limit = 10;
        SetServices.getSet({limit: limit, offset : offset}).then (response => {
             
            commit('get_Set', response.data);
            limit = response.data.total ;
      
            offset = 10;
            if(limit>10) {
           
                limit -=10 
                SetServices.getSet({limit: limit, offset : offset}).then( response=> {
                     commit("get_Set",response.data); 
                 }).catch(error => {
                     console.log(error.response.data.msg);
                 })
             } 
        }).catch(error => {
            console.log(error.response.data.msg);
        })
    },
    getSetDimensions({commit,state},data) {
      
       SetServices.getSetDimensions(data).then(response => {
           
           commit('get_Set_Dimensions',response.data);
       }).catch(error => {
           console.log(error.response.data.msg);
       })

    },
    PostSet({commit,state},data) { 
       var dimensionsId = new Array();
        data.Dimensions.forEach(element => {
            data.Select.forEach( selected => {
               if(element.nameAr == selected || element.nameEn == selected) {
                dimensionsId.push(element.id);
               }
           });
       });
       return new Promise((resolve,reject) => {
       SetServices.PostSet({Set:data.Set,dimensionsIds:dimensionsId}).then( response => {
            if(response.status == 201) {
                resolve(true);
             
            } 
          }).catch(error => {
            resolve(false);
         
            console.log(error.response.data.msg);
            reject(error.response);
          })
       }) 
    
    },
    patchSet({state},data) {
        return new Promise((resolve,reject) => {
            SetServices.PatchSet(data).then ( response => {
                if(response.status == 200) {
                    state.get_Set.forEach((element,index) => {
                        if(element.id == data.id) {
                         state.get_Set[index] = data
                        }
                     });
                   
                     resolve(true);
                   
                    }
            }).catch(error => {
                resolve(false);
                console.log(error.response.data.msg);
                reject(error.response);
            })
        })
      
    },
    deleteSet({state},data) {
            return  new Promise ((resolve,reject) => {
               SetServices.DeleteSet(data).then (response => {
            
                   if(response.status == 204) {
                    state.get_Set.forEach((element,index) => {
                       if(element.id == data) {
                        state.get_Set.splice(index,1)
                       }
                    });
                    resolve(true)
                }
               }).catch(error => {
                resolve(false)
                  reject(error.response)
                   console.log(error.response.data.msg);
               })
            })
    }
}
 
  /**
   *         axios.delete(DeleteChategory+Categoryid,{
        headers: {
              
            Authorization: "Bearer " + JSON.parse(localStorage.getItem('token'))
         }  
      }).then (response => {
           commit("Success","success")
         
      }).catch(error => {
        
      
    })
   */