/* eslint-disable prettier/prettier */
export default {
    user :{},
   
    error:'',
    errorLogIN:'',
    svaeEmail:'',
   
    New_reset :{},
 
}