<template>
	<div>
		<spinner v-if="loading" style="text-align: center"></spinner>
		<div v-else class="row justify-content-center mt-5">
			<div class="col-10 col-sm-10 Modify" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
				<div
					class="row justify-content-between ElMessiri-18 font-bold"
					style="position: relative; margin: 15px"
				>
					<div class="col-12 col-sm-12 col-lg-6">
						<div class="mt-4">
							<span class="required">1.</span> {{ $t('Modify.SelectScheme')
							}}<span class="required">*</span>
							<div v-if="SendError">
								<div v-if="!$v.schemeId.required" class="invalid-feedback block">
									{{ $t('LogIn.required') }}
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 col-sm-12 col-lg-6 mt-4">
						<input class="col-lg-12 col-sm-10 col-12" type="file" ref="fileInput" style="display: none" />

						<button
							class="AddImageFav ElMessiri-18"
							:style="{ 'background-image': `url(${imageShow})` }"
							@click="openFavorite"
						>
							<span v-if="!imageShow"> {{ $t('Modify.goFaveorite') }} </span>
						</button>
					</div>
					<div class="col-12 col-sm-12 col-lg-12 controlPostion">
						<span class="required">2.</span> {{ $t('Modify.WriteDimensions')
						}}<span class="required">*</span>
						<div v-for="(dimension, index) in getdimensions" :key="dimension.id">
							<div
								:v-model="(dimensionsid[index] = dimension.dimensionId)"
								class="ElMessiri-16 mt-2 mb-1"
								v-if="$i18n.locale == 'ar'"
								style="font-weight: 500"
							>
								{{ dimension.nameAr }}
							</div>
							<div
								class="ElMessiri-16 mt-2 mb-1"
								v-if="$i18n.locale == 'en'"
								style="font-weight: 500"
								:v-model="(dimensionsid[index] = dimension.dimensionId)"
							>
								{{ dimension.nameEn }}
							</div>

							<input class="col-4" v-model="dimensionValue[index]" type="text" />
							<div v-if="SendError">
								<div v-if="!$v.dimensionValue.required" class="invalid-feedback block">
									{{ $t('LogIn.required') }}
								</div>
							</div>
						</div>
					</div>

					<div class="col-12 col-sm-12 col-lg-6 mt-4">
						<span class="required">3.</span> {{ $t('Modify.WriteModifications') }}
						 
						<textarea class="col-7 WriteModifications" v-model="extraInfo" cols="33" rows="10"></textarea>
					</div>
					<div class="col-12 col-sm-12 col-lg-6"></div>
					<div class="col-12 col-sm-12 col-lg-6 mt-4">
						<span class="required">4.</span> {{ $t('Modify.ChooseScheme') }}<span class="required">*</span>
						<div v-if="SendError">
							<div v-if="!$v.Option.required" class="invalid-feedback block">
								{{ $t('LogIn.required') }}
							</div>
						</div>
					</div>
					<div class="col-12 col-sm-12 col-lg-6 mt-2">
						<div v-for="selectOption in Option" :key="selectOption.id" style="margin: 8px">
							<div class="getOption  row justify-content-around">
								<span class="col-4" v-if="`${$i18n.locale}` == 'en'"> {{ selectOption.nameEn }} </span>
								<span class="col-4" v-if="`${$i18n.locale}` == 'ar'"> {{ selectOption.nameAr }} </span>
								<a class="SeeModel  col-4" :href="selectOption.modelUrl">
									{{ $t('Control.Options.SeeModel') }}
								</a>
								<span  class="col-2">
									{{ selectOption.price }} $
									<span class="priceBefore">{{ selectOption.priceBefore }} $</span>
								</span>
								<span @click="DeleteOption(selectOption.id)" class="AddDelete col-2">
									{{ $t('Modify.ClickDelete') }}
								</span>
							</div>
						</div>
						<div class="dropdown">
							<button
								class="btn btn-secondary dropdown-toggle"
								type="button"
								id="dropdownMenuButton1"
								data-bs-toggle="dropdown"
								aria-expanded="false"
								style="width: 83%"
							>
								{{ $t('Modify.ChooesFile') }}
							</button>
							<ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1" style="width: 100%">
								<li class="row justify-content-center" v-for="gettOptio in gettOption" :key="gettOptio.id" style="margin: 8px">
									<div class="getOption row justify-content-around">
										<span  class="col-4" v-if="`${$i18n.locale}` == 'en'"> {{ gettOptio.nameEn }} </span>
										<span  class="col-4" v-if="`${$i18n.locale}` == 'ar'"> {{ gettOptio.nameAr }} </span>
										<a class="SeeModel col-4" :href="gettOptio.modelUrl">
											{{ $t('Control.Options.SeeModel') }}
										</a>
										<span  class="col-2">
											{{ gettOptio.price }} $
											<span class="priceBefore">{{ gettOptio.priceBefore }} $</span>
										</span>
										<span @click="addOption(gettOptio)" class="AddDelete col-2">
											{{ $t('Modify.ClickAdd') }}</span
										>
									</div>
								</li>
							</ul>
						</div>
					</div>
					<div class="col-12 col-sm-12 col-lg-6 mt-4">
						<span class="required">5.</span> {{ $t('Modify.UploadDraft') }}
					</div>

					<div class="col-12 col-sm-12 col-lg-6">
						<div class="col-10 mt-4">
							<!-- for input add file -->
							<div v-for="(input, index) in inputs" :key="index" class="ElMessiri-18">
								<button @click="test(index)" class="AddNewFile ClickToAdd mt-2">
									{{ $t('Control.AddFile.ClicktoAdd') }}
								</button>
								<span style="margin: 0px 10px 0px 0px">{{ input.File.name }}</span>
								<input
									type="file"
									:id="index"
									@change="previewFilesimg"
									@click="setindex(index)"
									style="display: none"
								/>
							</div>
							<button
								@click="Addinput"
								class="AddNewFile File"
								:class="$i18n.locale == 'ar' ? 'ar-style' : 'en-style'"
							>
								{{ $t('Control.AddFile.AddFile') }}
								<img src="../../assets/Admin/Add.svg" alt="Add" />
							</button>
						</div>
					</div>
				</div>
				<div class="row justify-content-center">
					<button v-if="!loadingSend" class="col-6 mb-4 mt-4 Save ElMessiri-18" @click="svae">
						{{ $t('Modify.Save') }}
					</button>
					<Spinner v-else style="text-align: center"></Spinner>
					<div
						v-if="Error"
						class="alert alert-danger col-sm-12 col-12 ElMessiri-20"
						style="text-align: center"
						role="alert"
					>
						{{ $t('Control.Eroor') }}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import router from '../../router/index';
import { mapState } from 'vuex';
import Spinner from '../Spinner.vue';
import { required } from 'vuelidate/lib/validators';
export default {
	data() {
		return {
			imageShow: '',
			loading: true,
			loadingSend: false,
			schemeId: null,
			extraInfo: '',
			Option: [],
			inputs: [],
			indexArray: null,
			dimensionValue: [],
			dimensionsid: [],
			Error: false,
			SendError: false,
		};
	},
	validations: {
		schemeId: { required },
		dimensionValue: { required },
		Option: { required },
	},
	components: {
		Spinner,
		Multiselect,
	},
	methods: {
		validationStatus: function (validation) {
			return typeof validation != 'undefined' ? validation.$error : false;
		},
		addOption(gettOptio) {
			this.Option.forEach((element) => {
				if (element.id == gettOptio.id) {
					return;
				}
			});
			this.Option.push(gettOptio);
		},
		DeleteOption(value) {
			this.Option.forEach((element, index) => {
				if (element.id == value) {
					this.Option.splice(index, 1);
				}
			});
		},
		openFavorite() {
			this.$store.dispatch('Scheme/SendFromModify', true);
		 
			router.push({ name: 'Favorite' });
		},
		svae() {
			this.SendError = true;
			this.$v.$touch();
			if (this.$v.$pendding || this.$v.$error) return;
			this.loadingSend = true;
			this.$store
				.dispatch('Request/AddRequest', {
					schemeId: this.schemeId,
					extraInfo: this.extraInfo,
					dimensionValue: this.dimensionValue,
					dimensionsid: this.dimensionsid,
					Option: this.Option,
					gettOption: this.gettOption,
					inputs: this.inputs,
				})
				.then((res) => {
					res == true ? [router.push({ name: 'MyRequests' }), (this.imageShow = '')] : (this.Error = true);
					this.loadingSend = false;
				});
		},
		Addinput() {
			if (this.inputs.length != 10) this.inputs.push({ File: {} });
		},
		test(va) {
			 
			document.getElementById(va).click();
			this.indexArray = va;
		},
		previewFilesimg(event) {
		 
			this.inputs[this.indexArray].File = event.target.files[0];

		 
		},
		setindex(index) {
			this.indexArray = index;
		 
		},
	},
	mounted() {
		if (this.SentToModify != null) {
			this.schemeId = this.SentToModify;
			this.$store.dispatch('SchemeDetails/getSchemeByID', this.SentToModify).then((res) => {
				this.imageShow = this.getDetails.imageUrl;
				this.loading = false;
			});
		}
		this.$store.dispatch('Option/getOption').then((res1) => {
		 
			this.loading = false;
		});
	},
	computed: {
		...mapState({
			SentToModify: (state) => state.Request.SentToModify,
			getDetails: (state) => state.SchemeDetails.getDetails,
			getdimensions: (state) => state.SchemeDetails.getDetails.dimensions,

			gettOption: (state) => state.Option.get_Option,
			sortEn: (state) => state.Option.sortEn,
			sortAr: (state) => state.Option.sortAr,
		}),
	},
	destroyed() {
		(this.imageShow = ''),
			(this.loading = true),
			(this.loadingSend = false),
			(this.schemeId = null),
			(this.extraInfo = ''),
			(this.Option = []),
			(this.inputs = []),
			(this.indexArray = null),
			(this.dimensionValue = []),
			(this.dimensionsid = []),
			(this.Error = false),
			(this.SendError = false);
	},
};
</script>

<style lang="scss">
.block {
	display: block !important;
}
.Modify {
	margin-bottom: 113px;
	background-color: #ffffff;
	box-shadow: 1px 1px 15px 1px rgb(0 0 0 / 40%);
	border-radius: 5px;
}
.required {
	color: $required;
}
.Save {
	background-color: $third;
	color: $t_forth;
	border-radius: 5px;
	border: none;
	margin: 25px 0px;
	padding: 5px 0px;
}
.WriteModifications {
  padding: 5px;
}
.AddImageFav {
	width: 100%;
	height: 280px;
	background-color: $Fifth;
	color: $t-Sex;
	text-decoration: underline;
	border-radius: 22px;
	border: 3px solid $border;
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center center;
}
.controlPostion {
	margin-top: -210px !important;
}
@media only screen and (max-width: 1000px) {
	.controlPostion {
		margin-top: 0px !important;
	}
}
.AddDelete {
	background-color: $Seven;
	color: $t-third;
	padding: 2px 7px;
	border-radius: 5px;
	cursor: pointer;
	    display: flex;
    align-items: center;
	font-size: 13px;
    font-weight: 500;
}

/**
	<multiselect
							class="mt-4"
							v-model="Option"
							:options="$i18n.locale == 'ar' ? sortAr : sortEn"
							:multiple="true"
						>
						</multiselect>

*/
</style>
