<template>
	<div class="row justify-content-center" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
		<div class="col-md-6 col-lg-8 col-sm-10 col-12 CatControl">
			<div class="row justify-content-between">
				<div class="col-4">
					<div class="ElMessiri-25 font-bold">{{ $t('Control.Set') }}</div>
				</div>
				<div class="col-1">
					<img
						src="../../assets/Admin/close.svg"
						alt="close"
						@click="close"
						style="width: 22px; margin: 6px 25px"
					/>
				</div>
			</div>
			<div class="line"></div>
			<div class="row justify-content-around ElMessiri-20">
				<div class="col-md-6 col-lg-6 col-sm-10 col-12">
					<div>{{ $t('Control.SetBody.Add') }}</div>
					<div class="line"></div>
					<div>{{ $t('Control.NameAr') }}</div>
					<input
						class="col-lg-12 col-sm-10 col-12"
						:class="{ 'is-invalid': validationStatus($v.SetInput.nameAr) }"
						type="text"
						v-model="SetInput.nameAr"
						:placeholder="$t('Control.NameAr')"
					/>
					<div v-if="SendError">
						<div v-if="!$v.SetInput.nameAr.required" class="invalid-feedback block">
							{{ $t('LogIn.required') }}
						</div>
					</div>

					<div>{{ $t('Control.NameEn') }}</div>
					<input
						class="col-lg-12 col-sm-10 col-12"
						:class="{ 'is-invalid': validationStatus($v.SetInput.nameEn) }"
						:dir="SetInput.nameEn == '' ? '' : 'ltr'"
						type="text"
						v-model="SetInput.nameEn"
						:placeholder="$t('Control.NameEn')"
					/>
					<div v-if="SendError">
						<div v-if="!$v.SetInput.nameEn.required" class="invalid-feedback block">
							{{ $t('LogIn.required') }}
						</div>
					</div>
					<div>{{ $t('Control.Dimensions') }}</div>

					<multiselect v-model="selected" :options="$i18n.locale == 'ar' ? sort : sortEn" :multiple="true">
					</multiselect>
					<button v-if="!loading" class="col-lg-12 col-sm-10 col-12 save" @click="Save">
						{{ $t('Control.Save') }}
					</button>
					<div v-if="loading" class="row justify-content-center">
						<div class="col-lg-3 col-3">
							<Spinner />
						</div>
					</div>
				</div>
				<div class="col-md-6 col-lg-4 col-sm-10 col-12">
					<div v-for="get_Set in get_Set" :key="get_Set.id">
						<div class="Category">
							<span v-if="`${$i18n.locale}` == 'en'"> {{ get_Set.nameEn }} </span>
							<span v-if="`${$i18n.locale}` == 'ar'"> {{ get_Set.nameAr }} </span>

							<span>
								<img
									class="deleteEdite"
									@click="delet(get_Set.id)"
									src="../../assets/Admin/Delete.svg"
									alt="Delete"
										style="cursor: pointer;"
								/>
								<img
									class="deleteEdite"
									@click="Edit(get_Set)"
									src="../../assets/Admin/Edit.svg"
									alt="Edit"
										style="cursor: pointer;"
								/>
							</span>
						</div>
					</div>
				</div>
			</div>
		 
			<div v-if="Eroor" class="alert alert-danger col-sm-12 col-12 ElMessiri-20" role="alert">
				{{ $t('Control.Eroor') }}
			</div>
			<Confirm v-if="ConfirmDelete" @ConfirmClose="ConfirmClose" @ConfirmYes="ConfirmYes" />
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import Multiselect from 'vue-multiselect';
import Confirm from './ConfirmDelete.vue';
import { required } from 'vuelidate/lib/validators';
import Spinner from '../Spinner.vue';
export default {
	components: { Multiselect, Confirm, Spinner },
	data() {
		return {
			SetInput: {
				id: null,
				nameAr: '',
				nameEn: '',
			},
			loading: false,
			SendError: false,
			Eroor: false,
			EditTosend: false,
			ConfirmDelete: false,
			IdDelete: null,
			selected: [],
		};
	},
	validations: {
		SetInput: {
			nameAr: { required },
			nameEn: { required },
		},
	 
	},
	methods: {
		validationStatus: function (validation) {
			return typeof validation != 'undefined' ? validation.$error : false;
		},
		close() {
			this.$emit('Close');
		},
		delet(va) {
			this.ConfirmDelete = true;
			this.IdDelete = va;
		},
		ConfirmClose() {
			this.ConfirmDelete = false;
		},
		ConfirmYes() {
			this.loading = true;
			this.$store.dispatch('Set/deleteSet', this.IdDelete).then((res) => {
				res == true
					? (this.SetInput = {
							id: null,
							nameAr: '',
							nameEn: '',
					  })
					: (this.Eroor = true);

				this.SendError = false;
				this.loading = false;
			});

			this.ConfirmDelete = false;
		},
		Edit(Set) {
			this.SetInput.nameAr = Set.nameAr;
			this.SetInput.nameEn = Set.nameEn;
			this.SetInput.id = Set.id;
			this.EditTosend = true;
		},
		Save() {
			this.SendError = true;
			this.$v.$touch();
			if (this.$v.$pendding || this.$v.$error) return;
			this.loading = true;
			if (this.EditTosend) {
				this.$store.dispatch('Set/patchSet', this.SetInput).then((res1) => {
					res1 == true
						? (this.CategoryInput = {
								id: null,
								nameAr: '',
								nameEn: '',
						  })
						: (this.Eroor = true);
					this.SendError = false;
					this.loading = false;
				});
				this.EditTosend = false;
			} else {
				this.$store
					.dispatch('Set/PostSet', {
						Set: this.SetInput,
						Select: this.selected,
						Dimensions: this.Dimensions,
					})
					.then((res) => {
						res == true
							? (this.SetInput = {
									id: null,
									nameAr: '',
									nameEn: '',
							  })
							: (this.Eroor = true);
						this.$store.dispatch('Set/getSet');
						this.SendError = false;
						this.loading = false;
						this.selected = [];
					});
			}
		},
	},
	computed: {
		...mapState({
			get_Set: (state) => state.Set.get_Set,
			Dimensions: (state) => state.Dimensions.get_Dimensions,
			sort: (state) => state.Dimensions.sortAr,
			sortEn: (state) => state.Dimensions.sortEn,
		}),
	},
	mounted() {
		this.$store.dispatch('Set/getSet');
		this.$store.dispatch('Dimensions/getDimensions');
	},
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
