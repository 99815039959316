/* eslint-disable prettier/prettier */
 
import OptionServices from '../Services/OptionServices';
 
 
 
export default {
    getOption({commit}) {
        var offset = 0;
        var limit = 10;
        return new Promise((resolve,reject) => {

        
        OptionServices.getOption({limit: limit, offset : offset}).then (response => {
           
            commit('get_Option', response.data);
            limit = response.data.total ;
      
            offset = 10;
            if(limit>10) {
           
                limit -=10 
                OptionServices.getOption({limit: limit, offset : offset}).then( response=> {
                     commit("get_Option",response.data); 
                 }).catch(error => {
                     console.log(error.response.data.msg);
                 })
                
             } 
             resolve(true);
        }).catch(error => {
            resolve(false);
            console.log(error.response.data.msg);
        })
    })
    },
    getCategoryDimensions({commit,state},data) {
        let IDCategory;
        state.get_Category.forEach(element => {
            if(data.split(' ').join('') ==  element.nameAr.split(' ').join('') || data.split(' ').join('') == element.nameEn.split(' ').join('')) {
                IDCategory= element.id;
            }
        });
   
        OptionServices.getCategoryDimensions(IDCategory).then(response => {
       
           commit('get_Category_Dimensions',response.data);
       }).catch(error => {
           console.log(error.response.data.msg);
       })

    },
    PostOption({commit},data) { 
        var Option = new FormData();
        Option.set('nameAr', data.nameAr);
        Option.set('nameEn', data.nameEn);
        Option.set('model', data.image);
        Option.set('descriptionAr', data.descriptionAr);
        Option.set('descriptionEn', data.descriptionEn);
        Option.set('price', data.price);
        Option.set('priceBefore', data.priceBefore);

       return new Promise((resolve,reject) => {
        OptionServices.PostOption(Option).then( response => {
            if(response.status == 201) {
                resolve(true);
      
            } 
          }).catch(error => {
            resolve(false);
            console.log(error.response.data.msg);
            reject(error.response);
          })
       }) 
      /* axios.post(PostCategory,{
            nameAr : data.Category.nameAr,
            nameEn : data.Category.nameEn,
            dimensionsIds : dimensionsId
         },  {
            headers: {
              
               Authorization: "Bearer " +  JSON.parse(localStorage.getItem('token'))
            }
         }).then(response => {
         
          
             commit('Success','success');
             dimensionsId = [];
        
         }).catch(error => {
          
         })*/ 
    },
    patchOption({state},data) {
        var OptionPatch = new FormData();
        OptionPatch.set('nameAr', data.nameAr);
        OptionPatch.set('nameEn', data.nameEn);
       

        OptionPatch.set('descriptionAr', data.descriptionAr);
        OptionPatch.set('descriptionEn', data.descriptionEn);
        OptionPatch.set('price', data.price);
        OptionPatch.set('priceBefore', data.priceBefore);
       
     
      if (typeof data.image === 'string') { 
    //  console.log("no send image");
      }else {
        OptionPatch.set('model', data.image);
      }
   
   

        return new Promise((resolve,reject) => {
            OptionServices.PatchOption({OptionPatch:OptionPatch , id : data.id }).then ( response => {
                if(response.status == 200) {
                    state.get_Option.forEach((element,index) => {
                        if(element.id == data.id) {
                         state.get_Option[index] = data
                        }
                     });
             
                     resolve(true);
                   
                    }
            }).catch(error => {
                resolve(false);
                console.log(error.response.data.msg);
                reject(error.response);
            })
        })
      
    },
    deleteOption({state},id) {
            return  new Promise ((resolve,reject) => {
                OptionServices.deleteOption(id).then (response => {
           
                   if(response.status == 204) {
                    state.get_Option.forEach((element,index) => {
                       
                       if(element.id == id) {
                        state.get_Option.splice(index,1)
                       }
                    });
                    resolve(true)
                }
               }).catch(error => {
                resolve(false)
                  reject(error.response)
                   console.log(error.response.data.msg);
               })
            })
    }
}
 
  /**
   *         axios.delete(DeleteChategory+Categoryid,{
        headers: {
              
            Authorization: "Bearer " + JSON.parse(localStorage.getItem('token'))
         }  
      }).then (response => {
           commit("Success","success")
      
      }).catch(error => {
        
       
    })
   */