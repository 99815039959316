<template>
	<div class="row justify-content-center">
		<div class="col-md-5 col-lg-4 col-sm-10 col-10 addfile">
			<div class="row justify-content-center">
				<div class="col-sm-10 col-md-8 col-lg-10">
					<p class="ElMessiri-25 AddNew">{{ $t('Control.AddFile.NewFile') }}</p>
					<div class="line"></div>

					<div v-for="(input, index) in inputs" :key="index" class="ElMessiri-18">
						<button @click="test(index)" class="AddNewFile ClickToAdd" >	{{ $t('Control.AddFile.ClicktoAdd') }} </button>
						 <span style="margin: 0px 10px 0px 0px;">{{input.File.name}}</span>
						<input type="file" :id="index" @change="previewFilesimg" @click="setindex(index)" style="    display: none;" />
						<input type="checkbox" @click="setFree(index)" name="Free" value="1" />
						{{ $t('Control.AddFile.Free') }}
					</div>
				</div>
			</div>
			<div class="row justify-content-center ElMessiri-20">
				<div class="col-sm-10 col-md-8 col-lg-10">
					<button
						@click="Addinput"
						class="AddNewFile File"
						:class="$i18n.locale == 'ar' ? 'ar-style' : 'en-style'"
					>
						{{ $t('Control.AddFile.AddFile') }}
						<img src="../../assets/Admin/Add.svg" alt="Add" />
					</button>
					<button v-if="!loading" @click="SaveFile" class="SaveFile File">
						{{ $t('Control.Save') }}
					</button>
					<spinner v-if="loading" />
					<div v-if="SuccessAdd" class="alert alert-success" role="alert">
						{{ $t('Control.Success') }}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import Spinner from '../Spinner.vue'
export default {
	data() {
		return {
			inputs: [],
			indexArray: null,
			loading: false
		};
	},
	components : {
  Spinner,
	},
	computed: {
		...mapState({
			SuccessAdd: (state) => state.Scheme.successAdd,
		}),
	},
	methods: {
		test(va) {
		 
			document.getElementById(va).click();
			this.indexArray = va;
		},
		Addinput() {
			this.inputs.push({ File: {}, Type: 0 });
		},
		previewFilesimg(event) {
			 
			this.inputs[this.indexArray].File = event.target.files[0];

		 
		},
		setindex(index) {
			this.indexArray = index;
			 
		},
		setFree(va) {
			if (this.inputs[va].Type == 1) {
				this.inputs[va].Type = 0;
			} else {
				this.inputs[va].Type = 1;
			}
		 
		},
		SaveFile() {
			this.loading = true;
			this.$store.dispatch('Scheme/PostFile', this.inputs).then(res => {
				 
				if(res) {
					this.loading = false;	
						this.$emit('Close'); 
				}else {
				  
				}
			})
		},
	},
};
</script>

<style lang="scss" >
.ar-style {
	text-align: right;
}
.en-style {
	text-align: left;
	direction: rtl;
}
.addfile {
	text-align: center;
	background-color: $Fifth;
	z-index: 101;
	position: fixed;
	top: 25%;

	border-radius: 20px;
	padding: 25px 10px;
}
.addfile .AddNew {
	color: $t_forth;
	margin-bottom: 0px;
}
.addfile .line {
	height: 2px;
	background-color: $forth;
}
.addfile .ClickToAdd {
	    margin: 10px 5px;
    padding: 3px 10px;
    border-radius: 5px;
}
.File {
	border-radius: 5px;
	border: none;
	margin: 25px 0px;
	padding: 6px 10px;
	display: block;
	width: 100%;
}
.SaveFile {
	background-color: #a8a8a8;
	color: #000000;
}
.AddNewFile {
	background-color: $Sex;
	color: $t-Sex;

	padding-right: 10px;
	border: 2px solid $t_secondary;
}
</style>
