/* eslint-disable prettier/prettier */
export default {
  set_Empty(state) {
    state.get_Dimensions = []
  },
  get_Dimensions(state,data) {
     
  /*    for(var i=0;i<data.data.length;i++) {
        state.get_Dimensions.push(data.data[i])
       
       if(state.get_Dimensions[0].id == data.data[i].id) {
         state.get_Dimensions = []; 
         state.get_Dimensions.push(data.data[i])
       }
 
    }*/
    for(var i=0;i<data.data.length;i++) {
      state.get_Dimensions.push(data.data[i])
  }
 
  //   state.get_Dimensions = data.data;

    data.data.forEach(element => {
        if(element.nameAr == state.sortAr[0] || element.nameEn == state.sortEn[0] ) {
          state.sortAr =[],
          state.sortEn = []
        }
        state.sortAr.push ( element.nameAr)
       state.sortEn.push(element.nameEn) 
      }); 
    },
    Success(state,data) {
      state.success = data
    },
    
}