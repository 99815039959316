/* eslint-disable prettier/prettier */
export default {
  
  get_RequestList(state,data) {
      for(var i=0;i<data.data.length;i++) {
        state.get_RequestList.push(data.data[i])
        if(state.get_RequestList[0].id == data.data[i].id) {
         state.get_RequestList = []; 
         state.get_RequestList.push(data.data[i])
       }
    }
    },
    get_RequestByid(state,data) {
         state.get_RequestByid = data.data;
    },
    get_FileByType(state,file) {
      state.get_FileUpload=[]
      state.get_FileExtra= []
      file.forEach(element => {
          if(element.type == 1) {
            state.get_FileUpload.push(element)
          
          }else {
            state.get_FileExtra.push(element)
          }
        });
    },
    SentToModify(state,data) {
      state.SentToModify = data
    }
}