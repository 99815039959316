import Vue from 'vue';
import Vuex from 'vuex';
import Login from '../components/Login/Store/index';
import SignUp from "../components/SignUp/Store/index";
import Category from "../components/Category/Store/index";
import Scheme from "../components/Scheme/Store/index";
import SchemeDetails from '../components/DetailsScheme/Store/index';
import Type from '../components/Type/Store/index';
import Dimensions from '../components/Dimensions/Store/index';
import Request from '../components/RequestModify/Request/Store/index';
import Option from '../components/Option/Store/index';
import Set from "../components/Set/Store/index";
import Order from "../components/DesignOrder/Order/Store/index"
import General from  "../components/General/Store/index"
import Plan from "../components/Plan/Store/index"
Vue.use(Vuex);

export default new Vuex.Store({
	state: {},
	mutations: {},
	actions: {},
	modules: {
		Login,
		SignUp,
		Category,
		Scheme,
		SchemeDetails,
		Type,
		Dimensions,
		Request,
		Option,
		Set,
		Order,
		General,
		Plan
	},
});
