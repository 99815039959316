/* eslint-disable prettier/prettier */
 
import state from './Request.state'
import getters from  './Request.getters'
import mutations from  './Request.mutation'
import actions from  './Request.action'
export default {
    namespaced : true,
    state ,
    getters,
    mutations,
    actions,
    module : {}
}
