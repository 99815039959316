<template>
	<div class="row justify-content-center" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
		<div class="col-md-6 col-lg-8 col-sm-10 col-12 CatControl">
			<div class="row justify-content-between">
				<div class="col-4">
					<div class="ElMessiri-25 font-bold">{{ $t('Control.Scheme.AddNew') }}</div>
				</div>
				<div class="col-1">
					<img
						src="../../assets/Admin/close.svg"
						alt="close"
						@click="close"
						style="width: 22px; margin: 6px 25px"
					/>
				</div>
			</div>

			<div class="line"></div>
			<div class="row justify-content-around ElMessiri-20">
				<div class="col-md-6 col-lg-5 col-sm-10 col-12">
					<div>{{ $t('Control.NameAr') }}</div>
					<input
						class="col-lg-12 col-sm-10 col-12"
						:class="{ 'is-invalid': validationStatus($v.SchemeInput.nameAr) }"
						type="text"
						v-model="SchemeInput.nameAr"
						:placeholder="$t('Control.NameAr')"
					/>
					<div v-if="SendError">
						<div v-if="!$v.SchemeInput.nameAr.required" class="invalid-feedback block">
							{{ $t('LogIn.required') }}
						</div>
					</div>

					<div>{{ $t('Control.NameEn') }}</div>
					<input
						class="col-lg-12 col-sm-10 col-12"
						:class="{ 'is-invalid': validationStatus($v.SchemeInput.nameEn) }"
						:dir="SchemeInput.nameEn == '' ? '' : 'ltr'"
						type="text"
						v-model="SchemeInput.nameEn"
						:placeholder="$t('Control.NameEn')"
					/>
					<div v-if="SendError">
						<div v-if="!$v.SchemeInput.nameEn.required" class="invalid-feedback block">
							{{ $t('LogIn.required') }}
						</div>
					</div>
					<div>{{ $t('Control.Scheme.Description') }}</div>

					<input
						class="col-lg-12 col-sm-10 col-12"
						:class="{ 'is-invalid': validationStatus($v.SchemeInput.Description) }"
						type="text"
						v-model="SchemeInput.Description"
						:placeholder="$t('Control.Scheme.Description')"
					/>
					<div v-if="SendError">
						<div v-if="!$v.SchemeInput.Description.required" class="invalid-feedback block">
							{{ $t('LogIn.required') }}
						</div>
					</div>
					<div>{{ $t('Control.Scheme.type') }}</div>
					<select class="col-lg-12 col-sm-10 col-12" name="Type" v-model="SchemeInput.Type">
						<option class="color" value="" disabled selected>{{ $t('Control.Scheme.type') }}</option>
						<option v-for="Type in types" :key="Type.id">
							<span v-if="`${$i18n.locale}` == 'ar'"> {{ Type.nameAr }}</span>
							<span v-if="`${$i18n.locale}` == 'en'"> {{ Type.nameEn }}</span>
						</option>
					</select>
					<div v-if="SendError">
						<div v-if="!$v.SchemeInput.Type.required" class="invalid-feedback block">
							{{ $t('LogIn.required') }}
						</div>
					</div>
					<div>{{ $t('Control.Scheme.Category') }}</div>
					<select
						class="col-lg-12 col-sm-10 col-12"
						name="Type"
						@change="getDimensions"
						v-model="SchemeInput.Category"
					>
						<option class="color" value="" disabled selected>{{ $t('Control.Scheme.Category') }}</option>
						<option v-for="Category in Categorys" :key="Category.id">
							<span v-if="`${$i18n.locale}` == 'ar'"> {{ Category.nameAr }}</span>
							<span v-if="`${$i18n.locale}` == 'en'"> {{ Category.nameEn }}</span>
						</option>
					</select>
					<div v-if="SendError">
						<div v-if="!$v.SchemeInput.Category.required" class="invalid-feedback block">
							{{ $t('LogIn.required') }}
						</div>
					</div>
				</div>
				<div class="col-md-6 col-lg-5 col-sm-10 col-12">
					<div>{{ $t('Control.Scheme.DimensionsScheme') }}</div>
					<div class="line"></div>
					<div v-for="(getCD, index) in getCategoryDimensions" :key="getCD.id">
						<div v-if="`${$i18n.locale}` == 'ar'">
						<div :v-model="(DimensionsSInCID[index] = getCD.id)">{{ getCD.nameAr }}</div>
							<input
								class="col-lg-12 col-sm-10 col-12"
								v-model="DimensionsSInCValue[index]"
								type="text"
								:placeholder="getCD.nameAr"
							/>
						</div>
						<div v-if="`${$i18n.locale}` == 'en'">
							<div>{{ getCD.nameEn }}</div>
							<input class="col-lg-12 col-sm-10 col-12" 	v-model="DimensionsSInCValue[index]" type="text" :placeholder="getCD.nameEn" />
						</div>
					     <div v-if="SendError">
							<div :class="DimensionsSInCValue[index]==''?'block':''"   class="invalid-feedback ">
								{{ $t('LogIn.required') }}
							</div>
						 </div>	 
					</div>

					<div>{{ $t('Control.Scheme.imgScheme') }}</div>

					<input
						class="col-lg-12 col-sm-10 col-12"
						type="file"
						ref="fileInput"
						style="display: none"
						@input="pickFile"
					/>

					<button class="AddImage" @click="$refs.fileInput.click()">
						<img
							:class="imageShow == '' ? '' : 'Hiden'"
							style="margin: 110px auto"
							src="../../assets/Admin/Add.svg"
							alt=""
						/>
						<div class="imagePreviewWrapper" :style="{ 'background-image': `url(${imageShow})` }"></div>
					</button>
					<div v-if="SendError">
						<div v-if="!$v.SchemeInput.image.required" class="invalid-feedback block">
							{{ $t('LogIn.required') }}
						</div>
					</div>
				</div>
			</div>
			<div class="row justify-content-center ElMessiri-20">
				<button v-if="!loading" class="col-lg-6 col-sm-8 col-10 save" @click="Save">
					{{ $t('Control.Scheme.SaveAndMove') }}
				</button>
				 <Spinner v-if="loading" class="col-lg-6 col-sm-8 col-10" style=" text-align: center;"  />
			 
					 <div v-if="Eroor" class="alert alert-danger col-sm-12 col-12 ElMessiri-20" role="alert">
                	{{ $t('Control.Eroor') }}
           </div>
			</div>
			<div class="row justify-content-center">
				 
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import Spinner from '../Spinner.vue'
export default {
	props: ['EditSchem','DataEditeScheme'],
	data() {
		return {
			SchemeInput: {
				id: null,
				nameAr: '',
				nameEn: '',
				Description: '',
				Type: '',
				Category: '',
				image: '',
			},
			SendError: false,
				loading : false,
				Eroor: false,
			ArrayErorr: [],
			DimensionsSInCValue: ['','','','','','','','','','','','','','','',''],
			DimensionsSInCID: [],
			imageShow: '',

			EditTosend: false,
		};
	},
	validations: {
		SchemeInput: {
			nameAr: { required },
			nameEn: { required },
			Description: { required },
			Type: { required },
			Category: { required },
			image: { required },
		},
		 
	},
	components: {Spinner},
	methods: {
		validationStatus: function (validation) {
			return typeof validation != 'undefined' ? validation.$error : false;
		},
		close() {
			this.$emit('Close');
		},
		getDimensions() {
			if(this.EditSchem) {
			return;
			}
		 
			this.$store.dispatch('Category/getCategoryDimensions', this.SchemeInput.Category);
		},
		Edit(type) {
			this.TypeInput.nameAr = type.nameAr;
			this.TypeInput.nameEn = type.nameEn;
			this.TypeInput.id = type.id;
			this.EditTosend = true;
		 
		},
		Save() {
				 this.getCategoryDimensions.forEach( (element,index )=> {
					 if(this.DimensionsSInCValue[index] == '') {
				 
						return false;
					 }
				 });
			this.SendError = true;
			this.$v.$touch();
			if (this.$v.$pendding || this.$v.$error) return;
        this.loading = true;
			if (this.EditSchem) {
			 
				this.$store.dispatch('Scheme/EditScheme',this.SchemeInput ).then(res => {
					res= true ? this.$emit('Close'):this.Eroor = true  
					   this.loading = false;
				})
			} else {
				this.$store
					.dispatch('Scheme/PostScheme', {
						SchemeInput: this.SchemeInput,
						DimensionsSInCValue: this.DimensionsSInCValue,
						DimensionsSInCID: this.DimensionsSInCID,
					})
					.then((res) => {
					 
						if(res){
								this.$emit('openAddFile', res);
						}else {
							this.Eroor = true
						}

						   this.loading = false;
					
					});
			}
		},
		pickFile(event) {
			 
			this.SchemeInput.image = event.target.files[0];
			let input = this.$refs.fileInput;
			let file = input.files;
			if (file && file[0]) {
				let reader = new FileReader();
				reader.onload = (e) => {
					this.imageShow = e.target.result;
				};
				reader.readAsDataURL(file[0]);
				this.$emit('input', file[0]);
			}
		},
		test() {
			//console.log('hello ');
		},
	},
	computed: {
		...mapState({
			types: (state) => state.Type.get_type,
			Categorys: (state) => state.Category.get_Category,
			getCategoryDimensions: (state) => state.Category.get_Category_Dimensions,
	 
		}),
	},
	mounted() {
				if(this.EditSchem) {
		 
      this.SchemeInput.id = this.DataEditeScheme.id;
      this.SchemeInput.nameAr = this.DataEditeScheme.nameAr;
      this.SchemeInput.nameEn = this.DataEditeScheme.nameEn;
	  if(this.$i18n.locale == 'ar') {
     this.SchemeInput.Type = this.DataEditeScheme.typeNameAr;
      this.SchemeInput.Category = this.DataEditeScheme.categoryNameAr;
	  }else {
		    this.SchemeInput.Type = this.DataEditeScheme.typeNameEn;
      this.SchemeInput.Category = this.DataEditeScheme.categoryNameEn;
	  }
    this.SchemeInput.Description= this.DataEditeScheme.description
      this.imageShow = this.DataEditeScheme.imageUrl;
	  this.SchemeInput.image = this.DataEditeScheme.imageUrl;
 
		}
		this.$store.dispatch('Type/getType');
		this.$store.dispatch('Category/getCategory');
	},
 
	destroyed(){
		this.EditTosend = false;
	    
		this.SchemeInput =  {
				id: null,
				nameAr: '',
				nameEn: '',
				Description: '',
				Type: '',
				Category: '',
				image: '',
			}
	}
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
.block {
	display: block;
}
.CatControl {
	background-color: #ffffff;
	z-index: 11;
	position: absolute;
	top: 25%;
	border-radius: 20px;
	padding: 25px 15px;
}
.CatControl .line {
	background-color: $forth;
	height: 1px;
	margin: 0px 0px 20px 0px;
}
.CatControl input {
	border-radius: 5px;
	border: solid 1px #757575;
	margin: 7px 0px;
}
/**.alert-success
 ::-webkit-file-upload-button {
  background: black;
  color: red;
  padding: 1em;
}
 */

.CatControl select {
	background-color: $Sex;
	color: $t-Sex;
}

.Category {
	margin: 10px 0px;
	padding: 4px 5px;
	border-radius: 5px;
	display: flex;
	justify-content: space-between;
	background-color: $secondary;
}
.deleteEdite {
	width: 20px;
	margin: 0px 6px;
	height: 20px;
}
.save {
	background-color: $third;
	color: $t_forth;
	border-radius: 5px;
	border: none;
	margin: 25px 0px;
	padding: 5px 0px;
}
.alert-success {
	text-align: center;
}
.alert-danger {
	text-align: center;
}
.AddImage {
	width: 100%;
	max-height: 250px;
	height: 250px;
	background-color: $Fifth;
	border: 1px solid $t_secondary;
	border-radius: 10px;
}
.imagePreviewWrapper {
	width: 100%;
	height: 100%;
	display: block;
	cursor: pointer;
	margin: 0 auto 30px;

	background-repeat: no-repeat;
	background-size: cover;
}
.Hiden {
	display: none;
}
</style>
