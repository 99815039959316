/* eslint-disable prettier/prettier */
import SchemeDetServices from '../Services/SchemeDetServices';
  
 import axios from 'axios';
export default {
  getSchemeDetails({commit},data) {
    localStorage.setItem('details',data.id);
     commit('Get_Details',data);
  },
  getSchemeByID({commit},data) {
 
   // var details = localStorage.getItem('details');
   return new Promise((resolve,reject) => {
   SchemeDetServices.getSchemeById(data).then(response => {
      commit('Get_Details',response.data.data);
 
      resolve(true);
    }).catch(err => {
      resolve(false);
      console.log(err.response.data.msg);
    })
  })
  },
  getDownload({},data) {
    SchemeDetServices.getDownload(data).then (response=> {
    
     window.open(response.data.data.asset.url , '_blank');

    }).catch(error=> {
      console.log( error.response.data.msg);
    }) 
  },
   
}
 
  /**
   *    
   */