/* eslint-disable prettier/prettier */

import axios from 'axios';
import OrderServices from '../Services/OrderServices';
export default {
	getRequestList({ commit }) {
		var offset = 0;
		var limit = 10;
		return new Promise((resolve, reject) => {
			OrderServices.getRequestList({ limit: limit, offset: offset })
				.then((response) => {
				 
					commit('get_RequestList', response.data);
					limit = response.data.total;

					offset = 10;
					if (limit > 10) {
						limit -= 10;
						RequestServices.getRequestList({ limit: limit, offset: offset })
							.then((response) => {
								commit('get_RequestList', response.data);
							})
							.catch((error) => {
								console.log(error.response.data.msg);
							});
					}
					resolve(true);
				})
				.catch((error) => {
					resolve(false);

					console.log(error.response.data.msg);
					reject(error.response);
				});
		});
	},
	getRequestByid({ commit }, data) {
		return new Promise((resolve, reject) => {
			OrderServices.getRequestByid(data)
				.then((response) => {
					if (response.status == 200) {
						commit('get_RequestByid', response.data);
                        commit('get_FileByType',response.data.data.files)
						resolve(true);
					}
				})
				.catch((err) => {
					resolve(false);
					console.log(err.response.data.msg);
					reject(err.response);
				});
		});
	},
	  AddRequest({ commit, state ,dispatch }, data) {
		var dimensions = new Array();
		var schemeOptionIds = new Array();
		let obj = { dimensionId: 0, value: 0 };
		for (let index = 0; index < data.dimensionsid.length; index++) {
			obj.dimensionId = data.dimensionsid[index];
			obj.value = data.dimensionValue[index];
			dimensions.push(obj);
			obj = { dimensionId: 0, value: 0 };
		}
		 
		data.Option.forEach((el) => {
					schemeOptionIds.push(el.id);
		});
		let dataSend;
		if (data.extraInfo) {
			dataSend = {
				setId: data.setId,
				extraInfo: data.extraInfo,
				dimensions: dimensions,
				schemeOptionIds: schemeOptionIds,
			};
		} else {
			dataSend = { schemeId: data.schemeId, dimensions: dimensions, schemeOptionIds: schemeOptionIds };
		}

		return new Promise((resolve, reject) => {
			OrderServices.postRequest(dataSend)
				.then((response) => {
					if (response.status == 201) {
						if (data.inputs[0]) {
							let id = response.data.data.id;
							dispatch('PostFileExtra',{id: id,inputs :data.inputs });
						}
					 
						resolve(true);
					}
				})
				.catch((error) => {
					resolve(false);

					console.log(error.response.data.msg);
					reject(error.response);
				});
		});
	},
 async	PostFileExtra({commit},data) {
      
	 await   Promise.all(
		data.inputs.map(async (file) => {
			let extentions = file.File.name.split('.').pop();
	 

			const r = await OrderServices.PostFile({ extentions: extentions, id: data.id });

		 

			await axios
				.put(r.data.extraFilePutUrl, file.File)
				.then((response) => {
					 
				 
				})
				.catch((err) => {
					console.log(err.response.data.msg);
				});
		})
	)
		.then((res) => {
			//return true;
			//  resultSend = true;
			//  commit("successAdd","successAdd");
		 
		})
		.catch((error) => {
			// resultSend = false;
			console.log(error.response.data.msg);
			 
		});
	},
	ReviewARequest({ state }, data) {
		return new Promise((resolve, reject) => {
			OrderServices.ReviewARequest(data)
				.then((response) => {
					if (response.status == 200) {
				 
						resolve(true);
					}
				})
				.catch((error) => {
					resolve(false);
					console.log(error.response.data.msg);
					reject(error.response);
				});
		});
	},
	Pay({ commit }, data) {
		return new Promise((resolve, reject) => {
			OrderServices.Pay(data)
				.then((response) => {
					if (response.status == 200) {
						window.open(response.data.url, '_blank');
					}
				})
				.catch((err) => {
					console.log(err.response.data.msg);
				});
		});
	},
	async UploadFile({ commit, state }, data) {
		var resultSend = false;
		var result = await Promise.all(
			data.inputs.map(async (file) => {
				let extentions = file.File.name.split('.').pop();
				 

		 
				const r = await OrderServices.UploadFile({ extentions: extentions, id: data.id });

			 

				await axios
					.put(r.data.extraFilePutUrl, file.File)
					.then((response) => {
				 
					})
					.catch((err) => {
						console.log(err.response.data.msg);
					});
			})
		)
			.then((res) => {
				//return true;
				resultSend = true;
				//  commit("successAdd","successAdd");
			})
			.catch((error) => {
				resultSend = false;
			});
		return new Promise((resolve) => {
			if (resultSend) {
				resolve(true);
			} else {
				resolve(false);
			}
		});
	},
	Download({},data) {
		OrderServices.Download(data).then (response=> {
			if (response.status == 200)
		 window.open(response.data.data.asset.url , '_blank');
	
		}).catch(error=> {
		  console.log( error.response.data.msg);
		}) 
	  },

	 
};
 