<template>
  <div class="  mt-5">
     <div class="container ElMessiri-20 font-bold"> {{getDescription}} </div> 
     <div class="dimensions" :class="$i18n.locale == 'ar' ? 'ar-style' : ''">
         <div class="ElMessiri-18 font-bold">  {{$t('SchemeDetails.dimensions')}}  </div> 
     <div v-for="getDimension in getDimensions" :key="getDimension.dimensionId"   >
        
          <div class="dimensionDetails ElMessiri-16"  >
            <span v-if="`${$i18n.locale}` == 'en'"> {{getDimension.nameEn}} </span>
             <span  v-if="`${$i18n.locale}` == 'ar'" > {{getDimension.nameAr}} </span>
            <span> {{getDimension.value}} </span>

            <span v-if="getDimension.unit == 'm2' ">  {{$t('SchemeDetails.SquareMeters')}}  </span>
               <span v-else-if="getDimension.unit == 'm' ">  {{$t('SchemeDetails.Maters')}}  </span>
               <span v-else> {{getDimension.unit}} </span>
            </div> 
         
        </div>
        <div class="mt-4 ElMessiri-18 font-bold"> {{$t('SchemeDetails.ExistingFiles')}} </div>
          <div v-for="getFile  in getFiles" :key="getFile.assetId" > 
             <div class="dimensionDetails ElMessiri-16">
            <span v-if="getFile.extension == 'jpg' || getFile.extension == 'png' || getFile.extension == 'jpeg' || getFile.extension == 'gif' " > {{ $t('SchemeDetails.Image') }}</span>
            <span v-else-if="getFile.extension == 'pdf' " > {{ $t('SchemeDetails.pdfFile') }}</span>
             <span v-else-if="getFile.extension == 'dwg' " > {{ $t('SchemeDetails.AutoCAD') }}</span>
             <span v-else> {{ $t('SchemeDetails.File') }}</span>

            <span> <img @click="download(getFile.assetId)" src="../../assets/Details/download.svg" alt="download" style="cursor: pointer;">  </span>
            <span v-if="getFile.type == 0 "  style=" width: 45px;" >  {{$t('SchemeDetails.Free')}} </span> 
            <span v-else style=" width: 45px;" >   </span>


            </div> 
            </div>
      </div> 
     
         
  </div>
</template>

<script>
export default {
 props : ['getDimensions','getFiles','getDescription','getid'],
 methods : {
   download(assetId) {
     
     this.$store.dispatch('SchemeDetails/getDownload', {id:this.getid , assetId: assetId});
   }
 }
}
</script>

<style lang="scss"  >
.ar-style {
	direction: rtl;
 
}
.container {
  text-align: center;
  
}
.dimensions {
  margin: 30px;
}
.dimensionDetails {
      display: flex;
    justify-content: space-between;
        padding: 0px 30px;
      margin: 5px 0px 13px 0px;
    height: 35px;
    background-color: $secondary;
    align-items: center;
    border-radius: 5px;
}
 
</style>