/* eslint-disable prettier/prettier */
import { getSet ,PostSet, DeleteSet,PatchSet  } from './SetAPI';
import axios from 'axios';
export default {
	getSet(data) {
		return axios.get(getSet+"?limit="+data.limit+"&offset="+data.offset).then((res) => {
			return res;
		});
	},
	getSetDimensions(data) {
     return axios.get(getSet+"/"+ data,{
		headers: {
              
			Authorization: "Bearer " +  JSON.parse(localStorage.getItem('token'))
		 }
	 }).then((res) => {
		 return res;
	 })
	},
	PostSet(data) { 
		return axios.post(PostSet,{
			nameEn : data.Set.nameEn,
			nameAr :data.Set.nameAr,
			dimensionsIds: data.dimensionsIds
		},{
				headers: {
				  
				   Authorization: "Bearer " +  JSON.parse(localStorage.getItem('token'))
				}
		}).then((res) => {
			return res;
		});
	},
	PatchSet(data) {
		return axios.patch(PatchSet+ data.id,{
			nameAr:data.nameAr,
			nameEn: data.nameEn
		},  {
            headers: {
              
               Authorization: "Bearer " +  JSON.parse(localStorage.getItem('token'))
            }
         }).then((res) => {
			return res;
		});
	},
	DeleteSet(data) {
		return axios.delete(DeleteSet+data,{
            headers: {
              
               Authorization: "Bearer " +  JSON.parse(localStorage.getItem('token'))
            }
         }).then((res) => {
			return res;
		});
	},
};
