/* eslint-disable prettier/prettier */
import { download, getSchemeByid } from './SchemeDetAPI';
import axios from 'axios';
export default {
	getDownload(data) {
	 
		return axios.get(download+data.id+"/"+data.assetId, {
            headers: {
              
               Authorization: "Bearer " +  JSON.parse(localStorage.getItem('token'))
            }
         }).then((res) => {
			return res;
		});
	},
	getSchemeById(data) {
	 
	 return	axios.get(getSchemeByid+data).then((res) =>{
			return res;
		})
	}
 
};
