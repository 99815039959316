/* eslint-disable prettier/prettier */
import {
	getScheme,
	getSchemeDefault,
	maxmin,
	DeletScheme,
	PostScheme,
	getSearch,
	PostFavorites,
	RemoveFavorites,
	EditScheme,
	getSchemeByCategory,
	Space_Category,
	PostFile,
} from './SchemeAPI';
import axios from 'axios';
export default {
	getScheme(data) {
	 
		var token = JSON.parse(localStorage.getItem('token'));
		if (token === null) {
		 
			return axios.get(getSchemeDefault + data).then((res) => {
				return res;
			});
		} else {
		 
			return axios.get(getScheme + data + '&userId=' + localStorage.getItem('user')).then((res) => {
				return res;
			});
		}
	},
	getSchemeFavorit(data) {
       return axios.get(getScheme +data+'&userId='+ localStorage.getItem('user')+'&favorites=true').then((res)=>{
		   return res;
	   })
	},
	getSchemeByCateg(data) {
	 
		return axios.get(getSchemeByCategory + data.offset + '&categoryId=' + data.id).then((res) => {
			return res;
		});
	},
	SpaceCategory(data) {
		//0&maxSpace=1000&minSpace=0&categoryId=1
		return axios
			.get(
				Space_Category +
					data.offset +
					'&maxSpace=' +
					data.all.top +
					'&minSpace=' +
					data.all.down +
					'&categoryId=' +
					data.id
			)
			.then((res) => {
				return res;
			});
	},
	DeleteScheme(data) {
		return axios
			.delete(DeletScheme + data, {
				headers: {
					Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('token')),
				},
			})
			.then((res) => {
				return res;
			});
	},
	getmaxmin(top, down) {
	 
		return axios.get(maxmin + '&maxSpace=' + top + '&minSpace' + down).then((res) => {
			return res;
		});
	},
	PostScheme(data) {
		return axios
			.post(PostScheme, data, {
				headers: {
					'Content-Type': 'multipart/form-data',
					Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('token')),
				},
			})
			.then((res) => {
				return res;
			});
	},
	PostFile(data) {
		return axios
			.post(
				PostFile + data.id,
				{ extension: data.extentions, type: data.Type },
				{
					headers: {
						Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('token')),
					},
				}
			)
			.then((res) => {
				return res;
			});
	},
	EditScheme(data) {
	 
		axios.patch();
	},
	getSearch(data) {
		//0&name=
	 
		return axios.get(getSearch + data.offset + '&name=' + data.search).then((res) => {
			 
			return res;
		});
	},
	PostFavorites(data) {
		return axios
			.post(
				PostFavorites + data,
				{},
				{
					headers: {
						Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('token')),
					},
				}
			)
			.then((res) => {
				return res;
			});
	},
	PatchFavorites(data) {
		return axios
			.patch(
				RemoveFavorites + data,
				{},
				{
					headers: {
						Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('token')),
					},
				}
			)
			.then((res) => {
				return res;
			});
	},
	PostCategory(data) {
		return axios
			.post(PostCategory, {
				nameAr: data.nameAr,
				nameEn: data.nameEn,
			})
			.then((res) => {
				return res;
			});
	},
};
/**
 * axios.post(PostScheme,data,{
		headers : {
			Authorization: "Bearer " +  localStorage.getItem('token')
		}
	})

		axios({
		method: 'post',
		url: PostScheme,
		data: data,
		
		 headers: {  'Content-Type': 'multipart/form-data' , Authorization: "Bearer " +  localStorage.getItem('token') }
		
		})
 * 
 */
