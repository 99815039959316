/* eslint-disable prettier/prettier */
import { getType, PostType,PatchType,DeleteType } from './TypeAPI';
import axios from 'axios';
export default {
	GetType(data) {
		return axios.get(getType+data.limit+"&offset="+data.offset).then((res) => {
			return res;
		});
	},
	PostType(data) {
		return axios.post(PostType,{
			nameAr : data.nameAr,
			nameEn : data.nameEn
		}, { headers: {
              
			Authorization: "Bearer " + JSON.parse(localStorage.getItem('token'))
		 }
		}).then((res) => {
			return res;
		});
	},
	PatchType(data) {
		return axios.patch(PatchType+ data.id,{
			nameAr:data.nameAr,
			nameEn: data.nameEn
		},  {
            headers: {
              
               Authorization: "Bearer " +   JSON.parse(localStorage.getItem('token'))
            }
         }).then((res) => {
			return res;
		});
	},
	DeleteType(data) {
		return axios.delete(DeleteType+data ,{
            headers: {
              
               Authorization: "Bearer " +  JSON.parse(localStorage.getItem('token'))
            }
         }).then((res) => {
			return res;
		});
	},
};
