/* eslint-disable prettier/prettier */
import {  getOption } from './OptionAPI';
import axios from 'axios';
export default {
	getOption(data) {
		return axios.get(getOption+"?limit="+data.limit+"&offset="+data.offset, {
			headers: {
              
				Authorization: "Bearer " +  JSON.parse(localStorage.getItem('token'))
			 }
		}).then((res) => {
			return res;
		});
	},
 
	PostOption(data) { 
		 
		return axios.post(getOption,data ,{
			
				headers: {
				  
				   Authorization: "Bearer " +  JSON.parse(localStorage.getItem('token'))
				}
		}).then((res) => {
			return res;
		});
	},
	PatchOption(data) {
		return axios.patch(getOption+'/'+data.id,data.OptionPatch ,  {
            headers: {
              
               Authorization: "Bearer " +  JSON.parse(localStorage.getItem('token'))
            }
         }).then((res) => {
			return res;
		});
	},
	deleteOption(data) {
		return axios.delete(getOption+"/"+data,{
            headers: {
              
               Authorization: "Bearer " +  JSON.parse(localStorage.getItem('token'))
            }
         }).then((res) => {
			return res;
		});
	},
};
