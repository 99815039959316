<template>
	<div>
 
		<div class="parent ElMessiri-15">
				<img class="search"  src="../../assets/icon/search.svg" alt="search" />
			<input :class="$i18n.locale == 'ar' ? 'inputAr' : 'inputEn'"   v-model="variable.SearchInput" v-on:keyup="Search" />
				 
		 
		</div>
	</div>
</template>

<script>
export default {
	props: ['IsCategory', 'IdCategory'],
	data() {
		return { 
			 disabled: 1,
			activeButton:0,
			variable: {
				Search: true,
				Default: false,
				Space: false,
				Category: false,
				SearchInput: '',
				offset: 0,
				all: {
					down: null,
					top: null,
				},
			},
		};
	},
	methods: {
	 
		Search() {
			if (this.variable.SearchInput == '') {
				this.$store.dispatch('Scheme/getScheme', this.variable.offset);
				(this.variable.Search = false),
					(this.variable.Default = true),
					(this.variable.Space = false),
					(this.variable.Category = false),
					this.$emit('eventname', this.variable);
	 
			} else {
				(this.variable.Search = true),
					(this.variable.Default = false),
					(this.variable.Space = false),
					(this.variable.Category = false),
					this.$emit('eventname', this.variable);
				this.$store.dispatch('Scheme/getSearch', {
					search: this.variable.SearchInput,
					offset: this.variable.offset,
				});
			}
		},
	},
};
</script>

<style lang="scss" scoped>
/** Search pox */
.parent {
	display: grid;
	place-items: center;
	margin-top: 15px;
	position: relative;
}
.parent input {
	width: 600px;
	height: 50px;
	border-radius: 25px;
	    padding-left: 20px;
	border: 2px solid $forth;
}
.parent .inputEn {padding-left: 50px}
.parent .inputAr {
	 direction: rtl;
    padding-right: 20px;
}
 .inputfocus {margin-bottom: 100px;}
.parent .grey {
		background-color: $forth !important;
	color: $t-third !important;
} 
/*
.parent .btn_shearch:focus {
	background-color: $forth;
	color: $t-third;
}*/
.search {
 /*margin-left: 245px;
	margin-top: -19px;*/

	position: absolute;
	    position: absolute;
    left: 29%;
    top: 10%;
	width: 38px;
    cursor: pointer;
}
@media only screen and (max-width: 900px) { 
	.search {
     /*      margin-left: 100px;
    margin-top: -18px;*/
	left: 18%;
	}
}
@media only screen and (max-width: 700px) {
	.parent input {
		width: 280px;
	}
	.search {
     /*      margin-left: 100px;
    margin-top: -18px;*/
	left: 10%;
	}
	 
}

/**  
	<button
				v-bind:class="activeButton == 0  ? 'grey' : ''"
				@click="minmax(0)"
				class="btn_shearch"
			>
				   {{ $t('buttonSearch.all') }}  
			</button>
			<button
				v-bind:class="activeButton == 1  ? 'grey' : ''"
				@click="minmax(1)"
				class="btn_shearch"
			>
				   {{ $t('buttonSearch.less') }} 500
			</button>
			<button
				v-bind:class="activeButton == 2  ? 'grey' : '' "
			 
				@click="minmax(2) ; "
				class="btn_shearch"
			>
				500-1000
			</button>
			<button
			 
			 v-bind:class="activeButton == 3  ? 'grey' : ''"
				@click="minmax(3);  "
				class="btn_shearch"
			>
				1000-1500
			</button>
			<button
			  v-bind:class="activeButton == 4  ? 'grey' : ''"
				@click="minmax(4);  "
				class="btn_shearch"
			>
				{{ $t('buttonSearch.more') }}  1500  
			</button>
        .parent .btn_shearch {
 	background-color: $Fifth;
	color: $t_Fifth;
	border: 1px solid $secondary;
	position: absolute;
	border-radius: 5px;
} 
.parent .btn_shearch:nth-child(2) {
	margin-left: 285px;
}
.parent .btn_shearch:nth-child(3) {
margin-left: 145px;
}
.parent .btn_shearch:nth-child(4) {
	margin-right: 30px;
}
.parent .btn_shearch:nth-child(5) {
	margin-right: 200px;
}
.parent .btn_shearch:nth-child(6) {
	    margin-left: -390px;
 
}
@media only screen and (max-width: 700px) {
	.parent .btn_shearch {
		margin-top: 90px;
	}
	.parent .btn_shearch:nth-child(2) {
		margin-left: 340px;
	}
	.parent .btn_shearch:nth-child(3) {
		margin-right: -52px;
	}
	.parent .btn_shearch:nth-child(4) {
		margin-right: 173px;
	}
	.parent .btn_shearch:nth-child(5) {
		    margin-right: 340px;
		    width: 82px;
	}
	.parent .btn_shearch:nth-child(6) {
		margin-left:  6px;
		    margin-top: 90px;
	}
	minmax(va) {
			if (va == 0) {
            this.activeButton = 0;
                	(this.variable.Search = false),
					(this.variable.Default = true),
					(this.variable.Space = false),
					(this.variable.Category = false),
					this.$emit('eventname', this.variable);
							this.$store.dispatch('Scheme/getScheme',this.variable.offset );

			} else if (va == 1) {
				this.variable.all.down = 0;
				this.variable.all.top = 500;
				  this.activeButton = 1;
			} else if (va == 2) {
				this.variable.all.down = 500;
				this.variable.all.top = 1000;
				  this.activeButton = 2;
			} else if (va == 3) {
				this.variable.all.down = 1000;
				this.variable.all.top = 1500;
				  this.activeButton = 3;
			} else if (va == 4) {
				this.variable.all.down = 1500;
				this.variable.all.top = 0;
				  this.activeButton = 4;
			}
			if (this.IsCategory) {
				(this.variable.Search = false),
					(this.variable.Default = false),
					(this.variable.Space = true),
					(this.variable.Category = true),
					this.$emit('eventname', this.variable);
				this.$store.dispatch('Scheme/getSpaceCatg', {
					all: this.variable.all,
					offset: this.variable.offset,
					id: this.IdCategory,
				});
			} else if (va != 0) {
				(this.variable.Search = false),
					(this.variable.Default = false),
					(this.variable.Space = true),
					(this.variable.Category = false),
					this.$emit('eventname', this.variable);
		 
				this.$store.dispatch('Scheme/getmaxmin', { all: this.variable.all, offset: this.variable.offset });
			}
		},

*/
</style>
