/* eslint-disable prettier/prettier */
import SchemeServices from '../Services/SchemeServices';
  import {maxmin,EditSchem} from '../Services/SchemeAPI';
  import router from '../../../router/index'
 import axios from 'axios';
export default {
    getScheme({commit},offset) {
        return new Promise((resolve,reject) => {

      
        SchemeServices.getScheme(offset).then (response => {
            if(offset == 0) {
            
                commit('get_total',response.data.total)
            }
          
            commit('get_Scheme', response.data);
            resolve(true);
        }).catch(err => {
            resolve(false);
            reject(err.response);
            console.log(err.response.data.msg);
        })
      })
    },
    getSchemeFavorit({commit},data) {
       
        SchemeServices.getSchemeFavorit(data).then(response => {
            if(data == 0) {
         
                 commit('get_total',response.data.total)
             }
        
             commit('get_Scheme', response.data);
        }).catch(err=> {
            console.log(err.response.data.msg);
        })
    },
    getSchemeCatg({commit} , data) {
       
       SchemeServices.getSchemeByCateg(data).then(response => {
        if(data.offset == 0) {
           
             commit('get_total',response.data.total)
         }
        
           commit("get_scheme_Catg",response.data);
       }).catch( err => {
           console.log(err.response.data.msg);
       })
    },
    getSpaceCatg({commit},data) {
        SchemeServices.SpaceCategory(data).then(response => {
            if(data.offset == 0) {
                
                 commit('get_total',response.data.total)
             }
             commit('get_SpaceCatg',response.data)
        }).catch(err=> {
            console.log(err.response.data.msg);
        })
    },
    DeleteScheme({commit},data) {
         SchemeServices.DeleteScheme(data).then( response=> {
       
             if(response.status ==204 ) {
                router.push({ name: 'Home' });
             }
             
         }).catch(err => {
             console.log(err.response.data.msg);
         })
    },
  /*   getmaxmin({commit},data) {
    
       axios.get(maxmin+data.offset+"&maxSpace="+data.all.top+"&minSpace="+data.all.down).then (response => {
        if(data.offset == 0) {
           
             commit('get_total',response.data.total)
         }
        commit('get_Scheme_max', response.data);
       })
     },*/
    PostScheme({commit,rootState },Scheme) {
              var dimensions = [];
              let get_type = rootState.Type.get_type;
              let get_Category = rootState.Category.get_Category;
              let typeId ='';
              let categoryId = '';
              get_type.forEach(element => {
                  if(element.nameAr == Scheme.SchemeInput.Type || element.nameEn == Scheme.SchemeInput.Type) {
                     typeId = element.id;
                  }
              });
              get_Category.forEach(element => {
                 if(element.nameAr == Scheme.SchemeInput.Category || element.nameEn == Scheme.SchemeInput.Category) {
                     categoryId = element.id;
                 }
             });
                var SchemeBody = new FormData();
              SchemeBody.set('nameAr', Scheme.SchemeInput.nameAr);
              SchemeBody.set('description', Scheme.SchemeInput.Description);
              SchemeBody.set('nameEn', Scheme.SchemeInput.nameEn);
              SchemeBody.set('typeId', typeId);
              SchemeBody.set('categoryId',categoryId);
              SchemeBody.set('image', Scheme.SchemeInput.image);
              if(rootState.Category.get_Category_Dimensions != 0) {
              var ob ={dimensionId:0, value:0};
               for (let index = 0; index < Scheme.DimensionsSInCID.length; index++) {
                 ob.dimensionId = Scheme.DimensionsSInCID[index];
                ob.value = Scheme.DimensionsSInCValue[index];
                // dimensions[index] = ob
                dimensions.push(ob);
                ob ={dimensionId:0, value:0}
                }
        var dimensionsString = JSON.stringify(dimensions);
     
        dimensions.forEach((element,index )=> {
            SchemeBody.append(`dimensions[${index}][dimensionId]`, element.dimensionId)
            SchemeBody.append(`dimensions[${index}][value]`, element.value)
          
        });
            }
       
              return new Promise((resolve, reject) => {
              SchemeServices.PostScheme(SchemeBody).then (response => {
                
                  commit('New_Scheme',response.data);
                  resolve(true);
              }).catch ( err => {
                resolve(false);
                 reject(err.response)
                 console.log(err.response.data.msg);
              })
              })
       
    },
  async  PostFile({commit,state},inputs) {
 var resultSend = false;
  var result = await  Promise.all( inputs.map(async (file)=>{
       let extentions = file.File.name.split('.').pop()
     
 
      const r = await SchemeServices.PostFile({extentions:extentions, id :state.NewScheme.data.id , Type : file.Type })
 
  
  
 
   await  axios.put(r.data.schemeFilePutUrl,file.File).then( response => {
    
   }).catch(err => {
       console.log(err.response.data.msg);
   })
  
  
})).then(res=>{
   //return true;
   resultSend = true;
  //  commit("successAdd","successAdd");
}) .catch (error => {
    resultSend = false;
})
return new Promise((resolve) => {
    if(resultSend) {
        resolve(true);
    }else {
        resolve(false)
    }
})
 

    },
    EditScheme({rootState,dispatch},Edit) {
    
      let get_type = rootState.Type.get_type;
      let get_Category = rootState.Category.get_Category;
      let typeIdEdit ='';
      let categoryIdEdit = '';
      get_type.forEach(element => {
          if(element.nameAr == Edit.Type || element.nameEn ==Edit.Type) {
            typeIdEdit = element.id;
          }
      });
      get_Category.forEach(element => {
         if(element.nameAr == Edit.Category || element.nameEn == Edit.Category) {
            categoryIdEdit = element.id;
         }
     });
      var SchemeBodyEdite = new FormData();
 
      SchemeBodyEdite.set('nameAr', Edit.nameAr);
      SchemeBodyEdite.set('description', Edit.Description);
      SchemeBodyEdite.set('nameEn', Edit.nameEn);
      SchemeBodyEdite.set('typeId', typeIdEdit);
      SchemeBodyEdite.set('categoryId',categoryIdEdit);
     
      if (typeof Edit.image === 'string') { 
       // console.log("no send image");
        }else {
            SchemeBodyEdite.set('image', Edit.image);
        }
        return new Promise ((resolve ,reject) => {

       
      axios.patch(EditSchem+Edit.id, SchemeBodyEdite,{
        headers : {
            'Content-Type': 'multipart/form-data',
            Authorization: "Bearer " +   JSON.parse(localStorage.getItem('token'))
        }
      }).then( response => {
        if(response.status == 200){
      
          dispatch('SchemeDetails/getSchemeByID',Edit.id,{ root: true })
          resolve(true);
        }
      }).catch(err => {
          console.log(err.response.data.msg);
          resolve(false);
      })
    })
      /**   
       *   SchemeServices.EditScheme(Edit).then(response => {
  
   }).catch (err => {
     
   })
       *    
       * 
       */
    },
    getSearch({commit},data) {
   
        SchemeServices.getSearch(data).then( response => {
            if(data.offset == 0) {
             
                 commit('get_total',response.data.total)
             }
            
            commit('get_Scheme_Search', response.data);
        })
    },
    AddFavorites({commit},Favorites) {
       
     
       SchemeServices.PostFavorites(Favorites).then( response => {
        
         commit('Set_Favorites', Favorites);
        }).catch(err => {
            console.log(err.response.data.msg);
        });
    },
    RemoveFavorites({commit},Remove) {
        
     SchemeServices.PatchFavorites(Remove).then(response => {
       
         commit('Remove_Favorites', Remove );
     }).catch (err => {
         console.log(err.response.data.msg);
     })
    },
    SendFromModify({commit},data) {
        commit('SendFromModify',data)
    }
}
 
  /**
   *       
   *   getScheme({commit}) {
        SchemeServices.getScheme().then (response => {
        
            commit('get_Scheme', response.data);
        })
    }
   *       var SchemeBody = new FormData();
         SchemeBody.set('nameAr', Scheme.nameAr);
         SchemeBody.set('nameEn', Scheme.nameEn);
         SchemeBody.set('groundSpace', Scheme.groundSpace);
         SchemeBody.set('buildingSpace', Scheme.buildingSpace);
         SchemeBody.set('typeId', Scheme.typeId);
         SchemeBody.set('categoryId', Scheme.categoryId);
         SchemeBody.append('image',Scheme.image);
         SchemeBody.append('pdf',Scheme.pdf);
         SchemeBody.append('autoCad',Scheme.autoCad);
   * 
   *      
      SchemeServices.getmaxmin(data.top,data.down).then (response => {
       commit('get_Scheme', response.data);
        
      }).catch(error => {
        
      })
   */