/* eslint-disable  */
  import axios from 'axios';
  import {SignUp} from '../Services/SignUpAPI';
  import router from '../../../router/index';
export default {
  Sign_Up({commit},newuser) {
   
    var role;
    if(newuser.role == 'design') {
        role = 2;
    }else if(newuser.role=='user') {
      role = 1;
    }
 
    axios.post (SignUp, {
      name : newuser.Name,
      email : newuser.Email,
      phone :newuser.phone,
      password : newuser.Password,
      role : role
    }).then ( response => {
      if(response.status == 201) {
        commit('SET_SginUp',response.data);
        localStorage.setItem('token',JSON.stringify(response.data.token));
        localStorage.setItem('role', JSON.stringify(response.data.user.role));
        localStorage.setItem('user',JSON.stringify(response.data.user.id));
        
       router.push({ name: 'Home' });
      }
    }).catch(error => {
      let statueser = error.response.status;
     if(statueser == 412) {
      
       commit('ErrorRepet','ErrorRepet');
     }
 
    })
  }
}
 