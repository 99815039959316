<template>
  <div> 
     <Headers/>
     <SectionModify  :first1_1="$t('Modify.modification')"   :first1_2=" $admins == true ? $t('Modify.ComingRequset') :$t('Modify.MyRequset')" :secound1_1="$t('Modify.EasySteps')" :secound1_2="'MyRequests'" />
     <AddModify/>
  </div>
</template>

<script>
 
  import Headers from "../Header/Header.vue";
  import SectionModify from "./SectionModify.vue";
  import AddModify from "./AddModify.vue";
 export default {
 data() {
   return {
     test:"hello"
   }
 },

 components : {
   Headers,
   SectionModify,
   AddModify
 }
}
</script>

<style>

</style>