/* eslint-disable prettier/prettier */
export default {
  getPermission(state,data) {
    for(var i=0;i<data.data.length;i++) {
      state.getPermission.push(data.data[i])
      if(state.getPermission[0].id == data.data[i].id) {
       state.getPermission = []; 
       state.getPermission.push(data.data[i])
     }
  }
  },
  get_PLan(state,data) {
      for(var i=0;i<data.data.length;i++) {
        state.get_PLan.push(data.data[i])
        if(state.get_PLan[0].id == data.data[i].id) {
         state.get_PLan = []; 
         state.get_PLan.push(data.data[i])
       }
    }
    },
   
    Success(state,data) {
      state.success = data
    },
    get_Category_Dimensions(state,data) {
      state.get_Category_Dimensions = data;
    }
}