/* eslint-disable prettier/prettier */
import CategoryServices from '../Services/CategoryServices';
 import {PostCategory,DeleteChategory} from '../Services/CategoryAPI';
 import axios from 'axios';
 
export default {
    getCategory({commit}) {
        var offset = 0;
        var limit = 10;
        CategoryServices.getCategory({limit: limit, offset : offset}).then (response => {
         
            commit('get_Category', response.data);
            limit = response.data.total ;
      
            offset = 10;
            if(limit>10) {
           
                limit -=10 
                CategoryServices.getCategory({limit: limit, offset : offset}).then( response=> {
                     commit("get_Category",response.data); 
                 }).catch(error => {
                    console.log(error.response.data.msg);
                 })
             } 
        }).catch(error => {
            console.log(error.response.data.msg);
        })
    },
    getCategoryDimensions({commit,state},data) {
        let IDCategory;
        state.get_Category.forEach(element => {
            if(data.split(' ').join('') ==  element.nameAr.split(' ').join('') || data.split(' ').join('') == element.nameEn.split(' ').join('')) {
                IDCategory= element.id;
            }
        });
       
       CategoryServices.getCategoryDimensions(IDCategory).then(response => {
     
           commit('get_Category_Dimensions',response.data);
       }).catch(error => {
           console.log(error.response.data.msg);
       })

    },
    PostCategory({commit,state},data) { 
       var dimensionsId = new Array();
        data.Dimensions.forEach(element => {
            data.Select.forEach( selected => {
               if(element.nameAr == selected || element.nameEn == selected) {
                dimensionsId.push(element.id);
               }
           });
       });
       return new Promise((resolve,reject) => {
          CategoryServices.PostCategory({Category:data.Category,dimensionsIds:dimensionsId}).then( response => {
            if(response.status == 201) {
                resolve(true);
             
            } 
          }).catch(error => {
            resolve(false);
         
            console.log(error.response.data.msg);
            reject(error.response);
          })
       }) 
      /* axios.post(PostCategory,{
            nameAr : data.Category.nameAr,
            nameEn : data.Category.nameEn,
            dimensionsIds : dimensionsId
         },  {
            headers: {
              
               Authorization: "Bearer " +  JSON.parse(localStorage.getItem('token'))
            }
         }).then(response => {
            
          
             commit('Success','success');
             dimensionsId = [];
           
         }).catch(error => {
       
         })*/ 
    },
    patchCategory({state},Category) {
        return new Promise((resolve,reject) => {
            CategoryServices.PatchCategory(Category).then ( response => {
                if(response.status == 200) {
                    state.get_Category.forEach((element,index) => {
                        if(element.id == Category.id) {
                         state.get_Category[index] = Category
                        }
                     });
           
                     resolve(true);
                   
                    }
            }).catch(error => {
                resolve(false);
                console.log(error.response.data.msg);
                reject(error.response);
            })
        })
      
    },
    deleteCategory({state},Categoryid) {
            return  new Promise ((resolve,reject) => {
               CategoryServices.DeleteChategory(Categoryid).then (response => {
           
                   if(response.status == 204) {
                    state.get_Category.forEach((element,index) => {
                       if(element.id == Categoryid) {
                        state.get_Category.splice(index,1)
                       }
                    });
                    resolve(true)
                }
               }).catch(error => {
                resolve(false)
                  reject(error.response)
                   console.log(error.response.data.msg);
               })
            })
    }
}
 
  /**
   *         axios.delete(DeleteChategory+Categoryid,{
        headers: {
              
            Authorization: "Bearer " + JSON.parse(localStorage.getItem('token'))
         }  
      }).then (response => {
           commit("Success","success")
          
      }).catch(error => {
        
      
    })
   */