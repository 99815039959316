 export const GetRequestList = 'https://cvim8f5wld.execute-api.us-east-2.amazonaws.com/dev/scheme/request/my-requests?limit=';
 // this is for Admin to see all request 
 export const GetAllRequestList = 'https://cvim8f5wld.execute-api.us-east-2.amazonaws.com/dev/scheme/request/all?limit=';
 export const GetRequestByid = 'https://cvim8f5wld.execute-api.us-east-2.amazonaws.com/dev/scheme/request/detail/';
 export const postRequest ='https://cvim8f5wld.execute-api.us-east-2.amazonaws.com/dev/scheme/request';
 export const ReviewARequest = 'https://cvim8f5wld.execute-api.us-east-2.amazonaws.com/dev/scheme/request/review/';
 export const pay = 'https://cvim8f5wld.execute-api.us-east-2.amazonaws.com/dev/scheme/request/pay/';
 export const PostFile ='https://cvim8f5wld.execute-api.us-east-2.amazonaws.com/dev/scheme/request/add-extra-file/';
 //this is for Admin
 export const UploadFile ='https://cvim8f5wld.execute-api.us-east-2.amazonaws.com/dev/scheme/request/add-file/';
 export const download = 'https://cvim8f5wld.execute-api.us-east-2.amazonaws.com/dev/scheme/request/detail/';