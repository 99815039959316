/* eslint-disable prettier/prettier */
export default {
  
  get_Set(state,data) {
      for(var i=0;i<data.data.length;i++) {
        state.get_Set.push(data.data[i])
        if(state.get_Set[0].id == data.data[i].id) {
         state.get_Set = []; 
         state.get_Set.push(data.data[i])
       }
    }
    },
   
    Success(state,data) {
      state.success = data
    },
    get_Set_Dimensions(state,data) {
      state.get_Set_Dimensions = data.data;
    }
}