<template>
	<div>
		<Headers></Headers>
		<div style="    position: relative;">
          	<svg class="shape1"></svg>
		</div>
		<SectionOrder />
	 
		<!-- start section for Category title -->
		<div   class="row mt-4 ElMessiri-18 SchemeDetails" :class="$i18n.locale == 'ar' ? 'ar-style' : ''">
			<div v-if="`${$i18n.locale}` == 'en'" class="col-12 col-sm-6 ">
					<router-link
					:to="`/${$i18n.locale}/`"
				 
				>
				<img class="backhome" src="../../assets/Details/backL.svg" alt="" />
					</router-link>
				<span class="CategoryName"> {{ getScheme.categoryNameEn }} </span>
				<span class="CategoryName"> {{ getScheme.typeNameEn }} </span>
				<span class="CategoryName">{{ getScheme.nameEn }}</span>
			</div>
			<div v-if="`${$i18n.locale}` == 'ar'" class="col-12 col-sm-6 ">
						<router-link
					:to="`/${$i18n.locale}/`"
				 
				>
				<img class="backhome" src="../../assets/Details/backR.svg" alt="" />
					</router-link>
				<span class="CategoryName"> {{ getScheme.categoryNameAr }} </span>
				<span class="CategoryName"> {{ getScheme.typeNameAr }} </span>
				<span class="CategoryName">{{ getScheme.nameAr }}</span>
			</div>
		</div>
		<!-- end section for Category title -->
         	<Spinner v-if="loading" style="text-align: center"></Spinner>
		<div v-else class="row justify-content-center mt-2" id="gutter">
			
			<div class="col-12 col-sm-10 Details">
								<img
					v-if="$admins"
					@click="EditScheme(getScheme)"
					src="../../assets/Admin/Edit.svg"
					style="width: 20px; margin-left: 15px cursor: pointer;"
					alt="Edit"
				/>
				<img
					v-if="$admins"
					@click="deletScheme(getScheme.id)"
					src="../../assets/Admin/Delete.svg"
					style="width: 20px;     margin: 10px; cursor: pointer; "
					alt="Delete"
					 
				/>
				<div class="row" id="gutter">
			 
					<div class="col-12 col-sm-12 col-md-12 col-lg-6">
					 <Discribed :getDimensions="getDimensions" :getFiles="getFiles" :getDescription="getDescription" :getid="getid"  />
					</div>
					<div class="col-12 col-sm-12 col-md-12 col-lg-6">
						<img class="path" :src="getScheme.imageUrl" alt="error" />
					</div>
				</div>
			</div>
			
		<ConfirmDelete v-if="ConfirmDelete" @ConfirmClose="ConfirmClose" @ConfirmYes="ConfirmYes" ></ConfirmDelete>
            	
				<!-- this is for Edit Scheme -->
			<SchemeControl v-if="EditSchem" :EditSchem="EditSchem"  :DataEditeScheme="DataEditeScheme"    @Close="Close" />	
	 
		</div>
	</div>
</template>

<script>
import Headers from '../Header/Header.vue';
import SectionOrder from '../SectionOrder.vue';
import Discribed from './Discribed.vue';
import { mapState } from 'vuex';
import ConfirmDelete from '../Admin/ConfirmDelete.vue';
import SchemeControl from '../Admin/SchemeControl.vue';
import Spinner from '../Spinner.vue';
export default {
		data () {
			return {
          DataEditeScheme : {},
          EditSchem:false,
          IdDelete:null,
          ConfirmDelete:false,
		  loading: true
			}
		},
	methods: {
	 
		downloadPdf(va) {
		 
		 
			this.$store.dispatch('SchemeDetails/getDownload', va);
		},
			Close() {
         this.EditSchem = false;
		},
				EditScheme(va) {
	   
	        this.DataEditeScheme = va;
			this.EditSchem = true;
		/*	this.SchemeEdits.id = va.id;
			this.SchemeEdits.nameAr = va.nameAr;
			this.SchemeEdits.nameEn = va.nameEn;
			this.SchemeEdits.groundSpace = va.groundSpace;
			this.SchemeEdits.buildingSpace = va.buildingSpace;
			this.SchemeEdits.buildingSpace = va.buildingSpace;
			this.SchemeEdits.typeId = va.typeId;
			this.SchemeEdits.categoryId = va.categoryId;*/
		},
		deletScheme(va) {
           this.IdDelete = va;
		   this.ConfirmDelete = true
			//this.$store.dispatch('Scheme/DeleteScheme', va);
		},
				ConfirmClose() {
         this.ConfirmDelete =false;
		},
		ConfirmYes() {
         	this.$store.dispatch('Scheme/DeleteScheme',  this.IdDelete);
		    this.ConfirmDelete =false;
		},
	},
	components: {
    Headers,
    SectionOrder,
    Discribed,
    ConfirmDelete,
    SchemeControl,
    Spinner
},

	mounted() {
		this.$store.dispatch('SchemeDetails/getSchemeByID', this.$route.params.id).then ( res=>{
                res ==true? this.loading=false:''
		})
	},
	computed: {
		...mapState({
			getScheme: (state) => state.SchemeDetails.getDetails,
			getDimensions : (state) => state.SchemeDetails.getDetails.dimensions,
			getFiles : (state) => state.SchemeDetails.getDetails.files,
			getDescription : (state) => state.SchemeDetails.getDetails.description,
			getid :  (state) => state.SchemeDetails.getDetails.id,
		}),
	},
};
</script>

<style lang="scss"   >
.ar-style {
	direction: rtl;
	.backhome {
		margin-right: 30px;
		margin-left: 10px;
	}
}
.shape1 {
	clip-path: polygon(0 0, 100% 0, 100% 35%, 0 35%);
	background-color: $third;
	width: 100%;
	height: 475px;
	z-index: -2;
	position: absolute;
	/* left: 0; */
	right: 0;
}
.SchemeDetails {
	background-color: $primary;
	color: $t_Fifth;
	height: 44px;
	padding-left: 24px;
	align-items: center;
}
.backhome {
	height: 15px;
	margin-right: 10px;
}
.CategoryName {
	margin: 15px;
}
.CategoryName:last-child {
	color: $t-third;
}
 #gutter {
    --bs-gutter-x: 0;
    --bs-gutter-y: 0;
 }
.path {
	    width: 100%;
    height: auto;
 
    /* margin-left: 0.5rem; */
    /* margin-top: 0px; */
    border-radius: 20px;
}
.Details {
	margin-top: 60px;
	margin-bottom: 113px;
	background-color: $Fifth;
	box-shadow: 1px 1px 15px 1px rgb(0 0 0 / 40%);
	border-radius: 20px;
}
 
@media only screen and (max-width: 700px) {
}
/**
	<div :class="$i18n.locale == 'ar' ? 'ar-style' : ''" class="row justify-content-between">
			<div class="col-xl-4 col-lg-4 col-sm-12 col-12 beforDownload"> {{ $t('SchemeDetails.buying') }}</div>
			<div class="col-xl-4 col-lg-4 col-sm-12 col-12">
				<img class="SvgStyle"   src="../../assets/icon/share.svg" alt="Love" />
				<img class="SvgStyle" @click="downloadPdf(getScheme.autoCadId)" src="../../assets/icon/download.svg" alt="Love" />
				<span class="download">AutoCAD</span>
				<img class="SvgStyle" @click="downloadPdf(getScheme.pdfId)" src="../../assets/icon/download.svg" alt="Love" />
                 <span  class="download1">PDF</span>
				<img class="SvgStyle" src="../../assets/icon/love.svg" alt="Love" />
			</div>
		</div>
		<div class="line"></div>
		.line {
	background-color: $primary;
	height: 5px;
}
.beforDownload {
	    margin-top: 5px;
  width: 480px;
    padding-left: 30px;
	color: $t_Fifth ;
	font-weight: bold;
	font-size: 22px;
}
 .download {
	 position: absolute;
	   margin-top: 60px;
	    margin-left: -56px;
		margin-right: 0px;
 }
 .download1 {
	 position: absolute;
	   margin-top: 60px;
	 margin-left: -35px;
	 margin-right: 0px;
 }
 .SvgStyle {
	width: 45px;

	margin-top: 15px;
	margin-left: 45px;
	margin-bottom: 25px;
	cursor: pointer;
}
*/
</style>
