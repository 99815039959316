/* eslint-disable prettier/prettier */
export default {
  get_Scheme(state,va){
     for(var i=0;i<va.data.length;i++) {
       state.Scheme_list.push(va.data[i])
       if(state.Scheme_list[0].id == va.data[i].id) {
        state.Scheme_list = []; 
        state.Scheme_list.push(va.data[i])
      }
   }
  },
  get_total(state,data) {
    state.total = data
    state.Scheme_list = []
  },
  get_scheme_Catg(state, va) {
    for(var i=0;i<va.data.length;i++) {
      state.Scheme_list.push(va.data[i])
      if(state.Scheme_list[0].id == va.data[i].id) {
       state.Scheme_list = []; 
       state.Scheme_list.push(va.data[i])
     }
  }
  },
  get_Scheme_Search (state,va) {
    for(var i=0;i<va.data.length;i++) {
      state.Scheme_list.push(va.data[i])
      if(state.Scheme_list[0].id == va.data[i].id) {
       state.Scheme_list = []; 
       state.Scheme_list.push(va.data[i])
     }
    }
  },
  get_Scheme_max(state,va) {
    for(var i=0;i<va.data.length;i++) {
      state.Scheme_list.push(va.data[i])
      if(state.Scheme_list[0].id == va.data[i].id) {
       state.Scheme_list = []; 
       state.Scheme_list.push(va.data[i])
     }
    }
  },
  get_SpaceCatg(state,va) {
    for(var i=0;i<va.data.length;i++) {
      state.Scheme_list.push(va.data[i])
      if(state.Scheme_list[0].id == va.data[i].id) {
       state.Scheme_list = []; 
       state.Scheme_list.push(va.data[i])
     }
    }
  },
   
  Search(state, data) {
    state.Scheme_list = data
  },
  Set_Favorites(state,data) {
   
     const  itemIndex = state.Scheme_list.findIndex(x => x.id == data)
     state.Scheme_list[itemIndex].isFavorite = 1 
   //  state.Favorites = data;
  },
  Remove_Favorites(state,data) {
 
    const itemIndex = state.Scheme_list.findIndex(x=> x.id== data)
    state.Scheme_list[itemIndex].isFavorite = null
  },
 
  New_Scheme(state,data) {
    state.NewScheme=data
  },
  SendFromModify(state,data) {
    state.SendFromModify = data
  }

}
/**
 *    // state.Scheme_list.push(data)
    //state.Scheme_list =   data.data,
   //   Vue.set(state.Scheme_list,data.data),
   // state.Scheme_list  = va.data
   //state.Scheme_list = {...state.Scheme_list,va}
  //   state.Scheme_list.push(va.data)
   //  Vue.set(state.Scheme_list, 'data', va.data)
 * 
 */