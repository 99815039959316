/* eslint-disable prettier/prettier */
export default {
  
  get_Option(state,data) {
      for(var i=0;i<data.data.length;i++) {
        state.get_Option.push(data.data[i])
        if(state.get_Option[0].id == data.data[i].id) {
         state.get_Option = []; 
         state.get_Option.push(data.data[i])
       }
    }

    data.data.forEach(element => {
      if(element.nameAr == state.sortAr[0] || element.nameEn == state.sortEn[0] ) {
        state.sortAr =[],
        state.sortEn = []
      }
      state.sortAr.push ( element.nameAr)
     state.sortEn.push(element.nameEn) 
    }); 

    },
   
    Success(state,data) {
      state.success = data
    },
    get_Category_Dimensions(state,data) {
      state.get_Category_Dimensions = data;
    }
}