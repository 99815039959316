<template>
	<div class="row justify-content-center" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
		<div class="col-md-6 col-lg-8 col-sm-10 col-12 CatControl">
			<div class="row justify-content-between">
				<div class="col-4">
					<div class="ElMessiri-25 font-bold">{{ $t('Control.Subscrip.Subscrip') }}</div>
				</div>
				<div class="col-1">
					<img
						src="../../assets/Admin/close.svg"
						alt="close"
						@click="close"
						style="width: 22px; margin: 6px 25px"
					/>
				</div>
			</div>
			<div class="line"></div>
			<div class="row justify-content-around ElMessiri-20">
				<div class="col-md-6 col-lg-6 col-sm-10 col-12">
					<div>{{ $t('Control.NameAr') }}</div>
					<input
						class="col-lg-12 col-sm-10 col-12"
						:class="{ 'is-invalid': validationStatus($v.PlanInput.nameAr) }"
						type="text"
						v-model="PlanInput.nameAr"
						:placeholder="$t('Control.NameAr')"
					 
					/>
					<div v-if="SendError">
						<div v-if="!$v.PlanInput.nameAr.required" class="invalid-feedback block">
							{{ $t('LogIn.required') }}
						</div>
					</div>

					<div>{{ $t('Control.NameEn') }}</div>
					<input
						class="col-lg-12 col-sm-10 col-12"
						:class="{ 'is-invalid': validationStatus($v.PlanInput.nameEn) }"
						:dir="PlanInput.nameEn == '' ? '' : 'ltr'"
						type="text"
						v-model="PlanInput.nameEn"
						:placeholder="$t('Control.NameEn')"
					 
					/>
					<div v-if="SendError">
						<div v-if="!$v.PlanInput.nameEn.required" class="invalid-feedback block">
							{{ $t('LogIn.required') }}
						</div>
					</div>
					<div>{{ $t('Control.Subscrip.PriceBefor') }}</div>
					<input
						class="col-lg-12 col-sm-10 col-12"
						:class="{ 'is-invalid': validationStatus($v.PlanInput.PriceBefor) }"
						type="text"
						v-model="PlanInput.PriceBefor"
						:placeholder="$t('Control.Subscrip.PriceBefor')"
						@keyup="acceptNumber"
					/>
					<div v-if="SendError">
						<div v-if="!$v.PlanInput.PriceBefor.required" class="invalid-feedback block">
							{{ $t('LogIn.required') }}
						</div>
					</div>
					<div>{{ $t('Control.Subscrip.PriceNow') }}</div>
					<input
						class="col-lg-12 col-sm-10 col-12"
						:class="{ 'is-invalid': validationStatus($v.PlanInput.PriceNow) }"
						type="text"
						v-model="PlanInput.PriceNow"
						:placeholder="$t('Control.Subscrip.PriceNow')"
						@keyup="acceptNumber"
					/>
					<div v-if="SendError">
						<div v-if="!$v.PlanInput.PriceNow.required" class="invalid-feedback block">
							{{ $t('LogIn.required') }}
						</div>
					</div>

					<div>{{ $t('Control.Subscrip.timeDate') }}</div>
					<input
						class="col-lg-12 col-sm-10 col-12"
						:class="{ 'is-invalid': validationStatus($v.PlanInput.timeDate) }"
						type="text"
						v-model="PlanInput.timeDate"
						:placeholder="$t('Control.Subscrip.timeDate')"
						@keyup="acceptNumber"
					/>
					<div v-if="SendError">
						<div v-if="!$v.PlanInput.timeDate.required" class="invalid-feedback block">
							{{ $t('LogIn.required') }}
						</div>
					</div>
					<div style="display: inline">{{ $t('Control.Subscrip.OneTime') }}</div>
					<input
						@change="test"
						type="checkbox"
						style="margin: 0 15px"
						name="OneTime"
						:value="OneTime"
						v-model="OneTime"
					/>
					<button v-if="!loading" class="col-lg-12 col-sm-10 col-12 save" @click="Save">
						{{ $t('Control.Save') }}
					</button>
					<div v-if="loading" class="row justify-content-center">
						<div class="col-lg-3 col-3">
							<Spinner />
						</div>
					</div>
				</div>
				<div class="col-md-6 col-lg-4 col-sm-10 col-12">
					<div>{{ $t('Control.Subscrip.powers') }}</div>
					<div class="line"></div>
					<div v-for="getPermission in getPermissions" :key="getPermission.id">
						<div class="Category">
							<span v-if="`${$i18n.locale}` == 'en'"> {{ getPermission.nameEn }} </span>
							<span v-if="`${$i18n.locale}` == 'ar'"> {{ getPermission.nameAr }} </span>
							<input
								:id="getPermission.id"
								type="checkbox"
								:name="getPermission.id"
								:value="getPermission.id"
								@change="handleChange"
								ref="checkId"
							/>
						</div>
					</div>
				</div>
			</div>

			<div v-if="Eroor" class="alert alert-danger col-sm-12 col-12 ElMessiri-20" role="alert">
				{{ $t('Control.Eroor') }}
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';

import { required } from 'vuelidate/lib/validators';
import Spinner from '../Spinner.vue';
export default {
	props: ['OpenPlanEdit', 'dataEdit'],
	components: { Spinner },
	data() {
		return {
			PlanInput: {
				id: null,
				nameAr: '',
				nameEn: '',
				PriceBefor: '',
				PriceNow: '',
				timeDate: '',
			},
			Select: [],
			OneTime: false,
			loading: false,
			SendError: false,
			Eroor: false,
			EditTosend: false,
		};
	},
	validations: {
		PlanInput: {
			nameAr: { required },
			nameEn: { required },
			PriceBefor: { required },
			PriceNow: { required },
			timeDate: { required },
		},
	},
	methods: {
 
		test() {
		//	console.log(this.OneTime);
		},
		validationStatus: function (validation) {
			return typeof validation != 'undefined' ? validation.$error : false;
		},
		acceptNumber() {
			///[^0-9,.]+/g, ""
			var x = this.PlanInput.PriceBefor.replace(/\D/g, '');
			var y = this.PlanInput.PriceNow.replace(/\D/g, '');
			var z = this.PlanInput.timeDate.replace(/\D/g, '');
			this.PlanInput.PriceBefor = x;
			this.PlanInput.PriceNow = y;
			this.PlanInput.timeDate = z;
		},
		handleChange(e) {
			const { value, checked } = e.target;
			if (checked) {
				this.Select.push(value);
			} else {
				const index = this.Select.findIndex((id) => id === value);
				if (index > -1) {
					this.Select.splice(index, 1);
				}
			}
		},
		close() {
			this.$emit('Close');
		},
		Edit(Category) {
			this.CategoryInput.nameAr = Category.nameAr;
			this.CategoryInput.nameEn = Category.nameEn;
			this.CategoryInput.id = Category.id;
			this.EditTosend = true;
		 
			//this.$store.dispatch('Category/patchCategory', va);
		},
		Save() {
			this.SendError = true;
			this.$v.$touch();
			if (this.$v.$pendding || this.$v.$error) return;
			this.loading = true;
			if (this.OpenPlanEdit) {
				this.$store
					.dispatch('Plan/PatchPlan', {
						PlanInput: this.PlanInput,
						OneTime: this.OneTime,
					})
					.then((res) => {
						if (res) {
							this.PlanInput = {
								id: null,
								nameAr: '',
								nameEn: '',
								PriceBefor: null,
								PriceNow: null,
								timeDate: null,
							};

							this.$emit('Close');
						} else {
							this.Eroor = true;
						}
						this.SendError = false;
						this.loading = false;
						this.Select = [];
						this.OneTime = false;
					});
			} else {
				this.$store
					.dispatch('Plan/PostPlan', {
						PlanInput: this.PlanInput,
						Select: this.Select,
						OneTime: this.OneTime,
					})
					.then((res) => {
						res == true
							? (this.PlanInput = {
									id: null,
									nameAr: '',
									nameEn: '',
									PriceBefor: null,
									PriceNow: null,
									timeDate: null,
							  })
							: (this.Eroor = true);

						this.SendError = false;
						this.loading = false;
						this.Select = [];
						this.OneTime = false;
						let inputs;
						this.getPermissions.forEach((element) => {
							inputs = document.getElementById(element.id);
							inputs.checked = false;
						});
					});
			}
		},
	},
	computed: {
		...mapState({
			getPermissions: (state) => state.Plan.getPermission,
		}),
	},
	mounted() {
		if (this.dataEdit) {
	 
			this.PlanInput.id = this.dataEdit.id;
			this.PlanInput.nameAr = this.dataEdit.nameAr;
			this.PlanInput.nameEn = this.dataEdit.nameEn;
			this.PlanInput.PriceBefor = this.dataEdit.priceBefore;
			this.PlanInput.PriceNow = this.dataEdit.price;
			this.PlanInput.timeDate = this.dataEdit.durationInMonth;

			this.dataEdit.isOneTime == 1 ? (this.OneTime = true) : (this.OneTime = false);

			//    isOneTime
		} else {
			this.$store.dispatch('Plan/getPermission');
		}
	},
};
</script>
<style></style>
