<template>
	<div class="sectio1_0">
	 
			<svg class="shape"></svg>
	  <SectionOrder/>
	</div>
</template>

<script>
import SectionOrder from './SectionOrder.vue';
export default {
components : {
	SectionOrder
}

};
</script>

<style lang="scss" scoped>
.sectio1_0 {
	position: relative;
	width: 100%;
}
.shape {
	clip-path: polygon(0 0, 100% 0, 92% 83%, 6% 40%);
	background-color: $third;
	width: 100%;
	height: 300px;
	z-index: -2;
	position: absolute;
	/* left: 0; */
	right: 0;
}
 
</style>
