/* eslint-disable prettier/prettier */
export const getScheme = 'https://cvim8f5wld.execute-api.us-east-2.amazonaws.com/dev/scheme/public/?limit=10&offset=';
export const getSchemeDefault = 'https://cvim8f5wld.execute-api.us-east-2.amazonaws.com/dev/scheme/public/?limit=10&offset=';
export const getSchemeByCategory = 'https://cvim8f5wld.execute-api.us-east-2.amazonaws.com/dev/scheme/public/?limit=10&offset=';
export const maxmin = 'https://cvim8f5wld.execute-api.us-east-2.amazonaws.com/dev/scheme/public/?limit=10&offset=';
export const Space_Category = 'https://cvim8f5wld.execute-api.us-east-2.amazonaws.com/dev/scheme/public/?limit=10&offset=';
export const DeletScheme = 'https://cvim8f5wld.execute-api.us-east-2.amazonaws.com/dev/scheme/';
export const EditSchem = 'https://cvim8f5wld.execute-api.us-east-2.amazonaws.com/dev/scheme/';
export const PostScheme = 'https://cvim8f5wld.execute-api.us-east-2.amazonaws.com/dev/scheme/';
export const getSearch = 'https://cvim8f5wld.execute-api.us-east-2.amazonaws.com/dev/scheme/public/?limit=10&offset=';
export const PostFavorites = 'https://cvim8f5wld.execute-api.us-east-2.amazonaws.com/dev/user/favorites?schemeId=';
export const RemoveFavorites = 'https://cvim8f5wld.execute-api.us-east-2.amazonaws.com/dev/user/favorites?schemeId=';
export const PostFile = 'https://cvim8f5wld.execute-api.us-east-2.amazonaws.com/dev/scheme/';
