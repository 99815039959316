<template>
  <div v-if="isVisible" data-aos="fade-right"
     data-aos-offset="400"
     data-aos-easing="ease-in-sine"
      class="counter row  font-bold ElMessiri-15 ">
      <div class="col align-self-center">
            <span> 
                	  <animated-number
    :value="offsetCounter+1-1"
    :formatValue="formatToPrice"
    :duration="1500"
  /> 
                 </span>
       <div class="line"></div>
       <span>        
  {{total}}
  </span>
      </div>
 
  </div>
</template>

<script>
import {mapState} from 'vuex'
import AnimatedNumber from "animated-number-vue";
import $ from 'jquery';
export default {
    props : ['offset','offsetCounter'],
    data() {
        return {
           value: 1000,
           isVisible: false
        }
    },
    components : {
     AnimatedNumber
    },
 computed : {
     ...mapState ({
         total : (state) => state.Scheme.total
     })
 },
 methods : {
     	  formatToPrice(value) {
      return `${value.toFixed()}`;
    },
     Visible: function () {
			$(document).bind(
				'scroll',
				function () {
					var sendVisible = $('.counter');
					if ($(document).scrollTop() > 400) {
						sendVisible.addClass('isVisible');
						this.isVisible = true;
					} else {
						sendVisible.removeClass('isVisible');
						this.isVisible = false;
					}
				}.bind(this)
			);
		},
 },	mounted: function () {
		this.$nextTick(function () {
			this.Visible();
		});
	},
 
}
</script>

<style lang='scss' scoped>
.counter {
    background-color: $primary ;
    color: $t-third ;
     left: 30px;
    top: 85%;
    width: 90px;
    height: 90px;
    text-align: center;
    border-radius: 45px;
    position: fixed;
    z-index: 9;
    letter-spacing: 2px;
     .line  {
         background-color: $Fifth;
          height: 2px;
     }
}
 
</style>