/* eslint-disable prettier/prettier */
export default {
  
  get_type(state,data) {
      
      for(var i=0;i<data.data.length;i++) {
        state.get_type.push(data.data[i])
        if(state.get_type[0].id == data.data[i].id) {
         state.get_type = []; 
         state.get_type.push(data.data[i])
       }
    }

    },
   
    Success(state,data) {
      state.success = data
    },
   
}