<template>
	<div class="row justify-content-center" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
		<div class="col-md-6 col-lg-8 col-sm-10 col-12 CatControl">
			<div class="row justify-content-between">
				<div class="col-4">
					<div class="ElMessiri-25 font-bold">{{ $t('Control.Types.Types') }}</div>
				</div>
				<div class="col-1">
					<img
						src="../../assets/Admin/close.svg"
						alt="close"
						@click="close"
						style="width: 22px; margin: 6px 25px"
					/>
				</div>
			</div>

			<div class="line"></div>
			<div class="row justify-content-around ElMessiri-20">
				<div class="col-md-6 col-lg-6 col-sm-10 col-12">
					<div>{{ $t('Control.Types.AddNew') }}</div>
					<div class="line"></div>
					<div>{{ $t('Control.NameAr') }}</div>
					<input
						class="col-lg-12 col-sm-10 col-12"
						:class="{ 'is-invalid': validationStatus($v.TypeInput.nameAr) }"
						type="text"
						v-model.trim="$v.TypeInput.nameAr.$model"
						:placeholder="$t('Control.NameAr')"
					/>
				 <div v-if="SendError">
					  	<div v-if="!$v.TypeInput.nameAr.required" class="invalid-feedback block">
							{{ $t('LogIn.required') }}
						</div>
				 </div>
					 
				 

					<div>{{ $t('Control.NameEn') }}</div>
					<input
						class="col-lg-12 col-sm-10 col-12"
						:class="{ 'is-invalid': validationStatus($v.TypeInput.nameEn) }"
						:dir="TypeInput.nameEn == '' ? '' : 'ltr'"
						type="text"
						v-model.trim="$v.TypeInput.nameEn.$model"
						:placeholder="$t('Control.NameEn')"
					/>
				  <div v-if="SendError">
						<div v-if="!$v.TypeInput.nameEn.required" class="invalid-feedback block">
							{{ $t('LogIn.required') }}
						</div>
					</div>

					<button v-if="!loading" class="col-lg-12 col-sm-10 col-12 save" @click="Save">{{ $t('Control.Save') }}</button>
				   <div v-if="loading" class="row justify-content-center">
                          <div class="col-lg-3 col-3">
                            <Spinner/>
						  </div>
				   </div>
					 
				</div>
				<div class="col-md-6 col-lg-4 col-sm-10 col-12">
					<div v-for="type in types" :key="type.id">
						<div class="Category">
							<span v-if="`${$i18n.locale}` == 'en'"> {{ type.nameEn }} </span>
							<span v-if="`${$i18n.locale}` == 'ar'"> {{ type.nameAr }} </span>

							<span>
								<img
									class="deleteEdite"
									@click="delet(type.id)"
									src="../../assets/Admin/Delete.svg"
									alt="Delete"
										style="cursor: pointer;"
								/>
								<img class="deleteEdite" @click="Edit(type)" src="../../assets/Admin/Edit.svg" alt="Eidt" 	style="cursor: pointer;" />
							</span>
						</div>
					</div>
				</div>
			</div>
			<div v-if="SuccessAdd" class="alert alert-success col-sm-12 col-12  ElMessiri-20" role="alert">
				{{ $t('Control.Success') }}
			</div>
			 <div v-if="Eroor" class="alert alert-danger col-sm-12 col-12 ElMessiri-20" role="alert">
                	{{ $t('Control.Eroor') }}
           </div>  
		</div>
		  
		<Confirm v-if="ConfirmDelete" @ConfirmClose="ConfirmClose" @ConfirmYes="ConfirmYes" />
	</div>
</template>

<script>
import { mapState } from 'vuex';
import Confirm from './ConfirmDelete.vue';
import { required } from 'vuelidate/lib/validators';
import Spinner from "../Spinner.vue"
export default {
	data() {
		return {
		 
			TypeInput: {
				id: null,
				nameAr: '',
				nameEn: '',
			},
			loading : false,
			SuccessAdd : false,
			Eroor : false,
			IdDelete: null,
			EditTosend: false,
			ConfirmDelete: false,
				SendError: false,
		};
	},
	validations: {
		TypeInput: {
			nameAr: { required },
			nameEn: { required },
		},
	},
	components: {
		Confirm,
		Spinner
	},
	methods: {
		validationStatus: function (validation) {
			return typeof validation != 'undefined' ? validation.$error : false;
		},
		close() {
			this.$emit('Close');
		},
		delet(va) {
			this.ConfirmDelete = true;
			this.IdDelete = va;
			//	this.$store.dispatch('Type/deleteType', va);
		},
		ConfirmClose() {
			this.ConfirmDelete = false;
		},
		ConfirmYes() {
			this.loading = true;
			this.$store.dispatch('Type/deleteType', this.IdDelete).then(res => {
				     res == true? this.TypeInput = {
						id : null,
						nameAr : '',
						nameEn : ''
					}   : this.Eroor = true
						 this.SendError = false;
						this.loading = false;
			});
			this.ConfirmDelete = false;
		},
		Edit(type) {
			this.TypeInput.nameAr = type.nameAr;
			this.TypeInput.nameEn = type.nameEn;
			this.TypeInput.id = type.id;
			this.EditTosend = true;
		 
		},
		Save() {
		 this.SendError = true;
			this.$v.$touch();
			if (this.$v.$pendding || this.$v.$error) return;
			this.loading = true;
			if (this.EditTosend) {
				this.$store.dispatch('Type/patchType', this.TypeInput).then( res1 => {
					  res1 == true? this.TypeInput = {
						id : null,
						nameAr : '',
						nameEn : ''
					}   : this.Eroor = true
					
					
						 this.SendError = false;
						this.loading = false;
				});
				this.EditTosend=false
			} else {
				this.$store.dispatch('Type/PostType', this.TypeInput).then( res => {
                    res == true? this.TypeInput = {
						id : null,
						nameAr : '',
						nameEn : ''
					}   : this.Eroor = true
						this.$store.dispatch('Type/getType');
						 this.SendError = false;
						this.loading = false;
				});
			}
		},
	},
	computed: {
		...mapState({
			types: (state) => state.Type.get_type,

			Success: (state) => state.Type.success,
		}),
	},
	mounted() {
		this.$store.dispatch('Type/getType');
	},
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
.block {
	display: block;
}
.CatControl {
	background-color: #ffffff;
	z-index: 11;
	position: absolute;
	top: 25%;
	border-radius: 20px;
	padding: 25px 15px;
}
.CatControl .line {
	background-color: $forth;
	height: 1px;
	margin: 0px 0px 20px 0px;
}
.CatControl input {
	border-radius: 5px;
	border: solid 1px #757575;
	margin: 7px 0px;
}

.Category {
	margin: 10px 0px;
	padding: 4px 5px;
	border-radius: 5px;
	display: flex;
	justify-content: space-between;
	background-color: $secondary;
}
.deleteEdite {
	width: 20px;
	margin: 0px 6px;
	height: 20px;
}
.save {
	background-color: $third;
	color: $t_forth;
	border-radius: 5px;
	border: none;
	margin: 25px 0px;
}
.alert-success {
	text-align: center;
}
.alert-danger {
	text-align: center;
}
</style>
