/* eslint-disable prettier/prettier */
export default {
  
  getStatistics(state,data) {
     state.getStatistics = data
    },
  
    getMyProfile(state,data) {
      state.getMyProfile = data
     },
}