<template>
	<div>
		<Headers />
		<SectionModify :first1_1="$t('Modify.modification')" />
		<Spinner v-if="loading" style="text-align: center"></Spinner>
		<div v-else class="row justify-content-center mt-5">
			<div class="col-10 col-sm-10 Modify">
				<div class="row justify-content-between" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
					<div class="col-12 col-sm-12 col-lg-6">
						<div class="row justify-content-center">
							<div class="col-11">
								<div class="mt-5 mb-2 ElMessiri-20 center">{{ $t('Modify.OrderDetails') }}</div>
								<div
									done
									class="DetailsUser ElMessiri-18"
									:class="
										RequestByid.status == 'pending files'
											? 'BackgroundSpecific2'
											: RequestByid.status == 'pending payment'
											? 'BackgroundSpecific3'
											: RequestByid.status == 'done'
											? 'BackgroundSpecific1'
											: 'BackgroundSpecific'
									"
								>
									<div>
										{{ $t('Modify.OrderStatus') }} :
										<span
											:class="
												RequestByid.status == 'pending files'
													? 'colorSpecific2'
													: RequestByid.status == 'pending payment'
													? 'colorSpecific3'
													: RequestByid.status == 'done'
													? 'colorSpecific1'
													: 'colorSpecific'
											"
										>
											{{ StateRequest(RequestByid.status) }}</span
										>
									</div>
									<div>{{ $t('Modify.SenderName') }}: {{ RequestByid.name }}</div>
									<div>{{ $t('Modify.Email') }}: {{ RequestByid.email }}</div>
									<div>{{ $t('Modify.Number') }}: {{ RequestByid.phone }}</div>
									<div>{{ $t('Modify.date') }}: {{ format_date(RequestByid.createdAt) }}</div>
									<div>
										{{ $t('Modify.ExpectedDate') }}: {{ format_date(RequestByid.deliveryDate) }}
									</div>

									<div>{{ $t('Modify.Price') }}:{{ RequestByid.price }}</div>
									<span
										v-if="$admins"
										@click="OpenClickToSelect"
										:class="$i18n.locale == 'ar' ? 'ar-lang' : ''"
										class="Select"
										>{{ $t('Modify.ClickToSelect') }}</span
									>
									<span
										v-if="RequestByid.status == 'pending payment' && PayElement"
										@click="Pay"
										:class="$i18n.locale == 'ar' ? 'ar-lang' : ''"
										class="Select pay"
										>{{ $t('Modify.ClickToPay') }}</span
									>

									<ClickToSelect
										v-if="ClickToSelect"
										@Close="Close"
										:class="$i18n.locale == 'ar' ? 'ar-lang' : ''"
									></ClickToSelect>
								</div>
								<div class="ElMessiri-18 font-bold">{{ $t('SchemeDetails.dimensions') }}</div>
								<div>
									<div
										class="dimensionDetails ElMessiri-16"
										v-for="dimensions in RequestByid.dimensions"
										:key="dimensions.id"
									>
										<span v-if="`${$i18n.locale}` == 'en'"> {{ dimensions.nameEn }} </span>
										<span v-if="`${$i18n.locale}` == 'ar'"> {{ dimensions.nameAr }} </span>
										<span class="centerPostion"> {{ dimensions.value }} </span>

										<span>
											{{ $t('SchemeDetails.SquareMeters') }}
										</span>
									</div>
								</div>
								<div v-if="RequestByid.extraInfo">
									<div class="ElMessiri-18 font-bold">{{ $t('Modify.Additional') }}:</div>
									<div class="ElMessiri-18">
										{{ RequestByid.extraInfo }}
									</div>
								</div>
								<div class="mt-2 ElMessiri-18 font-bold">{{ $t('Modify.RequiredScheme') }} :</div>
								<div>
									<div
										class="dimensionDetails ElMessiri-16"
										v-for="option in RequestByid.options"
										:key="option.id"
									>
										<span v-if="`${$i18n.locale}` == 'en'"> {{ option.nameEn }} </span>
										<span v-if="`${$i18n.locale}` == 'ar'"> {{ option.nameAr }} </span>
									</div>
								</div>
								<div v-if="get_FileExtra[0]">
									<div class="mt-4 ElMessiri-18 font-bold">{{ $t('Modify.AdditionalFiles') }} :</div>

									<div
										class="dimensionDetails ElMessiri-16"
										v-for="file in get_FileExtra"
										:key="file.id"
									>
										<span
											v-if="
												file.extension == 'jpg' ||
												file.extension == 'png' ||
												file.extension == 'jpeg' ||
												file.extension == 'gif'
											"
										>
											{{ $t('SchemeDetails.Image') }}</span
										>
										<span v-else-if="file.extension == 'pdf'">
											{{ $t('SchemeDetails.pdfFile') }}</span
										>
										<span v-else-if="file.extension == 'dwg'">
											{{ $t('SchemeDetails.AutoCAD') }}</span
										>
										<span v-else> {{ $t('SchemeDetails.File') }}</span>

										<span>
											<img
												@click="download(file.assetId)"
												src="../../assets/Details/download.svg"
												alt="download"
											/>
										</span>
										<span style="width: 45px"> </span>
									</div>
								</div>
								<div v-if="get_FileUpload[0]">
									<div class="mt-4 ElMessiri-18 font-bold">{{ $t('Modify.ReceivedFile') }} :</div>

									<div
										class="dimensionDetails ElMessiri-16"
										v-for="file in get_FileUpload"
										:key="file.id"
									>
										<span
											v-if="
												file.extension == 'jpg' ||
												file.extension == 'png' ||
												file.extension == 'jpeg' ||
												file.extension == 'gif'
											"
										>
											{{ $t('SchemeDetails.Image') }}</span
										>
										<span v-else-if="file.extension == 'pdf'">
											{{ $t('SchemeDetails.pdfFile') }}</span
										>
										<span v-else-if="file.extension == 'dwg'">
											{{ $t('SchemeDetails.AutoCAD') }}</span
										>
										<span v-else> {{ $t('SchemeDetails.File') }}</span>

										<span>
											<img
												@click="download(file.assetId)"
												src="../../assets/Details/download.svg"
												alt="download"
											/>
										</span>
										<span style="width: 45px"> </span>
									</div>
								</div>
							</div>
						</div>
						<!-- upload file for Admin -->
						<div v-if="$admins && RequestByid.status == 'pending files'">
							<div v-for="(input, index) in inputs" :key="index" class="ElMessiri-18">
								<button @click="test(index)" class="AddNewFile ClickToAdd mt-2">
									{{ $t('Control.AddFile.ClicktoAdd') }}
								</button>
								<span style="margin: 0px 10px 0px 0px">{{ input.File.name }}</span>
								<input
									type="file"
									:id="index"
									@change="previewFilesimg"
									@click="setindex(index)"
									style="display: none"
								/>
							</div>
							<button
								@click="Addinput"
								class="AddNewFile File"
								:class="$i18n.locale == 'ar' ? 'ar-style' : 'en-style'"
							>
								{{ $t('Control.AddFile.AddFile') }}
								<img src="../../assets/Admin/Add.svg" alt="Add" />
							</button>
							<div class="row justify-content-center">
								<button v-if="!loadingSend" class="col-6 mb-4 mt-4 Save ElMessiri-18" @click="svae">
									{{ $t('Modify.Save') }}
								</button>
								<Spinner v-else style="text-align: center"></Spinner>
								<div
									v-if="Error"
									class="alert alert-danger col-sm-12 col-12 ElMessiri-20"
									style="text-align: center"
									role="alert"
								>
									{{ $t('Control.Eroor') }}
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 col-sm-12 col-lg-6"><img class="imageScheme" :src="RequestByid.imageUrl" alt=" scheme" /></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Headers from '../Header/Header.vue';
import SectionModify from './SectionModify.vue';
import ClickToSelect from './ClickToSelect.vue';
import Spinner from '../Spinner.vue';
import { mapState } from 'vuex';
import moment from 'moment';
export default {
	data() {
		return {
			ClickToSelect: false,
			PayElement: true,
			loading: true,
			inputs: [],
			indexArray: null,
			loadingSend: false,
			Error: false,
		};
	},
	components: {
		Headers,
		SectionModify,
		ClickToSelect,
		Spinner,
	},
	mounted() {
		this.$store.dispatch('Request/getRequestByid', this.$route.params.idRequest).then((res) => {
			res == true ? (this.loading = false) : '';
		});
	},
	computed: {
		...mapState({
			RequestByid: (state) => state.Request.get_RequestByid,
			get_FileExtra: (state) => state.Request.get_FileExtra,
			get_FileUpload: (state) => state.Request.get_FileUpload,
		}),
	},
	methods: {
		download(assetId) {
		 
			this.$store.dispatch('Request/Download', { id: this.RequestByid.id, assetId: assetId });
		},
		svae() {
			this.loadingSend = true;
			this.$store.dispatch('Request/UploadFile', { inputs: this.inputs, id: this.RequestByid.id }).then((res) => {
				if (res == true) {
					this.loadingSend = false;
					this.loading = true;
					this.$store.dispatch('Request/getRequestByid', this.$route.params.idRequest).then((res) => {
						res == true ? (this.loading = false) : '';
					});
				} else {
					this.Error = true;
				}
			});
		},
		Addinput() {
			this.inputs.push({ File: {} });
		},
		previewFilesimg(event) {
		 
			this.inputs[this.indexArray].File = event.target.files[0];
 
		},
		setindex(index) {
			this.indexArray = index;
			 
		},
		test(va) {
			 
			document.getElementById(va).click();
			this.indexArray = va;
		},
		format_date(value) {
			if (value) {
				return moment(String(value)).format('YYYY/MM/DD');
			}
		},
		StateRequest(va) {
			switch (va) {
				case 'pending files':
					if (this.$i18n.locale == 'en') {
						return 'Wating Faile';
					} else return 'في انتظار الملفات ';
				case 'pending review':
					if (this.$i18n.locale == 'en') {
						return ' Wating Review ';
					} else return 'في انتظار  المراجعة ';
				case 'pending payment':
					if (this.$i18n.locale == 'en') {
						return ' pending payment';
					} else return ' في انتظار الدفع ';
				case 'done':
					if (this.$i18n.locale == 'en') {
						return ' Files have been delivered';
					} else return '  تم تسليم الملفات ';
			}
		},
		Close() {
			this.ClickToSelect = false;
		},
		OpenClickToSelect() {
			this.ClickToSelect = true;
		},
		Pay() {
		 
			this.PayElement = false;
			this.$store.dispatch('Request/Pay', this.RequestByid.id);
		},
	},
};
</script>

<style lang="scss">
.ar-lang {
	float: left !important;
}
.center {
	text-align: center;
}
.imageScheme {
	width: 100%;
	height: auto;
}
.DetailsUser {
	background-color: $requset1;
	padding: 8px;
	border-radius: 5px;
}
.BackgroundSpecific2 {
	background-color: $requset3;
}
.BackgroundSpecific3 {
	background-color: $requset2;
}
.colorSpecific2 {
	color: $requset3_3;
}
.colorSpecific3 {
	color: $requset2_2;
}
.colorSpecific {
	color: $requset1_1;
}
.BackgroundSpecific {
	background-color: $requset1;
}
.colorSpecific1 {
	color: $requset4_4;
}
.BackgroundSpecific1 {
	background-color: $requset4;
}
.AdditionalInput {
	width: 100%;
	height: 45px;
	border-radius: 5px;
	border: 1px solid $Seven;
}
.dimensionDetails {
	position: relative;
}
.centerPostion {
	position: absolute;
	left: 50%;
}
.Select {
	float: right;
	margin-top: -50px;
	text-decoration: underline;
	color: #ab0000;
}
.pay {
	margin-top: -25px !important;
}
@media only screen and (max-width: 500px) {
  .Select {
 
	margin-top: -35px;
 
}
  .pay {
	margin-top: -12px !important;
}
}

</style>
