<template>
	<div>
		<Header></Header>
		<Section1></Section1>
		<div class="mt-4 ElMessiri-18 SchemeDetails FavoritePart" :class="$i18n.locale == 'ar' ? 'ar-style' : ''">
			<span v-if="`${$i18n.locale}` == 'en'">
				<router-link :to="`/${$i18n.locale}/`">
					<img class="backhome" src="../../assets/Details/backL.svg" alt="" />
				</router-link>
			</span>

			<span v-if="`${$i18n.locale}` == 'ar'">
				<router-link :to="`/${$i18n.locale}/`">
					<img class="backhome" src="../../assets/Details/backR.svg" alt="" />
				</router-link>
			</span>
			<span class="Favorite">
				<span class="NameFavorite">{{ $t('Favorite') }}</span>
			</span>
		</div>
		<!-- Start Scheme favorite -->
		<div class="row" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
			<div
				class="col-sm-12 col-md-6 col-lg-3 prodect"
				v-for="(Scheme, index) in SchemeList"
				:key="index"
				data-aos="zoom-in-down"
				data-aos-duration="2000"
			>
				<img
					:class="typeof Scheme.isFavorite == 'number' ? 'loveActive' : 'LoveDisable'"
					class="love"
					id="Favorites"
					@click="AddFavorites(Scheme)"
					src="../../assets/icon/love.svg"
					alt=""
					data-aos="flip-left"
					data-aos-easing="ease-out-cubic"
					data-aos-duration="1500"
				/>
				<div>
					<img class="path" @click="Details(Scheme.id)" :src="Scheme.imageUrl" alt="error" />
					<!-- here from scroll -->
				</div>
			</div>
		</div>
		<div class="Observe" v-if="total" v-observe-visibility="HandelScrolled"></div>
	</div>
</template>

<script>
import Section1 from '../Section1.vue';
import Header from '../Header/Header.vue';
import { mapState } from 'vuex';
import { EventBus } from '../../main';
import router from '../../router';
export default {
	data() {
		return {
			offset: 0,
			idForRequest: null,
		 
			
		};
	},
	components: { Section1, Header },
	methods: {
		HandelScrolled(isVisible) {
			if (!isVisible) {
				return;
			}
			this.offset += 10;

			if (this.total <= this.offset) {
				return;
			}

			this.$store.dispatch('Scheme/getSchemeFavorit', this.offset);
		},
		Details(va) {
	 
		 
			//:to="`/${$i18n.locale}/DetailsScheme/${Scheme.id}`"

			if (this.SendFromModify) {
				//	 EventBus.$emit("SendToModify",va);
				this.$store.dispatch('Request/SentToModify',va)
				router.push({ name: 'RequestModify' });
			} else {
				router.push({name:'DetailsScheme',params: {id : va}})
			}
		},
		 
	},
	mounted() {
		this.$store.dispatch('Scheme/getSchemeFavorit', this.offset);
	},
	computed: {
		...mapState({
			SchemeList: (state) => state.Scheme.Scheme_list,
			total: (state) => state.Scheme.total,
			SendFromModify: (state) => state.Scheme.SendFromModify,

		}),
	},
   beforeDestroy() {
	   		 this.$store.dispatch('Scheme/SendFromModify',false);
   }
};
</script>

<style lang="scss">
.FavoritePart {
	display: flex;
}
.Favorite {
	width: 100%;
	text-align: center;
}
.NameFavorite {
	background-color: $Fifth;
	color: $t_forth;
	padding: 2px 15px;
	border-radius: 10px;
}
</style>
