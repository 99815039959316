<template>
	<div class="contact ElMessiri-16">
		<img class="Arrow" src="../../assets/icon/Arrow.svg" alt="" />
		<div>
			<ul :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
				<li  @click="openAdmin(1)">{{ $t('Control.Type') }}</li>
				<li @click="openAdmin(2)" >{{ $t('Control.Categories') }}</li>
	          <li @click="openAdmin(3)" >{{ $t('Control.Dimensions') }}</li>
				<li @click="openAdmin(4)" >{{ $t('Control.Schemes') }}</li>
	          <li  @click="openAdmin(5)"  >{{ $t('Control.Opttion') }}</li>
	          <li  @click="openAdmin(6)"  >{{ $t('Control.Set') }}</li>

				<li>{{ $t('Control.News') }}</li>

				<li  @click="openAdmin(7)">{{ $t('Control.Subscriptions') }}</li>

				<li> <router-link class="router" :to="`/${$i18n.locale}/Statistics/`" >    {{ $t('Control.Statistics') }} </router-link></li>
			 

			</ul>
		</div>
	</div>
</template>

<script>
export default {
 methods : {
   openAdmin(va) {
        this.$emit("openPage", va);
   }
 }

};
</script>

<style lang="scss" scoped>
.Arrow {
	position: absolute;
	left: 35px;
	margin-top: -20px;
	width: 15px;
}
.contact {
	padding-left: 10px;
	z-index: 11;
	position: absolute;
	margin-top: 25px;
	width: 175px; 
	margin-right: -65px;
	-webkit-backdrop-filter: brightness(150%) blur(100px);
	backdrop-filter: brightness(150%) blur(100px);
	background-color: rgba(255, 255, 255, 0.54);
	border-radius: 25px;
	padding-top: 5px;
}
.contact ul li {
	list-style: disc;
	padding: 5px 0px;
	cursor: pointer;

}
.contact ul li .router  {
	color : $t_forth ;
	text-decoration: none;
}
</style>
