<template>
	<div class="lan">
            <img class="Arrow" src="../../assets/icon/Arrow.svg" alt="">
		<li>
			<a class="dropdown-item" @click.prevent="setlocal('ar')">
				<img class="flag" src="../../assets/icon/Arabflag.svg" alt="Arab flag" />
				<span class="ElMessiri-18">العربية </span>
			</a>
		</li>

		<li>
			<a class="dropdown-item" @click.prevent="setlocal('en')">
				<img class="flag" src="../../assets/icon/Englishflag.svg" alt="English flag" />
				<span class="ElMessiri-18">English </span>
			</a>
		</li>
	</div>
</template>

<script>
export default {
methods : {
    	setlocal(locale) {
			if (this.$i18n.locale == locale) {
				return;
			}
			this.$i18n.locale = locale;
			localStorage.setItem('language', locale);
			this.$router.push({
				params: { lang: locale },
			});
			this.$emit('Close');
		},
}

};
</script>

<style lang="scss" scoped>
.lan {
	z-index: 11;
	    position: absolute;
    margin-top: 25px;
         width: 165px;
    margin-right: -100px;
        backdrop-filter: brightness(150%) blur(100px);
    background-color: rgba(255, 255, 255, 0.54);
	border-radius: 25px;
}
 .Arrow {
         position: absolute;
    left: 25px;
   
    margin-top: -14px;
    width: 15px;
 
 }
.flag {
	float: left;
	margin-right: 50px;
}
.dropdown-item:hover {
 
   color:  $t_forth;
   border-radius: 25px;
   
}
.dropdown-item:active {
	background-color:rgba(255, 255, 255, 0.54) ;
}
</style>
