<template>
	<div class="header">
		<div class="img_Back"></div>
		<nav class="navbar navbar-expand-lg navbar-light bg-light">
			<div class="container-fluid">
				<a class="" href="#">
					<img class="logo" src="../../assets/logo1.png" alt="" />
				</a>
				<button
					class="navbar-toggler"
					type="button"
					data-bs-toggle="collapse"
					data-bs-target="#navbarNavDropdown"
					aria-controls="navbarNavDropdown"
					aria-expanded="false"
					aria-label="Toggle navigation"
				>
					<span class="navbar-toggler-icon"></span>
				</button>
				<div
					:class="$i18n.locale == 'ar' ? 'en-style' : ''"
					class="collapse navbar-collapse"
					id="navbarNavDropdown"
				>
					<ul class="navbar-nav">
						<li class="nav-item dropdown mainHeader">
							<a
								class="nav-link nav-link1"
								href="#"
								id="navbarDropdownMenuLink"
								role="button"
								data-bs-toggle="dropdown"
								aria-expanded="false"
							>
								<img
									class="content"
									src="../../assets/home/personprofile.svg"
									style="margin-top: -18px"
									alt=""
								/>
							</a>
							<ul
								style="
							 

									width: 300px;
								"
								:style="$i18n.locale == 'ar' ? '	margin-left: -250px;' : 'margin-left: 0px;'"
								class="dropdown-menu ElMessiri-18"
								aria-labelledby="navbarDropdownMenuLink"
							>
								<li v-if="!checkLogIn" class="Acoount BeforServices">
									<router-link class="router Services" :to="`/${$i18n.locale}/sign_up`">
										{{ $t('LogIn.account') }}
									</router-link>
								</li>
								<li v-if="!checkLogIn" class="Acoount BeforServices">
									<router-link class="router Services" :to="`/${$i18n.locale}/Login`">
										{{ $t('LogIn.LogIn') }}
									</router-link>
								</li>
								<li v-if="checkLogIn" class="BeforServices" @click="check">
									<div class="Services">
									{{$t('LogIn.LogOut')}}
									</div>
								</li>
							</ul>
						</li>

						<li class="nav-item">
							<img
								class="content"
								src="../../assets/home/heartblack.svg"
								alt="Favorite"
								@click="OpenFavorite('Favorite')"
							/>
						</li>
						<li @click="Close()" class="nav-item mainHeader">
							<router-link class="navLink ElMessiri-18" :to="`/${$i18n.locale}/`">
								{{ $t('nav.Home') }}
							</router-link>
						</li>
						<li class="nav-item dropdown mainHeader">
							<a
								class="navLink dropdown-toggle ElMessiri-18"
								href="#"
								id="navbarDropdownMenuLink"
								role="button"
								data-bs-toggle="dropdown"
								aria-expanded="false"
							>
								{{ $t('nav.Services') }}
							</a>
							<ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
								<li class="BeforServices">
									<div @click="OpenOrder('AddOrder')" class="Services col_4 col-lg-12 ElMessiri-20">
										{{ $t('section1.design') }}
									</div>
								</li>
								<li class="BeforServices">
									<div
										@click="OpenOrder('RequestModify')"
										class="Services col_4 col-lg-12 ElMessiri-20"
									>
										{{ $t('section1.modification') }}
									</div>
								</li>
							</ul>
						</li>
						<li class="nav-item dropdown mainHeader">
							<router-link
								class="nav-link ElMessiri-18 colo font-bold"
								:to="`/${$i18n.locale}/Subscribe`"
							>
								{{ $t('Subscribe.Subscribe') }}
							</router-link>
						</li>
						<li class="nav-item dropdown mainHeader">
							<div class="navLink ElMessiri-18" @click="Open(1)">
								{{ $t('nav.AboutUs') }}
							</div>
							<AboutUs v-if="nav.About" />
						</li>
						<li class="nav-item dropdown mainHeader">
							<div class="navLink ElMessiri-18" @click="Open(2)">
								{{ $t('nav.Contact') }}
							</div>

							<ContactUs v-if="nav.Contact" />
						</li>
						<li class="nav-item dropdown mainHeader">
							<div class="navLink ElMessiri-18" @click="Open(3)">
								{{ $t('nav.Language') }}
							</div>
							<Language v-if="nav.lan" @Close="Close" />
						</li>
						<li class="nav-item dropdown mainHeader" v-if="$admins">
							<div class="navLink ElMessiri-18" @click="Open(4)">
								{{ $t('nav.Control') }}
							</div>
							<Control v-if="nav.Control" @openPage="openPage" />
						</li>
					</ul>
				</div>
			</div>
		</nav>
		<CategoryControl v-if="Admin.Category" @Close="Close" />
		<TypeControl v-if="Admin.Type" @Close="Close" />
		<DimensionsControl v-if="Admin.Dimensions" @Close="Close" />
		<SchemeControl v-if="Admin.Scheme" @openAddFile="openAddFile" @Close="Close" />
		<AddFile v-if="Admin.AddFile" @Close="Close" />
		<OptionsControl v-if="Admin.Options" @Close="Close"></OptionsControl>
		<SetControl v-if="Admin.Set" @Close="Close"></SetControl>
		<AddPlan v-if="Admin.Plan" @Close="Close"></AddPlan>

		<div v-if="nav.IsBlur" class="blur"></div>
		<ErorrFavorites
			v-if="LongINErorr"
			:ErorrFavorite="LongINErorr"
			@ErorrFavoriteEdite="LongINErorrRes"
		></ErorrFavorites>
	</div>
</template>

<script>
import AboutUs from './Aboutus.vue';
import ContactUs from './ContactUs.vue';
import Language from './language.vue';
import Control from './Control.vue';
import CategoryControl from '../Admin/CategoryControl.vue';
import TypeControl from '../Admin/TypeControl.vue';
import DimensionsControl from '../Admin/DimensionsControl.vue';
import SchemeControl from '../Admin/SchemeControl.vue';
import AddFile from '../Admin/AddFile.vue';
import OptionsControl from '../Admin/OptionsControl.vue';
import SetControl from '../Admin/SetControl.vue';
import AddPlan from '../Admin/AddPlan.vue';
import router from '../../router/index';
import ErorrFavorites from '../Scheme/ErorrFavorites.vue';
export default {
	data() {
		return {
			Admin: {
				Category: false,
				Type: false,
				Dimensions: false,
				Scheme: false,
				AddFile: false,
				Options: false,
				Set: false,
				Plan: false,
			},
			nav: {
				IsBlur: false,
				lan: false,
				Control: false,
				Contact: false,
				About: false,
			},
			LongINErorr: false,
			checkLogIn : false,

		};
	},
	methods: {
		OpenOrder(va) {
			var token1 = JSON.parse(localStorage.getItem('token'));
			if (token1 == null) {
				this.LongINErorr = true;
		 
			} else {
				router.push({ name: va });
			}
		},
		LongINErorrRes(va) {
			this.LongINErorr = va;
		},
		OpenFavorite(va) {
			var token1 = JSON.parse(localStorage.getItem('token'));
			if (token1 == null) {
				this.LongINErorr = true;
			 
			} else {
				router.push({ name: va });
			}
		},
		check() {
			localStorage.setItem('token', null);
			localStorage.setItem('role', 0);
			//location.reload(true);
			this.$router.go(this.$router.currentRoute);
		},
		Close() {
			this.nav.IsBlur = false;
			this.nav.lan = false;
			this.nav.Contact = false;
			this.nav.About = false;
			this.nav.Control = false;
			this.Admin.Type = false;
			this.Admin.Category = false;
			this.Admin.Dimensions = false;
			this.Admin.Scheme = false;
			this.Admin.AddFile = false;
			this.Admin.Options = false;
			this.Admin.Set = false;
			this.Admin.Plan = false;
		},
		openPage(vaq) {
			if (vaq == 1) {
				this.Admin.Type = true;
				this.Admin.Category = false;
				this.Admin.Dimensions = false;
				this.Admin.Scheme = false;
				this.Admin.Set = false;
				this.Admin.Plan = false;
			} else if (vaq == 2) {
				this.Admin.Category = true;
				this.Admin.Type = false;
				this.Admin.Dimensions = false;
				this.Admin.Scheme = false;
				this.Admin.Set = false;
				this.Admin.Plan = false;
			} else if (vaq == 3) {
				this.Admin.Dimensions = true;
				this.Admin.Category = false;
				this.Admin.Type = false;
				this.Admin.Scheme = false;
				this.Admin.Set = false;
				this.Admin.Plan = false;
			} else if (vaq == 4) {
				this.Admin.Scheme = true;
				this.Admin.Dimensions = false;
				this.Admin.Category = false;
				this.Admin.Type = false;
				this.Admin.Set = false;
				this.Admin.Plan = false;
			} else if (vaq == 5) {
				this.Admin.Options = true;
				this.Admin.Scheme = false;
				this.Admin.Dimensions = false;
				this.Admin.Category = false;
				this.Admin.Type = false;
				this.Admin.Set = false;
				this.Admin.Plan = false;
			} else if (vaq == 6) {
				this.Admin.Set = true;
				this.Admin.Options = false;
				this.Admin.Scheme = false;
				this.Admin.Dimensions = false;
				this.Admin.Category = false;
				this.Admin.Type = false;
				this.Admin.Plan = false;
			} else if (vaq == 7) {
				this.Admin.Plan = true;
				this.Admin.Set = false;
				this.Admin.Options = false;
				this.Admin.Scheme = false;
				this.Admin.Dimensions = false;
				this.Admin.Category = false;
				this.Admin.Type = false;
			}
			this.nav.IsBlur = true;
			this.nav.lan = false;
			this.nav.Contact = false;
			this.nav.About = false;
			this.nav.Control = false;
		},
		openAddFile(va) {
			if (va) {
				this.Admin.AddFile = true;
				this.Admin.Scheme = false;
				this.Admin.Dimensions = false;
				this.Admin.Category = false;
				this.Admin.Type = false;
			}
		},
		Open(va) {
			this.Admin.Scheme = false;
			this.Admin.Dimensions = false;
			this.Admin.Category = false;
			this.Admin.Type = false;
			if (va == 1) {
				if (this.nav.About) {
					this.nav.About = false;
					this.nav.IsBlur = false;
					return;
				}
				this.nav.About = true;
				this.nav.Contact = false;
				this.nav.lan = false;
				this.nav.Control = false;
			} else if (va == 2) {
				if (this.nav.Contact) {
					this.nav.Contact = false;
					this.nav.IsBlur = false;
					return;
				}
				this.nav.Contact = true;
				this.nav.lan = false;
				this.nav.About = false;
				this.nav.Control = false;
			} else if (va == 3) {
				if (this.nav.lan) {
					this.nav.lan = false;
					this.nav.IsBlur = false;
					return;
				}
				this.nav.lan = true;
				this.nav.Contact = false;
				this.nav.About = false;
				this.nav.Control = false;
			} else if (va == 4) {
				if (this.nav.Control) {
					this.nav.Control = false;
					this.nav.IsBlur = false;
					return;
				}
				this.nav.Control = true;
				this.nav.lan = false;
				this.nav.Contact = false;
				this.nav.About = false;
			}
			this.nav.IsBlur = true;
		},
	},
	components: {
		AboutUs,
		ContactUs,
		Language,
		Control,
		CategoryControl,
		TypeControl,
		DimensionsControl,
		SchemeControl,
		AddFile,
		OptionsControl,
		SetControl,
		AddPlan,
		ErorrFavorites,
	},
	mounted () {
		JSON.parse(localStorage.getItem('token'))==null ? this.checkLogIn =false :this.checkLogIn =true 
	}
};
</script>

<style lang="scss" scoped>
.blur {
	backdrop-filter: brightness(90%) blur(6px);
	background-color: rgba(142, 142, 142, 0.5);
	z-index: 10;
	position: absolute;
	width: 100%;
	height: 200vh;
}
@media only screen and (max-width: 991px) {
	.blur {
		display: none;
	}
}
.navbar-expand-lg .navbar-nav .dropdown-menu {
	border-radius: 25px;
	backdrop-filter: brightness(150%) blur(100px);
	background-color: rgba(255, 255, 255, 0.54);
}
.en-style {
	direction: rtl;
}
.header {
	margin-top: 10px;
}
.img_Back {
	background-image: url('../../assets/home/sssa.png');
	height: 25px;
}
.logo {
	width: 125px;
	/*background-image: url("../assets/logo1.png");*/
}
.content {
	width: 25px;
	height: 25px;
	margin-top: 5px;
	    cursor: pointer;
}
.mainHeader {
	padding-top: 15px;
}
.navbar {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 65px;
	z-index: 15;
}
.navbar-nav {
	padding-right: 0px;
	align-items: center;
}
.navLink {
	margin-left: 10px;
	margin-right: 10px;
	font-weight: bolder;
	color: $t_primary;
	text-decoration: none;
	cursor: pointer;
}
.colo {
	color: $t_primary !important;
}
.navbar-nav .nav-link1 {
	margin-left: 10px;
	margin-right: 10px;
	font-weight: bolder;
	color: $t_primary;
}
.container-fluid {
	background-color: $secondary;
}
.Acoount {
	text-align: center;
}
.Acoount .router {
	color: $t_forth;
	text-decoration: none;
}
.BeforServices {
	display: flex;
	justify-content: center;
}
.Services {
	background-color: $secondary;
	border: 1px solid $secondary;
	color: #000000;
	cursor: pointer;
	border-radius: 35px;
	height: 40px;
	width: 85%;
	text-decoration: none;
	margin: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
}
/**.account
		<li class="nav-item">
							<a class="nav-link" href="#">
								<img class="content" src="../assets/home/person.png" alt="" />
								<div class="account ElMessiri-15">
									{{ $t('nav.MyAccount') }}
									
									
									</div>
							</a>
						</li>
							<li class="nav-item mainHeader">
							<a class="nav-link nav-link1 ElMessiri-18" href="#"
								>{{ $t('nav.AboutUs') }}
							</a>
						</li>
						
*/
</style>
