<template>
	<div class="all">
		<div class="reset ElMessiri-15">
			<router-link :to="`/${$i18n.locale}/Login`" class="close">
				<img src="../../assets/Admin/close.svg" alt="close" style="width: 15px; height: 15px" />
			</router-link>
			<div class="resetnew">
				<div class="mb-2">{{ $t('LogIn.resetEmail') }}</div>
				<input
					type="text"
					:class="{ 'is-invalid': validationStatus($v.resetEmail) }"
					class="form-in"
					v-model.trim="$v.resetEmail.$model"
					placeholder="Email"
				/>
				<div v-if="!$v.resetEmail.required" class="invalid-feedback">
					{{ $t('LogIn.required') }}
				</div>

				<button type="submit" @click="resetpassword" v-if="!loading" class="send ElMessiri-20"> {{$t('LogIn.Send')}} </button>
				 
					 
				 
			</div>
		</div>
		 <Spinner v-if="loading" style="text-align:center"></Spinner>
		<div v-if="Eroor" class="alert alert-danger alerted" id="alerted" role="alert">
			<p>{{ $t('LogIn.NotFound') }}</p>
		</div>
	</div>
</template>

<script>
 
import { required } from 'vuelidate/lib/validators';
import Spinner from '../Spinner.vue';
export default {
	components :{Spinner},
	data: () => ({
		resetEmail: '',
		loading: false,
		Eroor:false
	}),
	validations: {
		resetEmail: { required },
	},
	methods: {
		validationStatus: function (validation) {
			return typeof validation != 'undefined' ? validation.$error : false;
		},
		resetpassword() {
		 
			this.$v.$touch();
			if (this.$v.$pendding || this.$v.$error) return;
                 
			this.loading = true;
			this.$store.dispatch('Login/ResetPassword', this.resetEmail).then(res =>{
				res == true ? this.loading = false : this.Eroor =true
			 this.loading = false
			})
		 
		},
	},
	computed: {
	 
	},
};
</script>

<style lang="scss">
.all {
	display: flex;
	flex-direction: column;
	justify-content: center;
	/* align-items: center;*/
	text-align: center;
	min-height: 100vh;
}
.reset {
	background-color: $forth;
	color: $t-third;
	width: 30%;
	margin: 0 auto;
	margin-top: 30px;
	height: 270px;
	border-radius: 10px;
}

.close {
	text-decoration: none;
	color: $t-third;
	padding-top: 0px;
	margin-right: 15px;
	float: right;
}
.reset .resetnew {
	padding-top: 40px;
	text-align: center;
}
.reset .send {
	display: block;
	margin: 15px auto;
	border: 3px solid $secondary;

	background-color: $secondary;
	color: $Fifth;
	width: 130px;
	border-radius: 8px;

	height: 38px;
}
@media only screen and (max-width: 600px) {
	.form-in {
		height: 25px;
		width: 150px;
	}
}
</style>
