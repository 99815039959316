/* eslint-disable prettier/prettier */
import axios from 'axios';
import { PostLogin, ResetPassw, ResetUsingCode } from '../Services/LoginAPI';
import router from '../../../router/index';
 
 
 
export default {
	LoginUser({ commit }, user) {
		return new Promise((resolve,reject) => {

	 
		axios
			.post(PostLogin, {
				emailOrPhone: user.Email,
				password: user.Password,
			})
			.then((response) => {
			 
				if (response.status == 200) {
					commit('SET_Login', response.data);
					localStorage.setItem('token',JSON.stringify(response.data.token));
					localStorage.setItem('role', JSON.stringify(response.data.user.role));
					localStorage.setItem('user', JSON.stringify(response.data.user.id));
                        
							router.push({ name: 'Home' });
						 
                   resolve(true);
					  
				}
	 
			})
			.catch((error) => {
			 
				console.log(error.response.data.msg);

				let statuserror = error.response.status;
				if (statuserror == 401 || statuserror == 477) {
					commit('errorlang', 'errorLogIN');
					resolve(false);
				}
			});
		})
	},

	ResetPassword({ commit }, res) {
	 return new Promise ((resolve,reject) => {

	 
		axios
			.post(ResetPassw, {
				emailOrPhone: res,
			})
			.then((response) => {
				if (response.status == 200) {
					commit('svaeEmail', res);
					router.push({ name: 'ResetUsingCode' });
					resolve(true);
				}
			})
			.catch((error) => {
				let errorReset = error.response.status;
				if (errorReset == 503 || errorReset == 418) {
					resolve(false)
				 
				}
			 
			});
		})
	},
	ResetCode({ commit, state }, Reset) {
		return new Promise((resolve,reject) => {

	 
		axios
			.post(ResetUsingCode + Reset.Code, {
				emailOrPhone: state.svaeEmail,
				newPassword: Reset.NewPassowrd,
			})
			.then((response) => {
				if (response.status == 200) {
					commit('New_reset', response.data);
					router.push({ name: 'Home' });
					resolve(true);
				}
			})
			.catch((error) => {
				if (error.response.status == 400 || error.response.status == 418) {
					console.log(error.response.status );
					resolve(false)
				 
				}
			});
		})
	},
};
