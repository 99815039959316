/* eslint-disable prettier/prettier */
import { getPermission,PostPlan,getPLan ,DeletePlan, PayToSub,PatchPlan } from './PlanAPI';
import axios from 'axios';
export default {
	getPermission(data) {
		return axios.get(getPermission+data.limit+"&offset="+data.offset,{
			headers: {
              
				Authorization: "Bearer " +  JSON.parse(localStorage.getItem('token'))
			 }
		}).then((res) => {
			return res;
		});
	},
	getPLan(data) {
		return axios.get(getPLan+data.limit+"&offset="+data.offset).then((res) => {
			return res;
		});
	},
	 
	PostPlan(data) { 
   
		return axios.post(PostPlan,{
			price: data.Plan.PriceNow,
			priceBefore:data.Plan.PriceBefor,
            isOneTime:  data.OneTime ,
			nameAr :data.Plan.nameAr,
			nameEn : data.Plan.nameEn,
			durationInMonth: data.Plan.timeDate,
			permissionsIds:data.Select
		},{
			
				headers: {
				  
				   Authorization: "Bearer " +  JSON.parse(localStorage.getItem('token'))
				}
		}).then((res) => {
			return res;
		});
	},
	PatchPlan(data) {
	 
		return axios.patch(PatchPlan+ data.PlanInput.id,{
			price: data.PlanInput.PriceNow ,
			priceBefore:data.PlanInput.PriceBefor ,
			isOneTime:data.OneTime,
			nameAr :data.PlanInput.nameAr,
			nameEn:data.PlanInput.nameEn,
			durationInMonth:data.PlanInput.timeDate
		},  {
            headers: {
              
               Authorization: "Bearer " +  JSON.parse(localStorage.getItem('token'))
            }
         }).then((res) => {
			return res;
		});
	},
	deletePlan(data) {
		return axios.delete(DeletePlan+data,{
            headers: {
              
               Authorization: "Bearer " +  JSON.parse(localStorage.getItem('token'))
            }
         }).then((res) => {
			return res;
		});
	},
	PayToSub(data) {
	 
		return axios.post(PayToSub+data,{},{
			headers: {
              
				Authorization: "Bearer " +  JSON.parse(localStorage.getItem('token'))
			 }
		}).then((res)=> {
			return res;
		});
	}
};
