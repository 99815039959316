/* eslint-disable prettier/prettier */
import PlanServices from '../Services/PlanServices';
 
 
export default {
    getPermission({commit}) {
        var offset = 0;
        var limit = 10;
        return new Promise((resolve,reject) => {

        
          PlanServices.getPermission({limit: limit, offset : offset}).then(response => {
            commit('getPermission', response.data);
            limit = response.data.total ;
      
            offset = 10;
            if(limit>10) {
           
                limit -=10 
                PlanServices.getPermission({limit: limit, offset : offset}).then( response=> {
                     commit("getPermission",response.data); 
                 }).catch(error => {
                     console.log(error.response.data.msg);
                 })
             } 
             resolve(true);
          }).catch(err=> {
            console.log(err.response.data.msg);
            resolve(false);
        })
    })
    },
    getPLan({commit}) {
        var offset = 0;
        var limit = 10;
        return new Promise((resolve,reject)=> {

        
        PlanServices.getPLan({limit: limit, offset : offset}).then (response => {
          
            commit('get_PLan', response.data);
            limit = response.data.total ;
      
            offset = 10;
            if(limit>10) {
           
                limit -=10 
                PlanServices.getPLan({limit: limit, offset : offset}).then( response=> {
                     commit("get_PLan",response.data); 
                 }).catch(error => {
                     console.log(error.response.data.msg);
                 })
             } 
             resolve(true);
        }).catch(error => {
            resolve(false);
            console.log(error.response.data.msg);
        })
    })
    },
   
    PostPlan({commit},data) {
       return new Promise((resolve,reject) => {
          PlanServices.PostPlan({Plan:data.PlanInput,Select:data.Select,OneTime:data.OneTime }).then( response => {
            if(response.status == 201) {
                resolve(true);
              
            } 
          }).catch(error => {
            resolve(false);
         
            console.log(error.response.data.msg);
            reject(error.response);
          })
       }) 
    },
    PatchPlan({state,dispatch},data) {
        return new Promise((resolve,reject) => {
            PlanServices.PatchPlan(data).then ( response => {
                if(response.status == 200) {
                    dispatch ('getPLan')
                 /*   state.get_Category.forEach((element,index) => {
                        if(element.id == Category.id) {
                         state.get_Category[index] = Category
                        }
                     });*/
                  
                     resolve(true);
                   
                    }
            }).catch(error => {
                resolve(false);
                console.log(error.response.data.msg);
                reject(error.response);
            })
        })
      
    },
    deletePlan({state},data) {
            return  new Promise ((resolve,reject) => {
               PlanServices.deletePlan(data).then (response => {
              
                   if(response.status == 204) {
                    state.get_PLan.forEach((element,index) => {
                       if(element.id == data) {
                        state.get_PLan.splice(index,1)
                       }
                    });
                    resolve(true)
                }
               }).catch(error => {
                resolve(false)
                  reject(error.response)
                   console.log(error.response.data.msg);
               })
            })
    },
    PayToSub({},data) {
    
        PlanServices.PayToSub(data).then ( response => {
            if (response.status == 200) {
                window.open(response.data.url, '_blank');
            }
        }).catch(err => {
            console.log(err.response.data.msg);
        })
    }
}
 
  /**
   *         axios.delete(DeleteChategory+Categoryid,{
        headers: {
              
            Authorization: "Bearer " + JSON.parse(localStorage.getItem('token'))
         }  
      }).then (response => {
           commit("Success","success")
       
      }).catch(error => {
        
       
    })
   */