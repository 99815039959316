/* eslint-disable  */
import state from './SignUp.state'
import getters from './SignUp.getters'
import mutations from './SignUp.mutation'
import actions from './SignUp.action'


export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
    modules:{}
}