<template>
	<div class="row justify-content-center" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
		<div class="col-md-6 col-lg-8 col-sm-10 col-12 CatControl">
			<div class="row justify-content-between">
				<div class="col-4">
					<div class="ElMessiri-25 font-bold">{{ $t('Control.Options.AddOption') }}</div>
				</div>
				<div class="col-1">
					<img
						src="../../assets/Admin/close.svg"
						alt="close"
						@click="close"
						style="width: 22px; margin: 6px 25px"
					/>
				</div>
			</div>

			<div class="line"></div>
			<div class="row justify-content-around ElMessiri-20">
				<div class="col-md-6 col-lg-5 col-sm-10 col-12">
					<div>{{ $t('Control.NameAr') }}</div>
					<input
						class="col-lg-12 col-sm-10 col-12"
						:class="{ 'is-invalid': validationStatus($v.OptionInput.nameAr) }"
						type="text"
						v-model="OptionInput.nameAr"
						:placeholder="$t('Control.NameAr')"
					/>
					<div v-if="SendError">
						<div v-if="!$v.OptionInput.nameAr.required" class="invalid-feedback block">
							{{ $t('LogIn.required') }}
						</div>
					</div>

					<div>{{ $t('Control.NameEn') }}</div>
					<input
						class="col-lg-12 col-sm-10 col-12"
						:class="{ 'is-invalid': validationStatus($v.OptionInput.nameEn) }"
						:dir="OptionInput.nameEn == '' ? '' : 'ltr'"
						type="text"
						v-model="OptionInput.nameEn"
						:placeholder="$t('Control.NameEn')"
					/>
					<div v-if="SendError">
						<div v-if="!$v.OptionInput.nameEn.required" class="invalid-feedback block">
							{{ $t('LogIn.required') }}
						</div>
					</div>
					<div>{{ $t('Control.Options.descriptionAr') }}</div>
					<input
						class="col-lg-12 col-sm-10 col-12"
						:class="{ 'is-invalid': validationStatus($v.OptionInput.descriptionAr) }"
						type="text"
						v-model="OptionInput.descriptionAr"
						:placeholder="$t('Control.Options.descriptionAr')"
					/>
					<div v-if="SendError">
						<div v-if="!$v.OptionInput.descriptionAr.required" class="invalid-feedback block">
							{{ $t('LogIn.required') }}
						</div>
					</div>
					<div>{{ $t('Control.Options.descriptionEn') }}</div>
					<input
						class="col-lg-12 col-sm-10 col-12"
                        :dir="OptionInput.descriptionEn == '' ? '' : 'ltr'"
						:class="{ 'is-invalid': validationStatus($v.OptionInput.descriptionEn) }"
						type="text"
						v-model="OptionInput.descriptionEn"
						:placeholder="$t('Control.Options.descriptionEn')"
					/>
					<div v-if="SendError">
						<div v-if="!$v.OptionInput.descriptionEn.required" class="invalid-feedback block">
							{{ $t('LogIn.required') }}
						</div>
					</div>
					<div>{{ $t('Control.Options.priceBefore') }}</div>
					<input
						class="col-lg-12 col-sm-10 col-12"
						:class="{ 'is-invalid': validationStatus($v.OptionInput.priceBefore) }"
						type="text"
						v-model="OptionInput.priceBefore"
						:placeholder="$t('Control.Options.priceBefore')"
                         @keyup="acceptNumber"
					/>
					<div v-if="SendError">
						<div v-if="!$v.OptionInput.priceBefore.required" class="invalid-feedback block">
							{{ $t('LogIn.required') }}
						</div>
					</div>
					<div>{{ $t('Control.Options.price') }}</div>
					<input
						class="col-lg-12 col-sm-10 col-12"
						:class="{ 'is-invalid': validationStatus($v.OptionInput.price) }"
						type="text"
						v-model="OptionInput.price"
						:placeholder="$t('Control.Options.price')"
                         @keyup="acceptNumber"
					/>
					<div v-if="SendError">
						<div v-if="!$v.OptionInput.price.required" class="invalid-feedback block">
							{{ $t('LogIn.required') }}
						</div>
					</div>
				</div>
				<div class="col-md-6 col-lg-5 col-sm-10 col-12">
					<div>{{ $t('Control.Options.model') }}</div>

					<input
						class="col-lg-12 col-sm-10 col-12"
						type="file"
						ref="fileInput"
						style="display: none"
						@input="pickFile"
					/>

					<button class="AddImage" @click="$refs.fileInput.click()">
						<img
							:class="imageShow == '' ? '' : 'Hiden'"
							style="margin: 110px auto"
							src="../../assets/Admin/Add.svg"
							alt=""
						/>
						<div class="imagePreviewWrapper" :style="{ 'background-image': `url(${imageShow})` }"></div>
					</button>
					<div v-if="SendError">
						<div v-if="!$v.OptionInput.image.required" class="invalid-feedback block">
							{{ $t('LogIn.required') }}
						</div>
					</div>
				</div>
			</div>
			<div class="row justify-content-center ElMessiri-20">
               <div class="col-lg-9 col-sm-10">
				   	<div v-for="getOption in getOptions" :key="getOption.id">
						<div  class="getOption row justify-content-around">
							<span class="col-3" v-if="`${$i18n.locale}` == 'en'"> {{ getOption.nameEn }} </span>
							<span class="col-3" v-if="`${$i18n.locale}` == 'ar'"> {{ getOption.nameAr }} </span>
                            <a class="SeeModel col-3" :href="getOption.modelUrl"> {{$t('Control.Options.SeeModel')}} </a>
							<span class="col-3">  {{getOption.price}} $    <span class="priceBefore">{{getOption.priceBefore}} $</span> </span>
							<span class="col-2">
								<img
									class="deleteEdite"
									@click="delet(getOption.id)"
									src="../../assets/Admin/Delete.svg"
									alt="Delete"
									style="cursor: pointer;"
								/>
								<img
									class="deleteEdite"
									@click="Edit(getOption)"
									src="../../assets/Admin/Edit.svg"
									alt="Edit"
										style="cursor: pointer;"
								/>
							</span>
						</div>
					</div>
			   </div>

				<button v-if="!loading" class="col-lg-6 col-sm-8 col-10 save" @click="Save">
					{{ $t('Control.Save') }}
				</button>
				<Spinner v-if="loading" class="col-lg-6 col-sm-8 col-10" style="text-align: center" />

				<div v-if="Eroor" class="alert alert-danger col-sm-12 col-12 ElMessiri-20" role="alert">
					{{ $t('Control.Eroor') }}
				</div>
			</div>
			 <ConfirmDelete v-if="ConfirmDelete" @ConfirmClose="ConfirmClose" @ConfirmYes="ConfirmYes"></ConfirmDelete>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import Spinner from '../Spinner.vue';
import ConfirmDelete from './ConfirmDelete.vue';
export default {
	data() {
		return {
			OptionInput: {
				id: null,
				nameAr: '',
				nameEn: '',
				descriptionAr: '',
				descriptionEn: '',
				price: '',
				priceBefore: '',
				image: '',
			},
			SendError: false,
			loading: false,
			Eroor: false,
			ArrayErorr: [],
			imageShow: '',
			EditTosend: false,
			ConfirmDelete: false,
			IdDelete : null
		};
	},
	validations: {
		OptionInput: {
			nameAr: { required },
			nameEn: { required },
			descriptionAr: { required },
			descriptionEn: { required },
			price: { required },
			priceBefore: { required },
			image: { required },
		},
	},
	components: { Spinner, ConfirmDelete },
	methods: {
		validationStatus: function (validation) {
			return typeof validation != 'undefined' ? validation.$error : false;
		},
    acceptNumber() {
        var x = this.OptionInput.price.replace(/\D/g, '');
        var y = this.OptionInput.priceBefore.replace(/\D/g, '');
        this.OptionInput.price = x
        this.OptionInput.priceBefore = y
    },
		close() {
			this.$emit('Close');
		},
delet(va) {
 		this.ConfirmDelete = true;
			this.IdDelete = va;
},
	ConfirmClose() {
			this.ConfirmDelete = false;
		},
		ConfirmYes() {
			 
		 	this.loading= true;
 this.$store.dispatch('Option/deleteOption',this.IdDelete).then(res => {
	 res == true ? this.loading =false : this.Eroor = true
 
 })
	
			this.ConfirmDelete = false;
		},
		Edit(getOption) {
 
			this.OptionInput.nameAr = getOption.nameAr;
			this.OptionInput.nameEn = getOption.nameEn;
			this.OptionInput.descriptionAr = getOption.descriptionAr;
			this.OptionInput.descriptionEn = getOption.descriptionEn;
			this.OptionInput.price = getOption.price;
			this.OptionInput.priceBefore = getOption.priceBefore;
			this.OptionInput.id = getOption.id;
		  this.OptionInput.image = getOption.modelUrl
		 this.imageShow = getOption.modelUrl
			this.EditTosend = true;
		 
		},
		Save() {
			this.SendError = true;
			this.$v.$touch();
			if (this.$v.$pendding || this.$v.$error) return;
			this.loading = true;
			if (this.EditTosend) {
			 
					this.$store.dispatch('Option/patchOption', this.OptionInput).then((res) => {
					if (res) {
						this.OptionInput = {
							id: null,
							nameAr: '',
							nameEn: '',
							descriptionAr: '',
							descriptionEn: '',
							price: '',
							priceBefore: '',
							image: '',
						};
				  this.imageShow = ''
							this.$store.dispatch('Option/getOption');
							this.EditTosend = false
					} else {
						this.Eroor = true;
					}
		 this.SendError = false;
					this.loading = false;
						 this.imageShow =''
				});
			} else {
				this.$store.dispatch('Option/PostOption', this.OptionInput).then((res) => {
					if (res) {
						this.OptionInput = {
							id: null,
							nameAr: '',
							nameEn: '',
							descriptionAr: '',
							descriptionEn: '',
							price: '',
							priceBefore: '',
							image: '',
						};

							this.$store.dispatch('Option/getOption');
					} else {
						this.Eroor = true;
					}
                       this.SendError = false;
					this.loading = false;
					this.imageShow =''
				});
			}
		},
		pickFile(event) {
		 
			this.OptionInput.image = event.target.files[0];
			let input = this.$refs.fileInput;
			let file = input.files;
			if (file && file[0]) {
				let reader = new FileReader();
				reader.onload = (e) => {
					this.imageShow = e.target.result;
				};
				reader.readAsDataURL(file[0]);
				this.$emit('input', file[0]);
			}
		},
	},
	computed: {
		...mapState({
			getOptions: (state) => state.Option.get_Option,
		 
		}),
	},
	mounted() {
		this.$store.dispatch('Option/getOption');
	},
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss"  >
.block {
	display: block;
}
.CatControl {
	background-color: #ffffff;
	z-index: 11;
	position: absolute;
	top: 25%;
	border-radius: 20px;
	padding: 25px 15px;
}
.CatControl .line {
	background-color: $forth;
	height: 1px;
	margin: 0px 0px 20px 0px;
}
.CatControl input {
	border-radius: 5px;
	border: solid 1px #757575;
	margin: 7px 0px;
}
/**.alert-success
 ::-webkit-file-upload-button {
  background: black;
  color: red;
  padding: 1em;
}
 */

.CatControl select {
	background-color: $Sex;
	color: $t-Sex;
}

.Category {
	margin: 10px 0px;
	padding: 4px 5px;
	border-radius: 5px;
	display: flex;
	justify-content: space-between;
	background-color: $secondary;
}
.deleteEdite {
	width: 20px;
	margin: 0px 6px;
	height: 20px;
}
.save {
	background-color: $third;
	color: $t_forth;
	border-radius: 5px;
	border: none;
	margin: 25px 0px;
	padding: 5px 0px;
}
.alert-success {
	text-align: center;
}
.alert-danger {
	text-align: center;
}
.AddImage {
	width: 100%;
	max-height: 250px;
	height: 250px;
	background-color: $Fifth;
	border: 1px solid $t_secondary;
	border-radius: 10px;
}
.imagePreviewWrapper {
	width: 100%;
	height: 100%;
	display: block;
	cursor: pointer;
	margin: 0 auto 30px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
}
.Hiden {
	display: none;
}
.getOption {
    background-color: $Fifth ;
    border: 1px solid;
    box-shadow: 1px 1px 1px 1px $forth;
    border-radius: 5px;
	margin: 5px 0;
	padding: 5px;
	display: flex;
	justify-content: space-evenly;
	    text-align: center;
}
.SeeModel {
	color:   $t-Sex;
}
.priceBefore {
	    text-decoration: line-through 1.5px  $t_secondary ;
    color:  $t_secondary ;
    margin: 0 5px;
}

</style>
