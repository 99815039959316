<template>
  <div class="About">
      <img class="Arrow" src="../../assets/icon/Arrow.svg" alt="">
      <div class="AboutBody ElMessiri-16" > 
           {{$t('AboutUs')}}
      </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.About {
       z-index: 11;
    position: absolute;
    margin-top: 25px;
    width: 403px;
    margin-right: -315px;
     backdrop-filter: brightness(150%) blur(100px);
    background-color: rgba(255, 255, 255, 0.54);
    border-radius: 25px;
}
 .Arrow {
         position: absolute;
    left: 25px;
   
      margin-top: -15px;
    width: 15px;
 
 }
.About .AboutBody {
 
    padding: 5px 10px;
    text-align: center;
}
</style>