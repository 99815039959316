import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Favorite from '../components/Scheme/FavoriteScheme.vue';
import Login from '../views/Login.vue';
import sign_up from  '../views/sign_up.vue';
import Reset from '../components/Login/ResetPassowrd.vue';
import ResetUsingCode from '../components/Login/ResetUsingCode.vue';
 
import DetailsScheme from '../components/DetailsScheme/DetailsScheme.vue';
import RequestModify from '../components/RequestModify/RequestModify.vue';
import MyRequests from '../components/RequestModify/MyRequests.vue';
import ModifyDetails from '../components/RequestModify/ModifyDetails.vue';

import AddOrder from "../components/DesignOrder/AddOrder.vue";
import MyOrder from "../components/DesignOrder/MyOrder.vue";
import OrderDetails from "../components/DesignOrder/OrderDetails.vue";
import Statistics from "../components/Admin/statistics.vue";
import Subscribe from "../components/Plan/Subscribe.vue"
import i18n from '../i18n';
Vue.use(VueRouter);
 
const routes = [
	{
		path : "/",
	   redirect : `/${i18n.locale}`	 
	},
	{   
		path : "/:lang",
		component : {
             render (c) {return c('router-view')}
		},
		children: [
		{
			path: '/',
			name: 'Home',
			component: Home,
		},
	/*	{
			path: 'about',
			name: 'About',
			// route level code-splitting
			// this generates a separate chunk (about.[hash].js) for this route
			// which is lazy-loaded when the route is visited.
			component: () => import(/* webpackChunkName: "about"  '../views/About.vue'),
		},
		*/
		{
			path: 'Login',
			name: 'Login',
			component: Login,
		},
		{
			path: 'sign_up',
			name: 'sign_up',
			component: sign_up,
		},
		{
			path: 'Reset',
			name: 'Reset',
			component: Reset,
		},
		{
			path: 'ResetUsingCode',
			name: 'ResetUsingCode',
			component: ResetUsingCode,
		},
	 
		{
			path: 'DetailsScheme/:id',
			name: 'DetailsScheme',
			component: DetailsScheme,
			props: true,
			meta: { title: "Data list" },
		},
		 
		{
			path: 'RequestModify',
			name: 'RequestModify',
			component: RequestModify,
		},
		{
			path: 'MyRequests',
			name: 'MyRequests',
			component: MyRequests,
		},
		{
			path: 'ModifyDetails/:idRequest',
			name: 'ModifyDetails',
			component: ModifyDetails,
			props: true,
			meta: { title: "Data Request Details list" },
		}, 
		{
			path: 'Favorite',
			name: 'Favorite',
			component: Favorite,
		},
		{
			path: 'AddOrder',
			name: 'AddOrder',
			component: AddOrder,
		},
		{
			path: 'MyOrder',
			name: 'MyOrder',
			component: MyOrder,
		},
		{
			path: 'OrderDetails/:idOrder',
			name: 'OrderDetails',
			component: OrderDetails,
			props: true,
			meta: { title: "Data Order Details list" },
		}, 
		{
			path: 'Statistics/',
			name: 'Statistics',
			component: Statistics,
		}, 
		{
			path: 'Subscribe/',
			name: 'Subscribe',
			component: Subscribe,
		},
		 
	  ]
	}
 
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
});

export default router;
