<template>
	<div>
		<Header></Header>
		<SectionModify :first1_1="$t('Subscribe.Subscribe')" :secound1_1="$t('Subscribe.ChoosePlan')"></SectionModify>
		<Spinner v-if="loading" style="text-align: center"></Spinner>

		<div
			v-else
			class="row justify-content-center mt-4 ElMessiri-20 font-bold"
			:dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
		>
			<div class="col-10">
				<div class="row justify-content-around mt-2 mb-2">
					<div class="col-12 col-sm-5 col-lg-4 mt-4" v-for="GetPlan in GetPlans" :key="GetPlan.id">
						<div class="Subscribe">
							<div class="SubscribeBody">
								<img
									v-if="$admins"
									@click="Delet(GetPlan.id)"
									src="../../assets/Admin/Delete.svg"
									alt="Delete"
									style="cursor: pointer;"
									:class="$i18n.locale == 'en' ? 'DeleteEditR' : 'DeleteEditL'"
								/>
								<img
									v-if="$admins"
									src="../../assets/Admin/Edit.svg"
									@click="OpenEdit(GetPlan)"
									alt="Edit"
									style="cursor: pointer;"
									:class="$i18n.locale == 'en' ? 'DeleteEditR' : 'DeleteEditL'"
								/>
								<div class="clear"></div>
								<div class="Price">
									{{ GetPlan.price }}$
									<span class="pricePefor">{{ GetPlan.priceBefore }}$</span>
								</div>
								<div class="NameSub" v-if="`${$i18n.locale}` == 'en'">{{ GetPlan.nameEn }}</div>
								<div class="NameSub" v-if="`${$i18n.locale}` == 'ar'">{{ GetPlan.nameAr }}</div>

								<div class="Time">
									{{ $t('Subscribe.For') }} {{ GetPlan.durationInMonth }} {{ $t('Subscribe.Month') }}
								</div>
							</div>
							<div class="line"></div>

							<div class="SubscribeBody SubscribeBody1">
								<div v-for="planPermission in GetPlan.planPermissions" :key="planPermission.id">
									<img src="../../assets/Sub/Sub.svg" alt="Sub" />
									<span class="Advantages" v-if="`${$i18n.locale}` == 'en'">
										{{ planPermission.nameEn }}</span
									>
									<span class="Advantages" v-if="`${$i18n.locale}` == 'ar'">
										{{ planPermission.nameAr }}</span
									>
								</div>
							</div>
							<div class="sub mt-2 mb-2">
								<button @click="Pay(GetPlan.id)">{{ $t('Subscribe.Sub') }}</button>
							</div>
						</div>
					</div>
				</div>
				<div class="row justify-content-around mt-2 mb-2">
					<div class="mt-4" v-if="Mysubscriptions">{{ $t('Subscribe.CurrentSubscriptions') }}</div>
					<div
						class="col-12 col-sm-5 col-lg-4 mt-4"
						v-for="Mysubscription in Mysubscriptions"
						:key="Mysubscription.id"
					>
						<div class="Subscribe">
							<div class="SubscribeBody">
								<div class="Price">
									{{ Mysubscription.paidPrice }}$
									<span class="pricePefor"> </span>
								</div>
								<div class="NameSub" v-if="`${$i18n.locale}` == 'en'">{{ Mysubscription.nameEn }}</div>
								<div class="NameSub" v-if="`${$i18n.locale}` == 'ar'">{{ Mysubscription.nameAr }}</div>

								<div class="Time">
									{{ $t('Subscribe.EndDate') }} {{ format_date(Mysubscription.endDate) }}
								</div>
							</div>
							<div class="line"></div>

							<div class="SubscribeBody SubscribeBody1">
								<div v-for="MyPermission in Mysubscription.permissions" :key="MyPermission.id">
									<img src="../../assets/Sub/Sub.svg" alt="Sub" />
									<span class="Advantages" v-if="`${$i18n.locale}` == 'en'">
										{{ MyPermission.nameEn }}</span
									>
									<span class="Advantages" v-if="`${$i18n.locale}` == 'ar'">
										{{ MyPermission.nameAr }}</span
									>
									<span class="Time" v-if="MyPermission.times != null">
										{{ $t('Subscribe.Balance') }} {{ MyPermission.times }}
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<ConfirmDelete v-if="ConfirmDelete" @ConfirmClose="ConfirmClose" @ConfirmYes="ConfirmYes"></ConfirmDelete>
		    <AddPlan v-if="OpenPlanEdit" :OpenPlanEdit="OpenPlanEdit" :dataEdit="dataEdit" @Close="Close" ></AddPlan>
		</div>
	</div>
</template>

<script>
import Header from '../Header/Header.vue';
import SectionModify from '../RequestModify/SectionModify.vue';
import { mapState } from 'vuex';
import ConfirmDelete from '../Admin/ConfirmDelete.vue';
import moment from 'moment';
import Spinner from '../Spinner.vue';
import AddPlan from '../Admin/AddPlan.vue';
 
export default {
	data() {
		return {
			ConfirmDelete: false,
			IdDelete: null,
			loading: true,
			OpenPlanEdit: false,
			dataEdit:{}
		};
	},
	components: { Header, SectionModify, ConfirmDelete, Spinner, AddPlan },
	methods: {
		Close() {
            this.OpenPlanEdit = false
			    this.OpenPlanEdit = false
			this.dataEdit = {}
		},
		OpenEdit(va) {
			this.dataEdit = va
            this.OpenPlanEdit = true
			 
		},
		format_date(value) {
			if (value) {
				return moment(String(value)).format('YYYY/MM/DD');
			}
		},
		Delet(va) {
			this.IdDelete = va;
			this.ConfirmDelete = true;
		},
		ConfirmClose() {
			this.ConfirmDelete = false;
		},
		ConfirmYes() {
			this.loading = true;
			this.$store.dispatch('Plan/deletePlan', this.IdDelete);

			this.ConfirmDelete = false;
		},
		Pay(va) {
			this.$store.dispatch('Plan/PayToSub', va);
		},
	},
	computed: {
		...mapState({
			GetPlans: (state) => state.Plan.get_PLan,
			Mysubscriptions: (state) => state.General.getMyProfile.subscriptions,
		}),
	},
	mounted() {
			if (JSON.parse(localStorage.getItem('token')) != null) {
	 	this.$store.dispatch('General/getMyProfile');
		}
			this.$store.dispatch('Plan/getPLan').then((res) => {
			res == true ? (this.loading = false) : '';
		});
	},
};
</script>

<style lang="scss" scoped>
.DeleteEditR {
	float: right;
	padding: 0 5px;
}
.DeleteEditL {
	float: left;
	padding: 0 5px;
}
.clear {
	clear: both;
}
.Subscribe {
	border: 2px solid #989898;
	border-radius: 10px;
	    width: 96%;
		height: 100%;
   position: relative;
   padding-bottom: 50px;
}
.SubscribeBody {
	margin: 10px;
}
 
.Price {
	font-size: 25px;
}
.pricePefor {
	font-size: 20px;
	text-decoration: 1px line-through;
	color: $t-Seven;
}
.Time {
	color: $t-Seven;
}
.line {
	background-color: #c1c1c1;
	height: 1px;
}
.sub {
  position: absolute;
  bottom: 0;
  left: 32%;

}
.sub button {
	background-color: $t-Seven;
	color: $t-third;
	border: none;
	border-radius: 8px;
	width: 135px;
	padding: 6px;
}
.Advantages {
	padding: 0 10px;
}
</style>
