/* eslint-disable prettier/prettier */
import DimensionsServices from '../Services/DimensionsServices';
 
export default {
 
   getDimensions({commit}) {
    var offset = 0;
    var limit = 10;
       DimensionsServices.getDimensions({limit: limit, offset : offset}).then ( response => {
       
           commit("set_Empty");
           commit('get_Dimensions', response.data);
           limit = response.data.total ;
      
           offset = 10;
           if(limit>10) {
          
               limit -=10 
               DimensionsServices.getDimensions({limit: limit, offset : offset}).then( response=> {
                    commit("get_Dimensions",response.data); 
                }).catch(error => {
                    console.log(error.response.data.msg);
                })
            } 
            
       }).catch(err => {
           console.log(err.response.data.msg);
       })
   },
   PostDimensions({commit},data) {
       return new Promise((resolve,reject) => {
        DimensionsServices.PostDimensions(data).then(response => {
            if(response.status == 201) {
                resolve(true);
               
            } 
           }).catch(error => {
            resolve(false);
         
            console.log(error.response.data.msg);
            reject(error.response);
           })

       })
     
   },
   DeleteDimensions({commit,state},data) {
       return new Promise ( (resolve,reject) => {
        DimensionsServices.DeleteDimensions(data).then(response => {
            if(response.status == 204) {
                state.get_Dimensions.forEach((element,index) => {
                   if(element.id == data) {
                    state.get_Dimensions.splice(index,1)
                   }
                });
                resolve(true);
            }
          
        }).catch(error=> {
            resolve(false)
            reject(error.response)
            console.log(error.response.data.msg);
        })
      
    })
   
   }
}
 
  /**
   *    
   */