<template>
	<div class="row justify-content-center" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
		<div class="col-md-6 col-lg-8 col-sm-10 col-12 CatControl">
			<div class="row justify-content-between">
				<div class="col-4">
					<div class="ElMessiri-25 font-bold">{{ $t('Control.Dimensions') }}</div>
				</div>
				<div class="col-1">
					<img
						src="../../assets/Admin/close.svg"
						alt="close"
						@click="close"
						style="width: 22px; margin: 6px 25px"
					/>
				</div>
			</div>
			<div class="line"></div>
			<div class="row justify-content-around ElMessiri-20">
				<div class="col-md-6 col-lg-6 col-sm-10 col-12">
					<div>{{ $t('Control.Dimension.AddNew') }}</div>
					<div class="line"></div>
					<div>{{ $t('Control.NameAr') }}</div>
					<input
						class="col-lg-12 col-sm-10 col-12"
						:class="{ 'is-invalid': validationStatus($v.DimensionInput.nameAr) }"
						type="text"
						v-model="DimensionInput.nameAr"
						:placeholder="$t('Control.NameAr')"
					/>
					<div v-if="SendError">
						<div v-if="!$v.DimensionInput.nameAr.required" class="invalid-feedback block">
							{{ $t('LogIn.required') }}
						</div>
					</div>
					<div>{{ $t('Control.NameEn') }}</div>
					<input
						class="col-lg-12 col-sm-10 col-12"
						:class="{ 'is-invalid': validationStatus($v.DimensionInput.nameEn) }"
						:dir="DimensionInput.nameEn == '' ? '' : 'ltr'"
						type="text"
						v-model="DimensionInput.nameEn"
						:placeholder="$t('Control.NameEn')"
					/>
					<div v-if="SendError">
						<div v-if="!$v.DimensionInput.nameEn.required" class="invalid-feedback block">
							{{ $t('LogIn.required') }}
						</div>
					</div>
					<div>{{ $t('Control.Dimension.unit') }}</div>
					<select class="col-lg-12 col-sm-10 col-12" name="Dimension" v-model="DimensionInput.unit">
						<option class="unit" value="m">m</option>
						<option class="unit" value="m2">m2</option>
					</select>
					<div v-if="SendError">
						<div v-if="!$v.DimensionInput.unit.required" class="invalid-feedback block">
							{{ $t('LogIn.required') }}
						</div>
					</div>
					<button v-if="!loading" class="col-lg-12 col-sm-10 col-12 save" @click="Save">{{ $t('Control.Save') }}</button>
						   <div v-if="loading" class="row justify-content-center">
                          <div class="col-lg-3 col-3">
                            <Spinner/>
						  </div>
				   </div>
				</div>
				<div class="col-md-6 col-lg-4 col-sm-10 col-12">
					<div v-for="Dimension in Dimensions" :key="Dimension.id">
						<div class="Category">
							<span v-if="`${$i18n.locale}` == 'en'"> {{ Dimension.nameEn }} </span>
							<span v-if="`${$i18n.locale}` == 'ar'"> {{ Dimension.nameAr }} </span>

							<span>
								<img
									class="deleteEdite"
									@click="delet(Dimension.id)"
									src="../../assets/Admin/Delete.svg"
									alt="Delete"
									style="cursor: pointer;"
								/>
							</span>
						</div>
					</div>
				</div>
			</div>
			<div v-if="Success" class="alert alert-success col-sm-12 col-12" role="alert">
				{{ $t('Control.Success') }}
			</div>
					 <div v-if="Eroor" class="alert alert-danger col-sm-12 col-12 ElMessiri-20" role="alert">
                	{{ $t('Control.Eroor') }}
           </div>  
		</div>
		<Confirm v-if="ConfirmDelete" @ConfirmClose="ConfirmClose" @ConfirmYes="ConfirmYes" />
	</div>
</template>

<script>
import { mapState } from 'vuex';
import Confirm from './ConfirmDelete.vue';
import { required } from 'vuelidate/lib/validators';
import Spinner from '../Spinner.vue';
export default {
	data() {
		return {
			DimensionInput: {
				id: null,
				nameAr: '',
				nameEn: '',
				unit: '',
			},
			SendError: false,
			loading : false,
			Eroor : false,
			ConfirmDelete: false,
			IdDelete: null,
		};
	},
	validations: {
		DimensionInput: {
			nameAr: { required },
			nameEn: { required },
			unit: { required },
		},
	},
	components: {
		Confirm,
		Spinner
	},
	methods: {
		validationStatus: function (validation) {
			return typeof validation != 'undefined' ? validation.$error : false;
		},
		close() {
			this.$emit('Close');
		},
		delet(va) {
			this.ConfirmDelete = true;
			this.IdDelete = va;
			//this.$store.dispatch('Dimensions/DeleteDimensions', va);
		},
		ConfirmClose() {
			this.ConfirmDelete = false;
		},
		ConfirmYes() {
			this.loading = true;
			this.$store.dispatch('Dimensions/DeleteDimensions', this.IdDelete).then(res => {
				     res == true? this.DimensionInput = {
						id : null,
						nameAr : '',
						nameEn : '',
						unit:'',
					}   : this.Eroor = true

						 this.SendError = false;
						this.loading = false;
			});
			this.ConfirmDelete = false;
		},
		Save() {
			this.SendError = true;
			this.$v.$touch();
			if (this.$v.$pendding || this.$v.$error) return;
              this.loading = true;
			this.$store.dispatch('Dimensions/PostDimensions', this.DimensionInput).then( res => {
                    res == true? this.DimensionInput = {
						id : null,
						nameAr : '',
						nameEn : '',
						unit:''
					}   : this.Eroor = true

					 	//this.$store.dispatch('Dimensions/getDimensions');
						 	this.$store.dispatch('Dimensions/getDimensions');
						 this.SendError = false;
						this.loading = false;
					 
				});
		},
	},
	computed: {
		...mapState({
			Dimensions: (state) => state.Dimensions.get_Dimensions,

			Success: (state) => state.Dimensions.success,
		}),
	},
	mounted() {
		this.$store.dispatch('Dimensions/getDimensions');
	},
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
.block {
	display: block;
}
.CatControl {
	background-color: #ffffff;
	z-index: 11;
	position: absolute;
	top: 25%;
	border-radius: 20px;
	padding: 25px 15px;
}
.CatControl .line {
	background-color: $forth;
	height: 1px;
	margin: 0px 0px 20px 0px;
}
.CatControl input,
select {
	border-radius: 5px;
	border: solid 1px #757575;
	margin: 7px 0px;
}

.Category {
	margin: 10px 0px;
	padding: 4px 5px;
	border-radius: 5px;
	display: flex;
	justify-content: space-between;
	background-color: $secondary;
}
.deleteEdite {
	width: 20px;
	margin: 0px 6px;
	height: 20px;
}
.save {
	background-color: $third;
	color: $t_forth;
	border-radius: 5px;
	border: none;
	margin: 25px 0px;
}
.alert-success {
	text-align: center;
}
</style>
