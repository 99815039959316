/* eslint-disable prettier/prettier */
export default {
    SET_Login (state,data) {
        state.user = data;
    },
  
    error(state,data) {
        state.error = data;
    },
    errorlang(state,data) {
        state.errorLogIN = data;
    },
    svaeEmail(state,data) {
        state.svaeEmail = data;
    },
    
    New_reset(state,data) {
      state.New_reset = data;
    },
  
}