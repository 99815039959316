/* eslint-disable prettier/prettier */
import { getCategory, PostCategory, PatchCategory, DeleteChategory,getCategoryDimensions } from './CategoryAPI';
import axios from 'axios';
export default {
	getCategory(data) {
		return axios.get(getCategory+data.limit+"&offset="+data.offset).then((res) => {
			return res;
		});
	},
	getCategoryDimensions(data) {
     return axios.get(getCategoryDimensions+ data+'?limit=10&offset=0',{
		headers: {
              
			Authorization: "Bearer " +  JSON.parse(localStorage.getItem('token'))
		 }
	 }).then((res) => {
		 return res;
	 })
	},
	PostCategory(data) { 
		var dimensionsIds0 = data.dimensionsIds[0]
		 
		return axios.post(PostCategory,{
			nameAr :data.Category.nameAr,
			nameEn : data.Category.nameEn,
			 
		},{
			
				headers: {
				  
				   Authorization: "Bearer " +  JSON.parse(localStorage.getItem('token'))
				}
		}).then((res) => {
			return res;
		});
	},
	PatchCategory(data) {
		return axios.patch(PatchCategory+ data.id,{
			nameAr:data.nameAr,
			nameEn: data.nameEn
		},  {
            headers: {
              
               Authorization: "Bearer " +  JSON.parse(localStorage.getItem('token'))
            }
         }).then((res) => {
			return res;
		});
	},
	DeleteChategory(data) {
		return axios.delete(DeleteChategory+data,{
            headers: {
              
               Authorization: "Bearer " +  JSON.parse(localStorage.getItem('token'))
            }
         }).then((res) => {
			return res;
		});
	},
};
