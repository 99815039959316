/* eslint-disable prettier/prettier */
import {  getDimensions,PostDimensions,DeleteDimensions } from './DimensionsAPI';
import axios from 'axios';
export default {
 
	getDimensions(data) {
		return axios.get(getDimensions+data.limit+"&offset="+data.offset,{
							headers: {
				  
				   Authorization: "Bearer " +  JSON.parse(localStorage.getItem('token'))
				}
			 
		}).then((res) => {
			return res;
		})
	},
	PostDimensions(data) {
		return axios.post(PostDimensions, {
			nameAr:data.nameAr,
			nameEn: data.nameEn,
			unit: data.unit
		},{
			headers: {
              
				Authorization: "Bearer " + JSON.parse(localStorage.getItem('token'))
			 }
		}).then((res) =>{
			return res;
		});
	},
	DeleteDimensions(data) {
		return axios.delete(DeleteDimensions+data, {
			headers: {
              
				Authorization: "Bearer " + JSON.parse(localStorage.getItem('token'))
			 }
		}).then((res) => {
			return res;
		})
	}
 
};
