<template>

  <div class="row justify-content-center ">
    <div class=" col-md-6 col-lg-4  col-sm-10  col-12   error" >
   <p class="ElMessiri-25 font-bold  "> {{$t('ErrorFavorite.LogYet')}}</p>
     <div class="error1 ElMessiri-20 "> {{$t('ErrorFavorite.mustBeLog')}}</div>
     <button @click="Login"  class="ElMessiri-20" > {{$t('ErrorFavorite.Login')}}  </button>
     <button @click="visiter" class="ElMessiri-20"  > {{$t('ErrorFavorite.visitor')}} </button>
      </div>
   
  </div>
</template>

<script>
import router from '../../router/index'
export default {
    props : ['ErorrFavorite'],
    data () {
        return {
            ErorrFavoriteEdite : false
        }
    },
methods : {
 Login () {
     router.push({name: 'Login'})
 },
 visiter() {
      this.$emit('ErorrFavoriteEdite',this.ErorrFavoriteEdite);
 }
}
}
</script>

<style lang="scss" scoped> 
.error {
    text-align: center;
    background-color:$Fifth;
    z-index: 101;
        position: fixed;
    top: 25%;
    box-shadow: 10px 10px 900px 430px $third;
    border-radius: 20px;
    padding: 25px 10px;
}
 .error:first-child p {
     color:  $t_secondary ;
 }
 .error1  {
     color:  $t_primary;
        margin-bottom: 25px;
 }
.error button {
    display: block; 
    background-color: $Fifth;
    color:  $t_forth ;
 
        margin: 15px auto;
    width:50%;
 
    height: 50px;
    border: 3px solid black;
    border-radius: 10px;
}
</style>