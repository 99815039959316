/* eslint-disable prettier/prettier */
export default {
  
    get_Category(state,data) {
      for(var i=0;i<data.data.length;i++) {
        state.get_Category.push(data.data[i])
        if(state.get_Category[0].id == data.data[i].id) {
         state.get_Category = []; 
         state.get_Category.push(data.data[i])
       }
    }
    },
   
    Success(state,data) {
      state.success = data
    },
    get_Category_Dimensions(state,data) {
      state.get_Category_Dimensions = data;
    }
}