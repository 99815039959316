/* eslint-disable prettier/prettier */
import { getStatistics ,getMyProfile } from './GeneralAPI';
import axios from 'axios';
export default {
	getStatistics(data) {
		return axios.get(getStatistics, {
			headers: {
				  
				Authorization: "Bearer " +  JSON.parse(localStorage.getItem('token'))
			 }
		}).then((res) => {
			return res;
		});
	},
	getMyProfile() {
		return axios.get(getMyProfile, {
			headers: {
				  
				Authorization: "Bearer " +  JSON.parse(localStorage.getItem('token'))
			 }
		}).then((res) => {
			return res;
		});
	},
	 
};
