/* eslint-disable prettier/prettier */
import { GetRequestList ,GetAllRequestList ,GetRequestByid,postRequest,ReviewARequest,pay ,UploadFile,PostFile ,download } from './OrderAPI';
import axios from 'axios';
export default {
	getRequestList(data) {
		
		if(localStorage.getItem('role') == 3) {
	 
			return axios.get(GetAllRequestList+data.limit+"&offset="+data.offset,{
				headers: {
				  
					Authorization: "Bearer " +  JSON.parse(localStorage.getItem('token'))
				 }
			}).then((res) => {
				return res;
			});
		}else {
			return axios.get(GetRequestList+data.limit+"&offset="+data.offset,{
				headers: {
				  
					Authorization: "Bearer " +  JSON.parse(localStorage.getItem('token'))
				 }
			}).then((res) => {
				return res;
			});
		}
	 
	},
	getRequestByid(data) {
      return axios.get( GetRequestByid+data ,{
		headers: {
				  
			Authorization: "Bearer " +  JSON.parse(localStorage.getItem('token'))
		 }
	  }).then((res) => {
		  return res;
	  })
	},
 
	postRequest(data) { 
		return axios.post(postRequest,data,{
			
				headers: {
				  
				   Authorization: "Bearer " +  JSON.parse(localStorage.getItem('token'))
				}
		}).then((res) => {
			return res;
		});
	},
	ReviewARequest(data) {
		return axios.patch(ReviewARequest+ data.id,{
			date:data.date,
			price: data.price
		},  {
            headers: {
              
               Authorization: "Bearer " +  JSON.parse(localStorage.getItem('token'))
            }
         }).then((res) => {
			return res;
		});
	},
	Pay(data) {
		return axios.get(pay+ data, {
			headers: {
              
				Authorization: "Bearer " +  JSON.parse(localStorage.getItem('token'))
			 }
		}).then ((res) => {
			return res;
		})
	},
	PostFile(data) {
		return axios
			.post(
				PostFile + data.id,
				{ extension: data.extentions },
				{
					headers: {
						Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('token')),
					},
				}
			)
			.then((res) => {
				return res;
			});
	},
	UploadFile(data) {
	 
		return axios
			.post(
				UploadFile + data.id,
				{ extension: data.extentions },
				{
					headers: {
						Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('token')),
					},
				}
			)
			.then((res) => {
				return res;
			});
	},
	Download(data) {
	
		return axios.get(download+data.id+"/"+data.assetId, {
            headers: {
              
               Authorization: "Bearer " +  JSON.parse(localStorage.getItem('token'))
            }
         }).then((res) => {
			return res;
		});
	},
 
};
