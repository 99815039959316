/* eslint-disable prettier/prettier */
import GeneralServices from '../Services/GeneralServices';
export default {
    getStatistics({commit}) {
        return new Promise ((resolve,reject) => {

        GeneralServices.getStatistics().then (response => {  
            if(response.status == 200) {
                commit('getStatistics', response.data);
                resolve(true);
            }          
        }).catch(error => {
           console.log(error.response.data.msg);
           resolve(false);
        })
    })
    },
    getMyProfile({commit}) {
        return new Promise ((resolve,reject) => {

        GeneralServices.getMyProfile().then (response => {  
            if(response.status == 200) {
                commit('getMyProfile', response.data);
                resolve(true);
            }          
        }).catch(error => {
           console.log(error.response.data.msg);
           resolve(false);
        })
    })
    },
   
}
 
 