<template>
	<div class="all">
		<div class="reset ElMessiri-15">
			<router-link :to="`/${$i18n.locale}/Login`" class="close">  <img src="../../assets/Admin/close.svg" alt="close" style="width: 15px; height: 15px" /> </router-link>
			<div class="resetnew">
				<div class="mb-2"> {{$t('LogIn.EnterNumber')}} </div>
				<input
					type="number"
					:class="{ 'is-invalid': validationStatus($v.New.Code) }"
					class="form-in"
					v-model.trim="$v.New.Code.$model"
					:placeholder="$t('LogIn.EnterNumber')"
				/>
				<div v-if="!$v.New.Code.required" class="invalid-feedback">
					{{ $t('LogIn.required') }}
				</div>
				<input
					type="text"
					:class="{ 'is-invalid': validationStatus($v.New.NewPassowrd) }"
					class="form-in mt-2"
					v-model.trim="$v.New.NewPassowrd.$model"
					:placeholder="$t('LogIn.NewPassword')"
				/>
				<div v-if="!$v.New.NewPassowrd.required" class="invalid-feedback">
					{{ $t('LogIn.required') }}
				</div>
				<div v-if="!$v.New.NewPassowrd.minLength || !$v.New.NewPassowrd.maxLength" class="invalid-feedback">
					{{ $t('LogIn.Password') }}
				</div>
				<div class="col-12">
					<button type="submit" v-if="!loading" @click="Reset" class="send login ElMessiri-20"> {{$t('LogIn.Send')}}</button>
				</div>
				 
	         	 
				<div v-if="error" class="alert alert-danger alerted" id="alerted" role="alert">
					<p>{{ $t('LogIn.error_reset') }}</p>
				</div>
			</div>
			 
		</div>
		<div>
          <Spinner v-if="loading" style="text-align:center"></Spinner>
	 
		</div>
		 
	</div>
</template>

<script>
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import { mapState } from 'vuex';
import Spinner from '../Spinner.vue';
export default {
    data: () => ({
        New: {
            Code: null,
            NewPassowrd: "",
        },
        loading: false,
        error: false
    }),
    validations: {
        New: {
            Code: { required },
            NewPassowrd: { required, minLength: minLength(8), maxLength: maxLength(50) },
        },
    },
    methods: {
        validationStatus: function (validation) {
            return typeof validation != "undefined" ? validation.$error : false;
        },
        Reset() {
            this.$v.$touch();
            if (this.$v.$pendding || this.$v.$error)
                return;
            this.loading = true;
            this.$store.dispatch("Login/ResetCode", this.New).then(res => {
                res == true ? this.loading = false : this.error = true;
				this.loading = false
            });
        },
    },
    computed: {
        ...mapState({
            error_reset: (state) => state.Login.error_reset_code,
        }),
    },
    components: { Spinner }
};
</script>

<style scoped>
.form-in {
	display: block;
	margin: 0 auto;
}
@media only screen and (max-width: 600px) {
	.login {
		width: 150px;
	}
}
</style>
