<!--  -->
<template>
	<div>
		<!-- Start Header Thread -->
		<ul :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="nav justify-content-center CopperplateGothic-25 font-bold">
				 <!-- this is All for Category -->
				 <li class="nav-item">
	                <button	 
						v-bind:class="activeButton == null ? 'white' : ''"
						class="nav-link ElMessiri-15"
						@click="getAllScheme"
					>
						 {{ $t('buttonSearch.all') }}
					</button>
				 </li>
			 
			<li class="nav-item"     v-for="Category in Categorys" :key="Category.id">
		 
				<div   class="test" @click="getSchemeCatg(Category.id)">
					<button
						:tabIndex="Category.id"
						v-bind:class="activeButton == Category.id ? 'white' : ''"
						class="nav-link ElMessiri-15"
					 
						v-if="`${$i18n.locale}` == 'ar'"
					>
						{{ Category.nameAr }}
					</button>
					<button
						:tabIndex="Category.id"
						v-bind:class="activeButton == Category.id ? 'white' : ''"
						class="nav-link ElMessiri-15"
					 
						v-if="`${$i18n.locale}` == 'en'"
					>
						{{ Category.nameEn }}
					</button>
				</div>
			</li>
		</ul>
		<!-- End Header Thread 	  -->

		<!-- Search start  -->
		<Search :IsCategory="IsTrue.IsCategory" :IdCategory="IsTrue.IdCategory" @eventname="updateIsSearch" />
		<counter :offset="IsTrue.offset" :offsetCounter="offsetCounter" />
		<ErorrFavorites :ErorrFavorite="ErorrFavorite" @ErorrFavoriteEdite="updateErorrFavorite" v-if="ErorrFavorite" />
		<!-- Search end  -->
		<div class="clear"></div>
 	
					<!--  start  prodect    -->
		<div class="row"  :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"    >
			<div
				class="col-sm-12 col-md-6 col-lg-3 prodect"
			 
				v-for="(Scheme, index) in SchemeList"
				:key="index"
            data-aos="zoom-in-down"
			   data-aos-duration="2000"
			>
		 
			 
		 

				<img
					:class="typeof  Scheme.isFavorite == 'number' ? 'loveActive' : 'LoveDisable'"
					class="love"
					id="Favorites"
					@click="AddFavorites(Scheme)"
					src="../../assets/icon/love.svg"
					alt=""
				data-aos="flip-left"
                    data-aos-easing="ease-out-cubic"
                 data-aos-duration="1500"
			 
				/>
				<router-link
					:to="`/${$i18n.locale}/DetailsScheme/${Scheme.id}`"
					@click.native="Details(Scheme)"
					style="text-decoration: none"
				>
					<img class="path"  :src="Scheme.imageUrl" alt="error" />
					<!-- here from scroll -->
			 
				</router-link>
			</div>
		 
		</div>
	<Spinner v-if="Loading" style="    text-align: center;"></Spinner>
		<div class="Observe" v-if="total" v-observe-visibility="HandelScrolled"></div>
		<!--  end  prodect -->
	</div>
</template>

<script>
import { mapState } from 'vuex';
import Search from './Search.vue';
import counter from './Counter.vue';
import ErorrFavorites from './ErorrFavorites.vue';
 
 
import Spinner from '../Spinner.vue';
export default {
	data() {
		return {
		 Loading: false,
			test: 'test',
			IdDelete : null,
			ConfirmDelete: false,
			activeButton: null,
			ErorrFavorite: false,
			offsetCounter : 10,
			// this is for shear data between child and parent component
			IsTrue: {
				IsDefaulte: true,
				IsCategory: false,
				IsSpace: false,
				IsShearch: false,
				IdCategory: null,
				SearchInput: '',
				offset: 0,
				all: {
					down: null,
					top: null,
				},
			},
			total11: 100,
			EditSchem: false,
			SchemeEdits: {
				id: null,
				nameAr: '',
				nameEn: '',
				groundSpace: null,
				buildingSpace: null,
				typeId: null,
				categoryId: null,
				image: '',
			},
			 DataEditeScheme : {},
		};
	},
	components: {
    Search,
    counter,
    ErorrFavorites,
  
    Spinner
},
	methods: {
	 
		updateIsSearch(variable) {
			this.IsTrue.IsShearch = variable.Search;
			this.IsTrue.IsDefaulte = variable.Default;
			this.IsTrue.IsSpace = variable.Space;
			this.IsTrue.IsCategory = variable.Category;
			this.IsTrue.SearchInput = variable.SearchInput;
			this.IsTrue.offset = variable.offset;
			this.IsTrue.all.top = variable.all.top;
			this.IsTrue.all.down = variable.all.down;
		},
		posttest() {
			this.$store.dispatch('Category/PostCategory', this.DashBorda);
		},
		Details(va) {
			this.$store.dispatch('SchemeDetails/getSchemeDetails', va);
		},
	 
		AddFavorites(va) {
			 
			var token1 = JSON.parse(localStorage.getItem('token'));
			if (token1 == null) {
				this.ErorrFavorite = true;
				 
			} else {
				if ( typeof va.isFavorite == 'number' ) {
				 
					this.$store.dispatch('Scheme/RemoveFavorites', va.id);
				} else {
				 
					this.$store.dispatch('Scheme/AddFavorites', va.id);
				}
			}
		},
		updateErorrFavorite(va) {
			this.ErorrFavorite = va;
		},
		previewFilesimg(event) {
		 
			this.SchemeEdits.image = event.target.files[0];
		},
		SchemeEdit() {
		 
			var SchemeBodyEdit = new FormData();
			SchemeBodyEdit.set('nameAr', this.SchemeEdits.nameAr);
			SchemeBodyEdit.set('nameEn', this.SchemeEdits.nameEn);
			SchemeBodyEdit.set('groundSpace', this.SchemeEdits.groundSpace);
			SchemeBodyEdit.set('buildingSpace', this.SchemeEdits.buildingSpace);
			SchemeBodyEdit.set('typeId', this.SchemeEdits.typeId);
			SchemeBodyEdit.set('categoryId', this.SchemeEdits.categoryId);
			if (this.SchemeEdits.image == '') {
				this.$store.dispatch('Scheme/EditScheme', { SchemeBodyEdit: SchemeBodyEdit, id: this.SchemeEdits.id });
			} else {
				SchemeBodyEdit.append('image', this.SchemeEdits.image);
				this.$store.dispatch('Scheme/EditScheme', { SchemeBodyEdit: SchemeBodyEdit, id: this.SchemeEdits.id });
			}
		},
		newScrole() {

	 	var self = this;
	  // 	this.offsetCounter+=10;
          	if (   this.total    <= this.IsTrue.offset) {
				 this.offsetCounter =  this.total  ;
				 
			}
	  

			if (this.IsTrue.IsDefaulte) {
				this.$store.dispatch('Scheme/getScheme', this.IsTrue.offset).then(res => {
					res == true ? setTimeout(function () {
				self.Loading = false;
			}, 1000):'';
						 
		 
			 
				});
			} else if (this.IsTrue.IsCategory == true && this.IsTrue.IsSpace == true) {
				this.$store.dispatch('Scheme/getSpaceCatg', {
					all: this.IsTrue.all,
					offset: this.IsTrue.offset,
					id: this.IsTrue.IdCategory,
				});
			} else if (this.IsTrue.IsCategory) {
				this.$store.dispatch('Scheme/getSchemeCatg', {
					id: this.IsTrue.IdCategory,
					offset: this.IsTrue.offset,
				});
			} else if (this.IsTrue.IsShearch) {
				this.$store.dispatch('Scheme/getSearch', {
					search: this.IsTrue.SearchInput,
					offset: this.IsTrue.offset,
				});
			} else if (this.IsTrue.IsSpace) {
				this.$store.dispatch('Scheme/getmaxmin', { all: this.IsTrue.all, offset: this.IsTrue.offset });
			}
		},
		HandelScrolled(isVisible) {
			if (!isVisible) {
				return;
			}
			 	this.IsTrue.offset += 10;

			if (   this.total    <= this.IsTrue.offset) {
				 
				return;
			}
		  this.Loading = true; 
       
			this.newScrole();
		 	if (   this.total    < this.offsetCounter+10) {
				 this.offsetCounter =  this.total  ;
			   return ;	 
			}
          	this.offsetCounter+=10;
		 
		},
		getSchemeCatg(va) {
			this.activeButton = va;
			if (this.IsTrue.IsSpace) {
				(this.IsTrue.offset = 0), (this.IsTrue.IdCategory = va), (this.IsTrue.IsCategory = true);
				(this.IsTrue.IsDefaulte = false), (this.IsTrue.IsShearch = false), (this.IsTrue.IsSpace = true);
				this.$store.dispatch('Scheme/getSpaceCatg', {
					all: this.IsTrue.all,
					offset: this.IsTrue.offset,
					id: this.IsTrue.IdCategory,
				});
			} else {
				(this.IsTrue.offset = 0), (this.IsTrue.IdCategory = va), (this.IsTrue.IsCategory = true);
				(this.IsTrue.IsDefaulte = false),
					(this.IsTrue.IsShearch = false),
					(this.IsTrue.IsSpace = false),
					this.$store.dispatch('Scheme/getSchemeCatg', { id: va, offset: this.IsTrue.offset });
			}
		},
		getAllScheme(){
           this.IsTrue.offset = 0;
		  this.activeButton = null
		  	this.$store.dispatch('Scheme/getScheme', this.IsTrue.offset);
		}
	},
	mounted() {
		this.$store.dispatch('Category/getCategory');
		this.$store.dispatch('Scheme/getScheme', this.IsTrue.offset);
		//this.newScrole();
		this.$store.dispatch('Type/getType');
	},
	computed: {
		...mapState({
			Categorys: (state) => state.Category.get_Category,
			SchemeList: (state) => state.Scheme.Scheme_list,
			total: (state) => state.Scheme.total,
			success: (state) => state.Category.success,
			successPatch: (state) => state.Category.successPatch,
			DashBord: (state) => state.Login.DashBord,
			successScheme: (state) => state.Scheme.success,
			Favorites: (state) => state.Scheme.Favorites,
			get_Types: (state) => state.Type.get_type,
		}),
	},
};
</script>
<style lang="scss"   >
 .Success {
	     position: fixed;
    top: 50%;
    width: 100%;
    text-align: center;
    z-index: 11;
 }
.ar-style {
  direction: rtl;
}
.nav {
	margin-top: 10px;
	background-color: $primary;
	padding: 8px 0px;
}
.nav .nav-item .nav-link {
	color: $t_Fifth;
	background-color: $primary;
	border: none;
}
.nav .nav-item .white {
	background-color: $Fifth !important;
	color: $t_forth !important ;
	border-radius: 14px;
}
/*
.nav-link:focus {
	 background-color: $Fifth !important;
	color: $t_forth !important ;
	border-radius: 14px;
<div v-if="successScheme" class="alert alert-success Success" role="alert">
  					{{ $t('Control.Success') }}
				</div>

} */

/** end  Search pox */
.clear {
	clear: both;
	display: block;
	margin-bottom: 15px;
}
.prodect .path {
 display: block;
    width: 100%;
    z-index: 99;
    max-height: 450px;
	min-height: 450px;
    max-width: 290px;
    margin: 0px auto;
}
.prodect .love {
	z-index: 100;
	cursor: pointer;
    position: relative;
      left: 0%;
    top: 85%; 
}
.prodect .LoveDisable {
	background-color: black;
	-webkit-mask: url('../../assets/icon/love.svg') no-repeat center;
	mask: url('../../assets/icon/love.svg') no-repeat center;
}
.prodect .loveActive {
	background-color: red;
	-webkit-mask: url('../../assets/icon/love.svg') no-repeat center;
	mask: url('../../assets/icon/love.svg') no-repeat center;
}
.Observe {height: 40px;}
/**.btn_shearch
       	created() {
		window.addEventListener('scroll', this.scrollFunction);
	},
	destroyed() {
		window.removeEventListener('scroll', this.scrollFunction);
	},

		handleScroll(va) {
         
		},
		scrollFunction() {
		       //let   element = event.currentTarget;
    
 
	     var  href = getAttribute('data-index');
	   
		  
		        for (let i =0; i<=window.scrollY;i++) {
			  if(element.getAttribute('data-index')) {
                
                   let  href = element.getAttribute('data-index');
	             
			  }}
		   
		},
		ttt() {
      
		},

	<li class="nav-item">
				<a class="nav-link" href="#">مخطط واجهة</a>
			</li>
			<li class="nav-item">
				<a class="nav-link" href="#">مخطط حديقة</a>
			</li>
			<li class="nav-item">
				<a class="nav-link" href="#">مخطط منوع</a>
			</li>


<div class="row  ">
  <div class=" col-sm-12 col-md-6 col-lg-4  prodect ">
      <img  class="path" src="../../assets/a.png" alt="">
          <img class="love" src="../../assets/icon/love.svg" alt="">
  </div>
   <div class=" col-sm-12 col-md-6 col-lg-4  prodect ">
      <img  class="path" src="../../assets/a.png" alt="">
          <img class="love" src="../../assets/icon/love.svg" alt="">
  </div>
   <div class=" col-sm-12 col-md-6 col-lg-4  prodect ">
      <img  class="path" src="../../assets/a.png" alt="">
          <img class="love" src="../../assets/icon/love.svg" alt="">
  </div>
</div>
  <!--  test for : -->
   <div style="margin-top: 100px;">
     <button @click="test" >click me</button>
     <div v-for="Category in Categorys" :key="Category.id" >
          <span> {{Category.id}}</span>
          <span>{{ Category.nameAr }}</span>
     </div>
<input type="text" v-model="te.nameAr"  placeholder="namear" >

 <input type="text" v-model="te.nameEn" placeholder="nameen" >
   <button @click="posttest" >click me</button>
   </div>

   		<div data-index="111">
						<div class="Detels">
							<div class="DetelsElements ElMessiri-15"  dir="rtl"   >
								<div>{{ $t('Scheme.Land') }}</div>
								<span> {{ Scheme.groundSpace }}m2</span>
							</div>
							<div v-if="`${$i18n.locale}` == 'ar'" class="DetelsElements ElMessiri-15 font-bold">
								{{ Scheme.nameAr }}
							</div>
							<div v-if="`${$i18n.locale}` == 'en'" class="DetelsElements ElMessiri-15 font-bold">
								{{ Scheme.nameEn }}
							</div>
							<div class="DetelsElements ElMessiri-15"  dir="ltr"     >
								<div>{{ $t('Scheme.building') }}</div>
								<span> {{ Scheme.buildingSpace }} m2 </span>
							</div>
						</div>
					</div>
.prodect .Detels {
	background-color: $secondary;
	height: 45px;
	color: $t_forth;
	margin-bottom: 15px;
}
.prodect .Detels .DetelsElements span {
	padding-left: 10px;
}
.prodect .Detels .DetelsElements:nth-child(2) {
	text-align: center;
	margin-top: -36px;
	font-weight: bold;
}
.prodect .Detels .DetelsElements:last-child {
 
	margin-top: -35px;
}


	 
				<img
					v-if="$admins"
					@click="Edit(Category)"
					src="../../assets/icon/pen.png"
					style="width: 20px; margin-left: 15px"
					alt=""
				/>
				<img
					v-if="$admins"
					@click="delet(Category.id)"
					src="../../assets/icon/delete.png"
					style="width: 20px"
					alt=""
				/>

 "comment2" : "this is for Scheme",
  "Scheme": {
    "Land": "Land area " ,
    "building" : "building area"
  } ,
      "comment2" : "this is for Scheme",
      "Scheme": {
        "Land": "مساحة الارض " ,
        "building" : "مساحة البناء"
      },

	  	EditScheme(va) {
	   
	        this.DataEditeScheme = va;
			this.EditSchem = true;
			this.SchemeEdits.id = va.id;
			this.SchemeEdits.nameAr = va.nameAr;
			this.SchemeEdits.nameEn = va.nameEn;
			this.SchemeEdits.groundSpace = va.groundSpace;
			this.SchemeEdits.buildingSpace = va.buildingSpace;
			this.SchemeEdits.buildingSpace = va.buildingSpace;
			this.SchemeEdits.typeId = va.typeId;
			this.SchemeEdits.categoryId = va.categoryId;
		},
		deletScheme(va) {
           this.IdDelete = va;
		   this.ConfirmDelete = true
			//this.$store.dispatch('Scheme/DeleteScheme', va);
		},
				ConfirmClose() {
         this.ConfirmDelete =false;
		},
		ConfirmYes() {
         	this.$store.dispatch('Scheme/DeleteScheme',  this.IdDelete);
		    this.ConfirmDelete =false;
		},
*/
</style>
