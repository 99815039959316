 export const GetRequestList = 'https://cvim8f5wld.execute-api.us-east-2.amazonaws.com/dev/general/order/my-orders?limit=';
 // this is for Admin to see all request 
 export const GetAllRequestList = 'https://cvim8f5wld.execute-api.us-east-2.amazonaws.com/dev/general/order/all?limit=';
 export const GetRequestByid = 'https://cvim8f5wld.execute-api.us-east-2.amazonaws.com/dev/general/order/detail/';
 export const postRequest ='https://cvim8f5wld.execute-api.us-east-2.amazonaws.com/dev/general/order';
 export const ReviewARequest = 'https://cvim8f5wld.execute-api.us-east-2.amazonaws.com/dev/general/order/review/';
 export const pay = 'https://cvim8f5wld.execute-api.us-east-2.amazonaws.com/dev/general/order/pay/';
 export const PostFile ='https://cvim8f5wld.execute-api.us-east-2.amazonaws.com/dev/general/order/add-extra-file/';
 //this is for Admin
 export const UploadFile ='https://cvim8f5wld.execute-api.us-east-2.amazonaws.com/dev/general/order/add-file/';
 export const download = 'https://cvim8f5wld.execute-api.us-east-2.amazonaws.com/dev/general/order/detail/';