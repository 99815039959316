<!--eslint-disable -->
<template>
	<div class=" all  " :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
		<div class="row justify-content-center">
		 
				<img src="../../assets/logo1.png" class="begain col-12 col-sm-4" alt="test" />
		 
		</div>
		<div class="row justify-content-center">
			<div class="col-10 col-sm-10 col-md-6 col-lg-4 center">
				<div class="form">
					<input
						type="text"
						:class="{ 'is-invalid': validationStatus($v.user.Email) }"
						class="form-in"
						v-model.trim="$v.user.Email.$model"
						:placeholder="$t('SginUp.Email')"
					/>
				  <div v-if="SendError">
                     	  <div v-if="!$v.user.Email.required" class="invalid-feedback ElMessiri-15 block">
					      	{{ $t('LogIn.required') }}
					      </div>
					        <div v-if="!$v.user.Email.email" class="invalid-feedback ElMessiri-15 block">
						     {{ $t('LogIn.NotEmail') }}
					       </div>
				  </div>
				 
					<input
						type="Password"
						:class="{ 'is-invalid': validationStatus($v.user.Password) }"
						class="form-in"
						v-model.trim="$v.user.Password.$model"
						:placeholder="$t('SginUp.Password')"
					/>
				   <div v-if="SendError">
				      	<div v-if="!$v.user.Password.required" class="invalid-feedback ElMessiri-15 block">
						{{ $t('LogIn.required') }}
					</div>
					<div v-if="!$v.user.Password.minLength || !$v.user.Password.maxLength" class="invalid-feedback ElMessiri-15 block">
						{{ $t('LogIn.Password') }}
					</div>
			   </div>
				 
				</div>
			</div>
		</div>

		<div class="row justify-content-center">
			<div class="col-6" style="text-align: center;">
				<button v-if="!loading" type="submit" @click="login" class="login ElMessiri-20"> {{$t('LogIn.LogIn')}}</button>
			</div>
		 
				 <Spinner v-if="loading" style="text-align:center"></Spinner>
		 
             
			<router-link class="router ElMessiri-15" :to="`/${$i18n.locale}/Reset`">{{ $t('LogIn.forget') }}</router-link>
			<router-link class="router ElMessiri-15" :to="`/${$i18n.locale}/sign_up`"> {{ $t('LogIn.account') }} </router-link>
		</div>

		<div v-if="Error" class="alert alert-danger ElMessiri-15 alerted" id="alerted" role="alert">
			<p>{{ $t('LogIn.errorLogIN') }}</p>
		</div>
	</div>
</template>
<!--eslint-disable -->
<script>
import { mapState } from 'vuex';
import { required, email, minLength, maxLength } from 'vuelidate/lib/validators';
import Spinner from '../Spinner.vue';

export default {
    data: () => ({
        user: {
            Email: "",
            Password: "",
        },
        loading: false,
        va: false,
        Error: false,
		SendError:false
    }),
    validations: {
        user: {
            Email: { required, email },
            Password: { required, minLength: minLength(8), maxLength: maxLength(50) },
        },
    },
    methods: {
        validationStatus: function (validation) {
            return typeof validation != "undefined" ? validation.$error : false;
        },
        login() {
            this.SendError = true;
            this.$v.$touch();
            if (this.$v.$pendding || this.$v.$error) {
                return;
            }
			this.loading=true
            this.$store.dispatch("Login/LoginUser", this.user).then (res => {
				res == true ? this.loading=false:this.Error =true
				 this.loading=false
			})
          
        },
    },
   
    components: { Spinner }
};
</script>

<style lang="scss" scoped>
.block {
	display: block;
}
 .all {
	display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    text-align: center;
    min-height: 100vh;
 }
.center {
 
 
	margin: 0 auto;
	padding-bottom: 25px;
 
	background-color: $third;
	    border-radius: 25px;
		 
}
.router {
	text-align: center;
	    text-decoration: none;
		color: $t_forth ;
		margin-bottom: 10px;
}
.router:hover {
 
		color: $t_forth ;
}
.begain {
	width: auto;
	height: auto;
	max-width : 140px;
	margin: 0 auto;
}
.form {
	margin: 0 auto;
	width: 300px;
}
.form-in {
	margin-bottom: 20px;
	width: 300px;
	margin: 0 auto;
	margin-top: 15px;
	border-radius: 10px;
	border: 1px solid $secondary;

	height: 40px;
}

.login {
	margin-top: 38px;
	margin-bottom: 25px;
	background-color: $secondary;
	color: $Fifth;
	width: 130px;
	border-radius: 8px;
	border: 3px solid $secondary;
	height: 38px;
}
#alerted {
	text-align: center;
	margin: 0 auto;
	padding: 0.5rem 0.5rem;

	width: 60%;
	margin-top: 30px;
	padding-top: 20px;
}

 
</style>
