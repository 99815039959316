/* eslint-disable prettier/prettier */
import TypeServices from '../Services/TypeServices';
  //import {PostCategory,DeleteChategory} from '../Services/TypeAPI';
 import axios from 'axios';
 
export default {
    getType({commit}) {
        var offset = 0;
        var limit = 10;
        TypeServices.GetType({limit: limit, offset : offset}).then(response => {
      
       
        commit("get_type",response.data);
        
        limit = response.data.total ;
      
        offset = 10;
        if(limit>10) {
           
           limit -=10 
            TypeServices.GetType({limit: limit, offset : offset}).then( response=> {
                commit("get_type",response.data); 
            })
        }        
        }).catch(err => {
            console.log(err.response.data.msg);
        })
     },
     PostType({commit},Type) {
   return new Promise((resolve,reject) => {
    TypeServices.PostType(Type).then(response => {
      
        if(response.status == 201) {
            resolve(true);
          
        } 

    
        commit('Success','success');
        
    }).catch(error => {
        resolve(false);
      reject(error.response);
  
        console.log(error.response.data.msg);
    })
   })
    
     },
    patchType({commit,state},Type) {
        return new Promise((resolve,reject) => {
        TypeServices.PatchType(Type).then(response => {
            if(response.status == 200) {
                state.get_type.forEach((element,index) => {
                    if(element.id == Type.id) {
                     state.get_type[index] = Type
                    }
                 });
              
                 resolve(true);
               
                }
            commit('Success','success');
        
       }).catch(error => {
        resolve(false);  
        reject(error.response);
        console.log(error.response.data.msg);
    })
})
    },
    deleteType({commit,state},Type) {
        return new Promise((resolve,reject) => {
        TypeServices.DeleteType(Type).then(response => {
            if(response.status == 204) {
                state.get_type.forEach((element,index) => {
                   if(element.id == Type) {
                    state.get_type.splice(index,1)
                   }
                });
                resolve(true)
              
            }
          
            commit("Success",'success');
        }).catch(error => {
            resolve(false)
            reject(error.response)
            console.log(error.response.data.msg);
        })
    })
    }
}
 
  /**
   *    
   */