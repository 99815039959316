<!--eslint-disable -->
<template>
	<div class="all" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"  >
		<div class="row justify-content-center"  >
				<img src="../../assets/logo1.png" class="col-10 col-sm-8 begain"   alt="test" />
		</div>
		<div class="row justify-content-center"  >
			<div class="col-10 col-sm-6 col-md-4" id="center">
				<div class="form-group">
					<input
						type="text"
						:class="{ 'is-invalid': validationStatus($v.newuser.Name) }"
						class="form-control input mt-4"
						v-model.trim="$v.newuser.Name.$model"
						:placeholder="$t('SginUp.Name')"
					/>
				<div v-if="SendError">
					<div v-if="!$v.newuser.Name.required" class="invalid-feedback ElMessiri-15 text_Center block">
						{{ $t('LogIn.required') }}
					</div>
				 </div>
					<input
						type="text"
						:class="{ 'is-invalid': validationStatus($v.newuser.Email) }"
						class="form-control input mt-4"
						v-model.trim="$v.newuser.Email.$model"
						:placeholder="$t('SginUp.Email')"
					/>
				 <div v-if="SendError">
					<div v-if="!$v.newuser.Email.required" class="invalid-feedback ElMessiri-15 text_Center block">
						{{ $t('LogIn.required') }}
					</div>
					<div v-if="!$v.newuser.Email.email" class="invalid-feedback ElMessiri-15 text_Center block">
						{{ $t('LogIn.NotEmail') }}
					</div>
				 </div>
					<vue-tel-input
						class="form-control input mt-4"
						type="number"
						v-bind="bindProps"
						v-on:country-changed="countryChanged"
						v-model="newuser.phone"
						 @focus="deletChar"
					></vue-tel-input>
						 <div v-if="SendError">
					<div v-if="!$v.newuser.phone.required" class="invalid-feedback ElMessiri-15 text_Center block">
						{{ $t('LogIn.NotEmail') }}
					</div>
						 </div>
					 <input id="phone1" type="text" v-model="newuser.phone" @change="deletChar" @keyup="acceptNumber"> 
					<input
						type="Password"
						class="form-control input"
						:class="{ 'is-invalid': validationStatus($v.newuser.Password) }"
						v-model.trim="$v.newuser.Password.$model"
						:placeholder="$t('SginUp.Password')"
					/>
					  <div v-if="SendError">
					<div v-if="!$v.newuser.Password.required" class="invalid-feedback ElMessiri-15 text_Center block">
						{{ $t('LogIn.required') }}
					</div>
					<div
						v-if="!$v.newuser.Password.minLength || !$v.newuser.Password.maxLength"
						class="invalid-feedback ElMessiri-15 block"
					>
						{{ $t('LogIn.Password') }}
					</div>
					  </div>
					<input
						type="Password"
						class="form-control input mt-4"
						:class="{ 'is-invalid': validationStatus($v.newuser.confirmPassword) }"
						v-model.trim="$v.newuser.confirmPassword.$model"
						:placeholder="$t('SginUp.confirm')"
					/>
                   <div v-if="SendError">
					<div v-if="!$v.newuser.confirmPassword.required" class="invalid-feedback ElMessiri-15 text_Center block">
						{{ $t('LogIn.required') }}
					</div>
					<div v-if="!$v.newuser.confirmPassword.sameAsPassword" class="invalid-feedback ElMessiri-15 text_Center block">
						  {{$t('SginUp.SamePassword')}}
					</div>
				   </div>
					<input type="radio" v-model="newuser.role" id="user" name="fav_language" value="design" />
					<span class="ElMessiri-12"> {{$t('SginUp.Designer')}}  </span>
					<input type="radio" v-model="newuser.role" id="user" name="fav_language" value="user" style="    margin-left: 60px;
                      margin-top: 20px;" />
					<span class="ElMessiri-12">  {{$t('SginUp.User')}}  </span>
				</div>
			</div>
		</div>
			<div class="row justify-content-center"> 
					<button class="col-10 col-sm-8 login ElMessiri-20" @click="Create">  {{$t('SginUp.CreateAccount')}}  </button>
			</div>
			<div v-if="loading" class="d-flex justify-content-center">
				<div class="spinner-border" role="status">
					<span class="visually-hidden">Loading...</span>
				</div>
			</div>

			<div v-if="ErrorRepet" class="alert alert-danger ElMessiri-15 alerted text_Center" id="alerted" role="alert">
				<p>{{ $t('SginUp.ErrorRepet') }}</p>
			</div>
	</div>
</template>
<!--eslint-disable -->
<script>
import { mapState } from 'vuex';
import { required, email, minLength, maxLength, sameAs } from 'vuelidate/lib/validators';
export default {
	data: () => ({
		newuser: {
			Name: '',
			Email: '',
			Password: '',
			confirmPassword: '',
			phone: '',
			role: '',
			phone: null,
		},
		loading: false,
			SendError: false,
		phone: null,
		country: null,
		bindProps: {
			mode: 'international',
			placeholder: 'Enter a phone number',
			required: false,
			enabledCountryCode: true,
			enabledFlags: true,
			autocomplete: 'of',
			name: 'telephone',

			maxLen: 25,
			inputOptions: {
				showDialCode: true,
			},
		},
	}),
	validations: {
		newuser: {
			Name: { required },
			Password: { required, minLength: minLength(8), maxLength: maxLength(50) },
			Email: { required, email },
			phone:{required},
			confirmPassword: { required, sameAsPassword: sameAs('Password') },
		},
	},
	methods: {
		validationStatus: function (validation) {
			return typeof validation != 'undefined' ? validation.$error : false;
		}, countryChanged(country) {
     
    },
		      acceptNumber() {
        var x = this.newuser.phone.replace(/\D/g, '');
  // this.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
  
   this.newuser.phone = x
  
    },
   
   deletChar() {
    
      document.getElementById("phone1").focus();
   },
		Create() {
			this.SendError = true;
			this.$v.$touch();
			if (this.$v.$pendding || this.$v.$error) return;
			this.$store.dispatch('SignUp/Sign_Up', this.newuser);
			var self = this;
			self.loading = true;
			setTimeout(function () {
				self.loading = false;
			}, 4000);
		 
		},
	},
	computed: {
		...mapState({
			ErrorRepet: (state) => state.SignUp.ErrorRepet,
		}),
	},
};
</script>

<style lang="scss" scoped>
#phone1{
    opacity: 0;
    width: 1px;
    height: 1px;	
}
.block {
	display: block;
}
 .all {
	display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    text-align: center;
    min-height: 100vh;
 }
.text_Center {
  text-align: center;
}
#center {

	padding-bottom: 25px;
	margin-top: 10px;
	background-color: $third;
	border-radius: 25px;
}
.begain {
	width: auto;
	height: auto;
	max-width: 150px;
	margin-top: 30px;
}
.form-group {
	margin: 0 auto;
	width: 200px;
}
.form-control {
	margin-bottom: 20px;
	margin: 0 auto;
 
}

.form-group .input {
	border-radius: 10px;
}
.login {
	margin-top: 38px;
	background-color: $secondary;
	color: $Fifth;
	border-radius: 8px;
	border: 3px solid;
	height: 38px;
	width: 210px;
}
.vue-tel-input {
	height: 42px;
}
#alerted {
	text-align: center;
	margin: 0 auto;
	padding: 0.5rem 0.5rem;

	width: 23%;
	margin-top: 30px;
	padding-top: 20px;
}

/**::placeholder       <input type="text"
         class="form-control input"   v-model="newuser.phone"   placeholder="Phone"> */
</style>
