import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Vuelidate from 'vuelidate';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
// ..
AOS.init({
    once: true
});
 
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
 
import i18n from './i18n'

import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';

Vue.use(VueTelInput);

import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

Vue.component('vue-phone-number-input', VuePhoneNumberInput);
 


import VueObserveVisibility from 'vue-observe-visibility';
Vue.use(VueObserveVisibility)
Vue.config.productionTip = false;
Vue.use(Vuelidate);
router.beforeEach((to, from, next) => {
    //  let defulatLang = navigator.language;
    //localStorage.setItem('language',navigator.language);



    let language = to.params.lang;
    if (!language) {
        language = 'ar'
    }
    i18n.locale = language
    next();

    Vue.prototype.$admins = false;
  

    let role = localStorage.getItem('role');
    if (role >= 3) {
        Vue.prototype.$admins = true;
      
    } else {
        Vue.prototype.$admins = false;
        
    }
    Vue.nextTick(() => {
        document.title = i18n.locale == 'en' ? 'Mokhatat2D' : '2Dمخطط';
    });


})
 
//var admin = false;
/**
 *  let Default = localStorage.getItem('language');
if(Default) {
   i18n.locale = Default
   router.push({
    params: { lang: Default },
}).catch(()=>{});
}
 * 
 *  var authactive = false;
if (authactive) {
routerp.push({ name: 'Home' });
}else {
    routerp.push({	name: 'Login'});
}
 *   localStorage.setItem('token', false);
 
  if(localStorage.getItem('token')) {
     router.push({ name: 'Home' });
  }else {
      router.push({	name: 'Login'});
  }
         if(!language) {
     language = 'ar'
 }
 i18n.locale = language
 next();
  let Default = localStorage.getItem('language');
 if(Default) {
     i18n.locale = Default
     router.push({
      params: { lang: Default },
  });
 }
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';

Vue.use(VueTelInput);

import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

Vue.component('vue-phone-number-input', VuePhoneNumberInput);
 

 */

//const dimensions = [{ dimensionId: 1, value: 100 }, { dimensionId: 1, value: 100 }]
 /**
  * const result = await Promise.all(files.map(async (file)=>{
    const r = await axios.post.....
    await axios.put.....

}))
  */
export const EventBus = new Vue();
new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App)
}).$mount('#app');


