<template>
  <div class="contact ElMessiri-16">
        <img class="Arrow" src="../../assets/icon/Arrow.svg" alt="">
      <div>Email : info@mfasgroup.com</div>
      <div>WhatsApp :+447859042929</div>
      <div>United Kingdom -71-75 Shelton Street, London</div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
 .Arrow {
         position: absolute;
    left: 25px;
   
    margin-top: -20px;
    width: 15px;
 
 }
.contact {
 
    padding-left: 10px;
    z-index: 11;
    position: absolute;
    margin-top: 25px;
    width: 350px;
    margin-right: -250px;
    -webkit-backdrop-filter: brightness(150%) blur(100px);
    backdrop-filter: brightness(150%) blur(100px);
    background-color: rgba(255, 255, 255, 0.54);
    border-radius: 25px;
    text-align: left;
    padding-top: 5px;
}
</style>