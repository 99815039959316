<template>

  <div class="row justify-content-center    ">
    <div class=" col-md-6 col-lg-4  col-sm-10  col-12   error" >
   <p class="ElMessiri-20 font-bold  "> {{$t('Control.Confirm.ConfirmDelete')}}</p>
          <button @click="Yes"   class="ElMessiri-20" > {{$t('Control.Confirm.Yes')}} </button>
     <button @click="Close"  class="ElMessiri-20"  > {{$t('Control.Confirm.No')}}</button>
   
      </div>
     
  </div>
</template>

<script>
 
export default {
    
    data () {
        return {
           
        }
    },
methods : {
 Close() {
     this.$emit('ConfirmClose');
 },
 Yes() {
      this.$emit('ConfirmYes');
 }
}
}
</script>

<style lang="scss" scoped> 
.error {
    text-align: center;
    background-color:$Fifth;
    z-index: 101;
    position: fixed;
    top: 25%;
    box-shadow:2px 2px 20px 10px $third;
    border-radius: 20px;
    padding: 25px 10px;
}
 .error:first-child p {
     color:  $t_secondary ;
 }
 
.error button {
     background-color: #FFFFFF;
    color: #000000;
    margin: 15px 10px;
    width: 25%;
    height: 40px;
    border: 3px solid black;
    border-radius: 10px;
    display: inline;
}
</style>