<template>
	<div>
		<Headers />
		<SectionModify :first1_1=" $admins == true ? $t('Modify.ComingRequset') :$t('Modify.MyRequset')" :first1_2="$t('Modify.modification')" :secound1_2="'MyRequests'" />
		<Spinner v-if="loading"  style="text-align: center"></Spinner>
		<div v-else class="row justify-content-center mt-5">
			<div class="col-10 col-sm-10 Modify">
				<div class="row justify-content-center">
					<div
						class="col-11 col-sm-11 mt-4 mb-2 DetailsRequest"
						:class="
							Request.status == 'pending files'
								? 'BackgroundSpecific2'
								: Request.status == 'pending payment'
								? 'BackgroundSpecific3'
								:   Request.status == 'done'
											? 'BackgroundSpecific1'
											:'BackgroundSpecific'
						"
						v-for="Request in RequestList"
						:key="Request.id"
						:dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
					>
						<img :src="Request.imageUrl" alt="imgeDetailsW" />
						<div class="RequestData ElMessiri-18">
							<div v-if="$i18n.locale == 'en'">{{ Request.nameEn }}</div>
							<div v-if="$i18n.locale == 'ar'">{{ Request.nameAr }}</div>

							<div class="mt-2">
								<span
									:class="
										Request.status == 'pending files'
											? 'colorSpecific2'
											: Request.status == 'pending payment'
											? 'colorSpecific3'
											:Request.status == 'done'
													?'colorSpecific1'
													 :'colorSpecific'
									"
									>{{ StateRequest(Request.status) }}</span
								>
								- {{ format_date(Request.createdAt) }}
							</div>
						</div>
						<router-link :to="`/${$i18n.locale}/ModifyDetails/${Request.id}`" style="text-decoration: none">
							<img
								v-if="$i18n.locale == 'en'"
								class="CheckDetails"
								src="../../assets/Request/right-arrow.svg"
								alt="Details"
							/>
							<img
								v-if="$i18n.locale == 'ar'"
								class="CheckDetails"
								src="../../assets/Request/left-arrow.svg"
								alt="Details"
							/>
						</router-link>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Headers from '../Header/Header.vue';
import SectionModify from './SectionModify.vue';
import Spinner from '../Spinner.vue';
import { mapState } from 'vuex';
import moment from 'moment';

export default {
	data () {
		return {
        loading : true,
		}
	},
	components: {
		Headers,
		SectionModify,
		Spinner
	},
	mounted() {
		this.$store.dispatch('Request/getRequestList').then(res => {
			res==true? this.loading = false :''
		})
	},
	computed: {
		...mapState({
			RequestList: (state) => state.Request.get_RequestList,
		}),
	},
	methods: {
		format_date(value) {
			if (value) {
				return moment(String(value)).format('YYYY/MM/DD');
			}
		},
		StateRequest(va) {
			switch (va) {
				case 'pending files':
					if (this.$i18n.locale == 'en') {
						return 'Wating Faile';
					} else return 'في انتظار الملفات ';

				case 'pending review':
					if (this.$i18n.locale == 'en') {
						return ' Wating Review ';
					} else return 'في انتظار  المراجعة ';
				case 'pending payment':
					if (this.$i18n.locale == 'en') {
						return ' pending payment';
					} else return ' في انتظار الدفع ';
					case 'done' :
					if (this.$i18n.locale == 'en') {
						return ' Files have been delivered';
					} else return '  تم تسليم الملفات ';
			}
		},
	},
};
</script>

<style lang="scss"  >
.row {
	--bs-gutter-x: 0;
}
.DetailsRequest {
	border-radius: 5px;
	display: flex;
	align-items: center;
}
.DetailsRequest img {
	width: 75px;
	height: 105px;
}
.RequestData {
	display: inline-block;
	margin: 0px 15px 0px 15px;
	width: 85%;
}

.CheckDetails {
	width: 35px !important;
	height: 35px !important;
}
</style>
