<template>
	<div>
		<div class="path1">
			<div class="row justify-content-center">
				<div class="col-12 col-sm-12 modification ElMessiri-25">
				 {{first1_1}}	 
				</div>
			</div>
			<div class="row">
			 
				<router-link   :to="`/${$i18n.locale}/${secound1_2}`" class="col-12 col-sm-12  offset-md-1   align-self-start CommingRequset ElMessiri-18">
					{{ first1_2 }}
				</router-link >
			 
			</div>
		</div>

		<!-- this is for line black -->
		<div class="row ElMessiri-14 line" :class="$i18n.locale == 'ar' ? 'ar-style' : ''">
			<div v-if="`${$i18n.locale}` == 'en'" class="col-12 col-sm-12">
				<router-link :to="`/${$i18n.locale}/`">
					<img class="backhome" src="../../assets/Details/backL.svg" alt="" />
				</router-link>
				<span class="lineName">	 {{first1_1}}	 </span>
				<span class="lineName">{{ secound1_1 }} </span>
			</div>
			<div v-if="`${$i18n.locale}` == 'ar'" class="col-12 col-sm-12">
				<router-link :to="`/${$i18n.locale}/`">
					<img class="backhome" src="../../assets/Details/backR.svg" alt="" />
				</router-link>
				<span class="lineName">{{first1_1}} </span>
				<span class="lineName">{{ secound1_1 }} </span>
			</div>
		</div>
	</div>
</template>

<script>
export default {
 
  props : ['first1_1', 'first1_2','secound1_1' , 'secound1_2'],
  
  
};
</script>

<style lang="scss" scoped>
.ar-style {
	direction: rtl;
	.backhome {
		margin-right: 30px;
		margin-left: 10px;
	}
}
.path1 {
	background-color: $third;
	height: 70px;
	z-index: -2;
	width: 100%;
}
.modification {
	text-align: center;
	color: $t-third;
	margin-top: 20px;
}
.CommingRequset {
	margin-top: -18px;
	color: $t-third;
	text-decoration: none;
}
.line {
	background-color: $primary;
	color: $t_Fifth;
	height: 40px;
	padding-left: 24px;
	align-items: center;
}
.backhome {
	height: 15px;
	margin-right: 10px;
}
.lineName {margin: 5px;}
.lineName:last-child {color: $t-third;}
@media only screen and (max-width: 500px) {
   .modification  {
	   	margin-top: 5px;
   }
   .CommingRequset  {
	   margin-top: 0;
   }
}
</style>
