/* eslint-disable prettier/prettier */
 
import state from './Set.state'
import getters from  './Set.getters'
import mutations from  './Set.mutation'
import actions from  './Set.action'
export default {
    namespaced : true,
    state ,
    getters,
    mutations,
    actions,
    module : {}
}
