<template>
  <div>
    <SignUp />
  </div>
</template>

<script>
import SignUp from "../components/SignUp/SignUp.vue"
export default {
 components : {
     SignUp
 }
}
</script>

<style>

</style>