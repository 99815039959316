/* eslint-disable prettier/prettier */

import axios from 'axios';
import RequestServices from '../Services/RequestServices';
export default {
	getRequestList({ commit }) {
		var offset = 0;
		var limit = 10;
		return new Promise((resolve, reject) => {
			RequestServices.getRequestList({ limit: limit, offset: offset })
				.then((response) => {
					 
					commit('get_RequestList', response.data);
					limit = response.data.total;

					offset = 10;
					if (limit > 10) {
						limit -= 10;
						RequestServices.getRequestList({ limit: limit, offset: offset })
							.then((response) => {
								commit('get_RequestList', response.data);
							})
							.catch((error) => {
								console.log(error.response.data.msg);
							});
					}
					resolve(true);
				})
				.catch((error) => {
					resolve(false);

					console.log(error.response.data.msg);
					reject(error.response);
				});
		});
	},
	getRequestByid({ commit }, data) {
		return new Promise((resolve, reject) => {
			RequestServices.getRequestByid(data)
				.then((response) => {
					if (response.status == 200) {
						commit('get_RequestByid', response.data);
                        commit('get_FileByType',response.data.data.files)
						resolve(true);
					}
				})
				.catch((err) => {
					resolve(false);
					console.log(err.response.data.msg);
					reject(err.response);
				});
		});
	},
	  AddRequest({ commit, state ,dispatch }, data) {
		 
		var dimensions = new Array();
		var schemeOptionIds = new Array();
		let obj = { dimensionId: 0, value: 0 };
		for (let index = 0; index < data.dimensionsid.length; index++) {
			obj.dimensionId = data.dimensionsid[index];
			obj.value = data.dimensionValue[index];
			dimensions.push(obj);
			obj = { dimensionId: 0, value: 0 };
		}
		data.Option.forEach((el) => {
			data.gettOption.forEach((element1) => {
				if (
					element1.nameAr.split(' ').join('') == el.nameAr.split(' ').join('') ||
					element1.nameEn.split(' ').join('') == el.nameEn.split(' ').join('')
				)
					schemeOptionIds.push(element1.id);
			});
		});
		let dataSend;
		if (data.extraInfo) {
			dataSend = {
				schemeId: data.schemeId,
				extraInfo: data.extraInfo,
				dimensions: dimensions,
				schemeOptionIds: schemeOptionIds,
			};
		} else {
			dataSend = { schemeId: data.schemeId, dimensions: dimensions, schemeOptionIds: schemeOptionIds };
		}

		return new Promise((resolve, reject) => {
	 	RequestServices.postRequest(dataSend)
				.then((response) => {
					if (response.status == 201) {
						if (data.inputs[0]) {
							let id = response.data.data.id;
							dispatch('PostFileExtra',{id: id,inputs :data.inputs });
						}
					 
						resolve(true);
					}
				})
				.catch((error) => {
					resolve(false);

					console.log(error.response.data.msg);
					reject(error.response);
				});
		});
	},
 async	PostFileExtra({commit},data) {
     
	 await   Promise.all(
		data.inputs.map(async (file) => {
			let extentions = file.File.name.split('.').pop();
		 

			const r = await RequestServices.PostFile({ extentions: extentions, id: data.id });

 
	 

			await axios
				.put(r.data.extraFilePutUrl, file.File)
				.then((response) => {
				 
				})
				.catch((err) => {
					console.log(err.response.data.msg);
				});
		})
	)
		.then((res) => {
			//return true;
			//  resultSend = true;
			//  commit("successAdd","successAdd");
		 
		})
		.catch((error) => {
			// resultSend = false;
			console.log(error.response.data.msg);
			 
		});
	},
	ReviewARequest({ state }, data) {
		return new Promise((resolve, reject) => {
			RequestServices.ReviewARequest(data)
				.then((response) => {
					if (response.status == 200) {
					 
						resolve(true);
					}
				})
				.catch((error) => {
					resolve(false);
					console.log(error.response.data.msg);
					reject(error.response);
				});
		});
	},
	Pay({ commit }, data) {
		return new Promise((resolve, reject) => {
			RequestServices.Pay(data)
				.then((response) => {
					if (response.status == 200) {
						window.open(response.data.url, '_blank');
					}
				})
				.catch((err) => {
					console.log(err.response.data.msg);
				});
		});
	},
	async UploadFile({ commit, state }, data) {
		var resultSend = false;
		var result = await Promise.all(
			data.inputs.map(async (file) => {
				let extentions = file.File.name.split('.').pop();
			 

				const r = await RequestServices.UploadFile({ extentions: extentions, id: data.id });

			 
			 

				await axios
					.put(r.data.extraFilePutUrl, file.File)
					.then((response) => {
					 
					})
					.catch((err) => {
						console.log(err.response.data.msg);
					});
			})
		)
			.then((res) => {
				//return true;
				resultSend = true;
				//  commit("successAdd","successAdd");
			})
			.catch((error) => {
				resultSend = false;
			});
		return new Promise((resolve) => {
			if (resultSend) {
				resolve(true);
			} else {
				resolve(false);
			}
		});
	},
	Download({},data) {
		RequestServices.Download(data).then (response=> {
			if (response.status == 200)
		 window.open(response.data.data.asset.url , '_blank');
	
		}).catch(error=> {
		  console.log( error.response.data.msg);
		}) 
	  },

	SentToModify({ commit }, data) {
		commit('SentToModify', data);
	},
};

/**
   *         axios.delete(DeleteChategory+Categoryid,{
        headers: {
              
            Authorization: "Bearer " + JSON.parse(localStorage.getItem('token'))
         }  
      }).then (response => {
           commit("Success","success")
        
      }).catch(error => {
        
   
    })
   */
/**
 
							 	Promise.all(
								data.inputs.map(async (file) => {
									let extentions = file.File.name.split('.').pop();
								 

									const r = await RequestServices.PostFile({ extentions: extentions, id: id });

								 
							 

									await axios
										.put(r.data.extraFilePutUrl, file)
										.then((response) => {
									 
										})
										.catch((err) => {
										 
										});
								})
							)
								.then((res) => {
									//return true;
									//  resultSend = true;
									//  commit("successAdd","successAdd");
								 
								})
								.catch((error) => {
									// resultSend = false;
								 
									resolve(false);
								});
 */