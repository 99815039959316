<template>
	<div>
		<Header></Header>
		<SectionModify :first1_1="$t('Control.Statistics')" :secound1_1="date"></SectionModify>
    <Spinner v-if="Loading"  style="text-align: center" ></Spinner>
		<div v-else class="row justify-content-center mt-4 ElMessiri-20 font-bold" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
			<div class="col-10">
				<div class="row justify-content-around mt-4 ">
					<div class="col-12 col-sm-5 col-lg-3">
						<div class="cycle backcolor1">
							<div class="textName">{{$t('Control.statistics.Designees')}} </div>
							<div class="line1"></div>
							<div class="result">{{getstatistics.userCount.designers}}</div>
						</div>
					</div>
					<div class="col-12 col-sm-5 col-lg-3">
						<div class="cycle backcolor1">
							<div class="textName">{{$t('Control.statistics.users')}}</div>
							<div class="line1"></div>
							<div class="result">{{getstatistics.userCount.users}}</div>
						</div>
					</div>
					<div class="col-12 col-sm-5 col-lg-3">
						<div class="cycle backcolor1">
							<div class="textName">{{$t('Control.statistics.TotalUsers')}}</div>
							<div class="line1"></div>
							<div class="result">{{getstatistics.userCount.total}}</div>
						</div>
					</div>
				</div>
        <div class="row justify-content-around mt-5">
					<div class="col-12 col-sm-5 col-lg-3">
						<div class="cycle backcolor1">
							<div class="textName">{{$t('Control.statistics.Type')}}</div>
							<div class="line1"></div>
							<div class="result">{{getstatistics.schemeCount.type}}</div>
						</div>
					</div>
					<div class="col-12 col-sm-5 col-lg-3">
						<div class="cycle backcolor1">
							<div class="textName">{{$t('Control.statistics.Categories')}}</div>
							<div class="line1"></div>
							<div class="result">{{getstatistics.schemeCount.category}}</div>
						</div>
					</div>
					<div class="col-12 col-sm-5 col-lg-3">
						<div class="cycle backcolor1">
							<div class="textName">{{$t('Control.statistics.Schemes')}}</div>
							<div class="line1"></div>
							<div class="result">{{getstatistics.schemeCount.scheme}}</div>
						</div>
					</div>
				</div>

        <div class="mt-4  " >{{$t('Control.statistics.RequestModification')}}: <span class="result">  {{getstatistics.schemeRequestCount.total}} </span> </div>
           <div class="row justify-content-around mt-2">
					<div class="col-12 col-sm-6 col-lg-3">
						<div class="cycle backcolor2">
							<div class="textName">{{$t('Control.statistics.pendingReview')}}</div>
							<div class="line1"></div>
							<div class="result">{{getstatistics.schemeRequestCount.pendingReview}}</div>
						</div>
					</div>
					<div class="col-12 col-sm-6 col-lg-3">
						<div class="cycle backcolor3">
							<div class="textName">{{$t('Control.statistics.pendingPayment')}}</div>
							<div class="line1"></div>
							<div class="result">{{getstatistics.schemeRequestCount.pendingPayment}}</div>
						</div>
					</div>
          	<div class="col-12 col-sm-6 col-lg-3">
						<div class="cycle backcolor4">
							<div class="textName">{{$t('Control.statistics.WatingFaile')}}</div>
							<div class="line1"></div>
							<div class="result">{{getstatistics.schemeRequestCount.pendingFiles}}</div>
						</div>
					</div>
          		<div class="col-12 col-sm-6 col-lg-3">
						<div class="cycle backcolor5">
							<div class="textName">{{$t('Control.statistics.done')}}</div>
							<div class="line1"></div>
							<div class="result">{{getstatistics.schemeRequestCount.done}}</div>
						</div>
					</div>
				</div>
         <div class="mt-4  " >{{$t('Control.statistics.RequestDesign')}}: <span class="result">  {{getstatistics.orderCount.total}}</span>  </div>
            <div class="row justify-content-around mt-2 mb-4">
					<div class="col-12 col-sm-6 col-lg-3">
						<div class="cycle backcolor2">
							<div class="textName">{{$t('Control.statistics.pendingReview')}}</div>
							<div class="line1"></div>
							<div class="result">{{getstatistics.orderCount.pendingReview}}</div>
						</div>
					</div>
					<div class="col-12 col-sm-6 col-lg-3">
						<div class="cycle backcolor3">
							<div class="textName">{{$t('Control.statistics.pendingPayment')}}</div>
							<div class="line1"></div>
							<div class="result">{{getstatistics.orderCount.pendingPayment}}</div>
						</div>
					</div>
          	<div class="col-12 col-sm-6 col-lg-3">
						<div class="cycle backcolor4">
							<div class="textName">{{$t('Control.statistics.WatingFaile')}}</div>
							<div class="line1"></div>
							<div class="result">{{getstatistics.orderCount.pendingFiles}}</div>
						</div>
					</div>
          		<div class="col-12 col-sm-6 col-lg-3">
						<div class="cycle backcolor5">
							<div class="textName">{{$t('Control.statistics.done')}}</div>
							<div class="line1"></div>
							<div class="result">{{getstatistics.orderCount.done}}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Header from '../Header/Header.vue';
import SectionModify from '../RequestModify/SectionModify.vue';
import {mapState} from "vuex"
import Spinner from '../Spinner.vue';
export default {
	data() {
		return {
			date: '',
      Loading: true
		};
	},
	components: { Header, SectionModify, Spinner },
	methods: {
		date_function: function () {
			var formatted_date = new Date().toJSON().slice(0, 10).replace(/-/g, '/');
			this.date = formatted_date;
		},
	},
	mounted() {
		this.date_function();
		this.$store.dispatch('General/getStatistics').then(res => {
      res == true? this.Loading=false:''
    });
	},
  computed: {
    ...mapState({
      getstatistics :(state) => state.General.getStatistics
    })
  }
};
</script>

<style lang="scss"  scoped >
.cycle {
	text-align: center;
	background-color: green;

    height: 150px;
    padding: 30px 0;
	border: 1px solid #989898;
	border-radius: 15px;
  margin-bottom: 10px;
}
.line1 {
	display: block;
	height: 2px;
	width: 100%;
	background-color: #C1C1C1;
	margin: 15px 0;
}
.result {
  color: #676767;
}
.backcolor1 {
  background-color: $requset5;
}
.backcolor2 {
  background-color: $requset1;
}
.backcolor3 {
  background-color: $requset2;
}
.backcolor4 {
  background-color: $requset3;
}
.backcolor5 {
  background-color: $requset4;
}
@media only screen and (max-width: 500px) {
 .cycle {
  margin: 5px 50px;
 }
}
</style>
